import {Injectable} from '@angular/core';
import {CVSBannerComponentData, CVSBannerService, CVSBannerType} from 'angular-component-library';
import {BannerData} from '../../models/BannerData';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    private cvsBannerService: CVSBannerService
  ) { }

  showSuccessBanner(data: BannerData) {
    const bannerData = {
      hideX: false,
      outletId: '#appDashboardBanner',
      headline: data.headLine,
      closeCallBack: () => this.cvsBannerService.close(),
      body: data.body,
      bannerType: CVSBannerType.Success,
      removedAfterMilliseconds : data.removedAfterMilliseconds,
      bannerLinks: data.bannerLinks
    };
    this.cvsBannerService.sendAlert(bannerData);
  }

  showFailureBanner(data: BannerData) {
    const bannerData = {
      hideX: false,
      outletId: '#clientManagementErrorBanner',
      headline: data.headLine,
      closeCallBack: () => this.cvsBannerService.close(),
      body: data.body,
      bannerType: CVSBannerType.Error,
      removedAfterMilliseconds : data.removedAfterMilliseconds,
      bannerLinks: data.bannerLinks
    };
    this.cvsBannerService.sendAlert(bannerData);
  }

  showWarningBanner(data: BannerData) {
    const bannerData = {
      hideX: false,
      outletId: '#appDashboardBanner',
      headline: data.headLine,
      closeCallBack: () => this.cvsBannerService.close(),
      body: data.body,
      bannerType: CVSBannerType.Warning,
      removedAfterMilliseconds : data.removedAfterMilliseconds,
      bannerLinks: data.bannerLinks
    };
    this.cvsBannerService.sendAlert(bannerData);
  }

  showValidationErrorBanner(bannerData, alert: CVSBannerType) {
    const bannerComponentData: CVSBannerComponentData = {
      bannerType: alert,
      hideX: bannerData.hideX,
      isHorizontalBannerLinks: false,
      closeCallBack: this.cvsBannerService.close,
      headline: bannerData.headline,
      body: bannerData.body,
      outletId: bannerData.outletId,
      bannerLinks: bannerData.bannerLinks
    };
    this.cvsBannerService.sendAlert(bannerComponentData);
  }

  closeBanner() {
    this.cvsBannerService.close();
  }
}
