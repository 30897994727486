import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {AppRolesComponent} from '../../../app-management/app-dashboard/app-roles/app-roles.component';
import {
  CVSBannerComponentData,
  CVSBannerService,
  CVSBannerType,
  CVSConfirmationDialogContentComponent
} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PBMRoleInfo} from '../../../models/PBMRoleInfo';
import {AppManagementService} from '../../../services/app-management/app-management.service';
import {AppDataService} from '../../../services/app-management/data/app-data.service';
import {SnackBarWrapperService} from '../../../services/snack-bar-wrapper/snack-bar-wrapper.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AddEditModeEnum, StatusCodes} from '../../../enums/add-edit-form.enum';
import {Router} from '@angular/router';
import {BannerService} from '../../../services/banner-service/banner.service';
import {AgGridHelper} from '../../helpers/AgGridHelper';

@Component({
  selector: 'app-role-table-action-cell-renderer',
  templateUrl: './role-table-action-cell-renderer.component.html',
  styleUrls: ['./role-table-action-cell-renderer.component.scss']
})
export class RoleTableActionCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  parentComponent: AppRolesComponent;
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;
  showDisabledAction: boolean;
  showEnabledAction: boolean;

  constructor(
    private matDialog: MatDialog,
    private appDataService: AppDataService,
    private appManagementService: AppManagementService,
    private snackBarWrapperService: SnackBarWrapperService,
    private router: Router,
    private bannerService: BannerService,
    private cvsBannerService: CVSBannerService
    ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.parentComponent = this.params.context.this;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setTableActionClicked(tableActionClicked: boolean) {
    const data = this.params.node.data;
    data.tableActionClicked = tableActionClicked;
    this.params.api.applyTransaction({update: [data]});
    this.showDisabledAction = this.showDisableMenuItem(this.params.node.data.status);
    this.showEnabledAction = this.showEnableMenuItem(this.params.node.data.status);
  }

  showDisableMenuItem(status: string) {
    return status === StatusCodes.ACTIVE || status === StatusCodes.PENDING;
  }

  showEnableMenuItem(status: string) {
    return status === StatusCodes.INACTIVE || status === StatusCodes.PENDING;
  }

  openEnableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to activate the selected Role?',
        body:
            '<br>'
            + 'This Role will become available for User Profiles.'
            + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.enableAppRole();
    });
  }

  openDisableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to inactivate the selected Role?',
        body:
          '<br>'
          + 'This Role will not be available for User Profiles.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.disableAppRole();
    });
  }

  openDeleteConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to delete this Role?',
        body:
          '<br>'
          + '<p class="delete-spacing">If this Role is not currently linked to any Users Profiles, it will no longer </p>'
          + 'be available for use.'
          + '<br>'
          + '<br>'
          + '<br> Warning: This cannot be undone.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false,
      },
      panelClass: 'delete-confirmation-modal'
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      const roleToRemove = this.params.node.data;
      this.confirmationDialog.close();

      this.appManagementService.deleteAppRoleById(roleToRemove.id).subscribe({
        next: this.deleteRoleSuccessHandler.bind(this, roleToRemove),
        error: this.deleteRoleFailureHandler.bind(this)
      });
    });
  }

  private deleteRoleSuccessHandler(deletedRole: PBMRoleInfo) {
    this.params.api.forEachNode(node => {
      if (node.data.roleCode.includes(deletedRole.roleCode)) {
        this.params.api.applyTransaction({remove: [node.data]});
      }
    });
    this.appDataService.removeRolesFromCurrentApp([deletedRole.id]);
    this.bannerService.showSuccessBanner({
      headLine : `${deletedRole.roleName} has been deleted`,
      body : `The role ${deletedRole.roleName} has been successfully deleted.`,
      removedAfterMilliseconds: 5000
    });
  }

  private deleteRoleFailureHandler(err: HttpErrorResponse) {
    this.showDeleteErrorBanner([this.params.node.data], err);
  }

  showDeleteErrorBanner(rolesUnableToBeDeleted: any[], err: HttpErrorResponse) {
    const errorBannerData = {
      hideX: false,
      outletId: '#appDashboardBanner',
      headline: 'Role(s) cannot be deleted',
      closeCallBack: AgGridHelper.setUnableToDeleteStyleAndCloseBanner.bind(this,
          undefined,
          rolesUnableToBeDeleted,
          this.params.api,
          this.cvsBannerService),
      body: err.error.message,
      bannerType: CVSBannerType.Warning,
    } as CVSBannerComponentData;

    AgGridHelper.setUnableToDeleteStyle(true, rolesUnableToBeDeleted, this.params.api);

    this.cvsBannerService.sendAlert(errorBannerData);
  }

  showDisableFailureBanner(err: HttpErrorResponse) {
    const errorBannerData = {
      hideX: false,
      outletId: '#appDashboardBanner',
      headline: 'Role(s) cannot be disabled',
      body: err.error.message,
      bannerType: CVSBannerType.Warning,
    } as CVSBannerComponentData;

    this.cvsBannerService.sendAlert(errorBannerData);
  }

  snackBarCenterMessage(successMessage) {
    this.snackBarWrapperService.postCenterBottomSuccessSnackBar(successMessage);
  }

  enableAppRole() {
    const pbmRoleInfo: PBMRoleInfo = this.params.data;
    pbmRoleInfo.appId = this.parentComponent.selectedApp.id;
    pbmRoleInfo.status = StatusCodes.ACTIVE;

    this.appManagementService.updateAppRole(pbmRoleInfo).subscribe( {
      next: this.handleEnableSuccess.bind(this, pbmRoleInfo)
    });
  }

  disableAppRole() {
    const pbmRoleInfo: PBMRoleInfo = this.params.data;
    pbmRoleInfo.appId = this.parentComponent.selectedApp.id;
    pbmRoleInfo.status = StatusCodes.INACTIVE;

    this.appManagementService.updateAppRole(pbmRoleInfo).subscribe( {
      next: this.handleDisableSuccess.bind(this, pbmRoleInfo),
      error: this.handleDisableFailure.bind(this),
    });
  }

  handleEnableSuccess(role: PBMRoleInfo) {
    this.params.node.data.status = StatusCodes.ACTIVE;
    this.params.api.applyTransaction({update: [this.params.node.data]});
    this.appDataService.updateRoleOnCurrentApp(role);
    this.bannerService.showSuccessBanner({
      headLine : `${role.roleName} has been activated`,
      body : `The role ${role.roleName} has been successfully activated.`,
      removedAfterMilliseconds: 5000
    });
    this.parentComponent.expandCollapseButtonsComponent.updateExpandCollapseButtons();
  }

  handleDisableSuccess(role: PBMRoleInfo) {
    this.params.node.data.status = StatusCodes.INACTIVE;
    this.params.api.applyTransaction({update: [this.params.node.data]});
    this.appDataService.updateRoleOnCurrentApp(role);
    this.bannerService.showSuccessBanner({
      headLine : `${role.roleName} has been inactivated`,
      body : `The role ${role.roleName} has been successfully inactivated.`,
      removedAfterMilliseconds: 5000
    });
    this.parentComponent.expandCollapseButtonsComponent.updateExpandCollapseButtons();
  }

  private handleDisableFailure(err: HttpErrorResponse) {
    this.showDisableFailureBanner(err);
  }

  navigateToAddEditRoles() {
    this.router.navigate(['/app-management/manage/' + this.parentComponent.selectedApp.appCode + '/role'], {
      state: {
        data: this.params.data,
        mode: AddEditModeEnum.EDIT
      }
    });
  }

  navigateToAddEditRolesCopyMode() {
    this.router.navigate(['/app-management/manage/' + this.parentComponent.selectedApp.appCode + '/role'], {
      state: {
        data: this.params.data.permissions,
        mode: AddEditModeEnum.ADD
      }
    });
  }
}
