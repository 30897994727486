<div class="client-group-lobs-container">
  <span class="number-of-lobs">{{params.data.lineOfBusinesses?.length}} Line of Business</span>
  <cvs-popover-button *ngIf="params.data.lineOfBusinesses?.length > 0"
                      iconName="list--s"
                      class="cvs-icon-only-btn list-icon"
                      hideX="false"
                      customPanelClass="lob-custom-panel-container"
                      [direction]=right
                      [hasBackdrop]=true (click)="$event.preventDefault()">
    <div>
      <h2>Line of Business</h2>
      <div class="list-items">
        <ul>
          <li *ngFor="let lob of lineOfBusinesses">{{lob.name}}</li>
        </ul>
      </div>
    </div>
  </cvs-popover-button>
</div>
