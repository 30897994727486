<div class="tile__background-border client-and-users-container">
  <cvs-loader-overlay-container>
    <div class="client-management-header-container header-padding">
      <div>
        <h2 class="sub-header-padding">{{selectedGroup.groupName}}</h2>
        <p class="client-management-description">{{selectedGroup.groupDescription}}</p>
        <p>Below are the super clients and users associated with this group</p>
      </div>
      <button class="pds-icon-only-btn" (click)="hideGroupDetail()">
        <mat-icon svgIcon="close-btn--s" role="img" aria-describedby="close-button"></mat-icon>
      </button>
    </div>

    <mat-tab-group class="pds-primary-tab "(selectedTabChange)="onTabChange($event)" animationDuration="0ms" disableRipple color="primary">

      <mat-tab class="tab-label" label="Clients">
        <div class="client-search">
          <app-search #clientAppSearch (search)="onClientSearch($event)" ></app-search>
        </div>

        <div class="clients-list-container" *ngIf="groupType === ClientHierarchyEnum.SUPER_CLIENT">
          <div class="client-item" *ngFor="let client of clients">
            <a tabindex="0"
               (click)="openClientProfileModal(client)"
               (keyup.space)="openClientProfileModal(client)">{{client.superClientName}} ({{client.superClientId}})
            </a>
          </div>
        </div>

        <div class="clients-list-container" *ngIf="groupType === ClientHierarchyEnum.CLIENT_CODE">
          <div class="client-item" *ngFor="let client of clients">
            <a tabindex="0"
               (click)="openClientProfileModal(client)"
               (keyup.space)="openClientProfileModal(client)">{{client.clientCode}}
            </a>
          </div>
        </div>

        <div class="clients-list-container" *ngIf="groupType === ClientHierarchyEnum.CARRIER">
          <div class="client-item" *ngFor="let client of clients">
            <a tabindex="0"
               (click)="openClientProfileModal(client, true)"
               (keyup.space)="openClientProfileModal(client, true)">{{client.carrierName}} ({{client.carrierId}})
            </a>
          </div>
        </div>

        <app-client-paginator
          #clientsPaginator
          [totalRowCount]="totalClientsCount"
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          (page)="handlePageEvent($event)">
        </app-client-paginator>
      </mat-tab>
      <mat-tab class="tab-label" label="Users">
        <ag-grid-angular
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          (gridReady)="onGridReady($event)"
          [rowHeight]="32"
          [context]="context"
          [pagination]="true"
          [suppressMenuHide]="true"
          class="ag-theme-alpine client-group-user-grid"
          id="user-grid">
        </ag-grid-angular>
        <app-paginator-rows-per-page
          [defaultPaginatorSize]="defaultPaginatorSize"
          [pageSizeOptions]="[50, 100, 150]"
          [gridApi]="gridApi"
          [columnApi]="columnApi"
          id="user-paginator" >
        </app-paginator-rows-per-page>

      </mat-tab>
    </mat-tab-group>
  </cvs-loader-overlay-container>
</div>
