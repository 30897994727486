import {HttpErrorResponse} from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, catchError, forkJoin, map, Observable, of, startWith, Subject, takeUntil} from 'rxjs';
import {
  BannerLink,
  CVSBannerComponentData,
  CVSBannerService,
  CVSBannerType,
  CVSConfirmationDialogContentComponent
} from 'angular-component-library';
import {BasicUserInfo, PBMUserInfo} from 'src/app/models/User';
import {UserActions, UserManagementActions} from '../../models/user-management-actions';
import {UserManagementService} from '../../services/user-management/user-management.service';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {MatDialog} from '@angular/material/dialog';
import {AppManagementService} from 'src/app/services/app-management/app-management.service';
import {PBMAppInfo} from 'src/app/models/AppInfo';
import {
  AddUserFormGroup,
  AppAccessFormGroup,
  ClientAccessFromGroup,
  UserAccessType
} from '../../enums/add-user-form.enum';
import {PBMAccessTypeInfo} from 'src/app/models/PBMAccessTypeInfo';
import {BannerLinkHelperService} from '../../services/bannerlink-helper/banner-link-helper.service';
import {
  appSelectedAndRoleSelectedValidator,
  generalAccessValidator,
  onlyOneClientAccessValidator,
  requiredClientAccessValidator,
  requiredColleagueAffiliationValidator,
  requiredEmployeeIdValidator,
  validEmployeeIdPatternValidator
} from '../../validator/validator.utils';
import {PBMPermissionInfo} from '../../models/PBMPermissionInfo';
import {JsonPatch} from '../../models/JsonPatch';
import {AuthRoles} from '../../enums/auth-roles.enum';
import {MatExpansionPanel} from '@angular/material/expansion';
import {AMSManagementService} from '../../services/audit-management/audit-management.service';
import {Firm} from '../../models/Firm';
import {AddEditModeEnum} from '../../enums/add-edit-form.enum';
import {UserDataService} from '../../services/user-management/data/user-data.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss']
})
export class UserAddEditComponent implements OnInit, OnDestroy, AfterViewInit {
  title;
  titleHelpText = 'All fields required unless marked as optional.';
  cardTitle = 'User Information';
  userTypeTitle = 'User Type';
  basicDetailSubtitle = 'Basic Details';
  myLocationSubtitle = 'Location';
  myLocationError = 'Location required.';
  myFirmError = 'Audit management App is required with Firm selection';
  userAccessSubtitle = 'User Access';
  clientAccessSubtitle = 'Client Access';
  clientNameLabel = 'Client Name';
  clientNameOptionalLabel = 'Client Name (Optional)';
  clientNameHelpText = 'Select one or more clients';
  clientNameHelpTextForClientUser = 'Select a client.';
  clientNameErrorText = 'At least one selected client is required.';
  clientNameErrorTextForClientUser = 'Client is required.';
  activeStatusBeforeEditSaved = '';

  formSubmitAttempt = false;

  userTypeToggleOptions: Array<string> = ['CVS Health Colleague', 'Client User', 'Consultant User'];
  userLocationToggleOptions: Array<string> = ['Onshore', 'Offshore'];
  corporationOptions: Array<string> = ['CVS Health'];
  activeOptions: Array<string> = ['Active', 'Inactive'];
  firmOptions: Array<string> = [];
  filteredFirmOptions: Observable<string[]>;
  firms: Firm[] = [];
  addUserForm: UntypedFormGroup;
  addUserResponse: PBMUserInfo;
  bannerData: CVSBannerComponentData;
  isHorizontalBannerLinks = false;
  hideX = true;
  confirmationDialog: any;
  currentUserType: string;
  userBeingEditedOrCopied: PBMUserInfo;
  clientsObj: any;
  clientsObjForClientUser: any;
  fetchAllDataComplete = false;
  showSpinner: Subject<boolean> = new BehaviorSubject(false);
  unsubscribe$ = new Subject();
  destroy$ = new Subject();
  pbmApps: PBMAppInfo[];
  isAdmin: boolean;
  jsonPatchRequests: JsonPatch[] = [];
  mode: AddEditModeEnum;

  @ViewChildren('appAccessExpansionPanel') private appAccessExpansionPanel: QueryList<MatExpansionPanel>;

  userTypeMap: Map<string, () => void> = new Map([
    [
      UserAccessType.CVS_HEALTH_COLLEAGUE, () => {
      this.addInternalUserValidators();
      this.clearClientUserValidators();
      this.clearConsultantUserValidators();
    }
    ],
    [
      UserAccessType.CLIENT_USER, () => {
      this.clearConsultantUserValidators();
    }
    ],
    [
      UserAccessType.CONSULTANT_USER, () => {
      this.addConsultantUserValidators();
      this.clearClientUserValidators();
    }
    ]
  ]);

  private clearConsultantUserValidators() {
    this.addUserForm.get(AddUserFormGroup.FIRM).clearValidators();
    this.addUserForm.get(AddUserFormGroup.FIRM).setErrors(null);
    this.addUserForm.get(AddUserFormGroup.FIRM).setValue(null);
    this.addUserForm.get(AddUserFormGroup.FIRM).updateValueAndValidity();
    this.destroy$.next(true);
  }

  private clearClientUserValidators() {
    this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).clearValidators();
    this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).setErrors(null);
    this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).setValue(null);
    this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).updateValueAndValidity();
  }

  controlNameToLabelMap = {
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    phone: 'Phone',
    employeeId: 'Employee ID',
    email: 'Email address',
    userType: 'User Type',
    firm: 'firm',
    corporation: 'Colleague Affiliation',
  };

  userAccessTabIndex = 0;

  constructor(private formBuilder: UntypedFormBuilder,
              private currentUserService: CurrentUserService,
              private bannerService: CVSBannerService,
              private userManagementService: UserManagementService,
              private amsManagementService: AMSManagementService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private el: ElementRef,
              private matDialog: MatDialog,
              private appManagementService: AppManagementService,
              private bannerLinkHelperService: BannerLinkHelperService,
              private cdr: ChangeDetectorRef,
              private userDataService: UserDataService,
  ) {
    this.setModeAndTitle();
    this.currentUserService.currentUser$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(user => {
        if (user) {
          this.isAdmin = user.adminUser;
        }
      });
  }

  private setModeAndTitle() {
    if (this.router.getCurrentNavigation().extras?.state.mode === AddEditModeEnum.ADD) {
      this.title = 'Add User';
      this.mode = AddEditModeEnum.ADD;
    } else {
      this.title = 'Edit User';
      this.mode = AddEditModeEnum.EDIT;
      this.showSpinner.next(true);
    }
  }

  ngOnInit(): void {
    if (this.userDataService.userBeingCopied$.value) {
      this.userBeingEditedOrCopied = {...this.userDataService.userBeingCopied$.value} as PBMUserInfo;
    }
    this.currentUserService.logoutStart$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        if (!this.canDeactivate()) {
          this.currentUserService.logoutStop$.next(true);
        }
      });
    this.initForm();

    if(this.userBeingEditedOrCopied){
      this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue(this.userBeingEditedOrCopied.accessType);
    }

    this.fetchAllData();
  }

  ngAfterViewInit() {
    if (this.isCopyMode()) {
      if (this.userBeingEditedOrCopied.internalUser) {
        this.setGeneralAccessFields();
        this.addUserForm.get(AddUserFormGroup.ADMIN_USER).setValue(this.userBeingEditedOrCopied.adminUser);
        this.addUserForm.get(AddUserFormGroup.CLIENT_ADMIN_USER).setValue(this.userBeingEditedOrCopied.adminTypes.includes('Client'));
      }
      this.addUserForm.get(AddUserFormGroup.USER_TYPE).disable();
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.userDataService.setUserBeingCopied(null);
    this.unsubscribe$.next(true);
    this.destroy$.next(true);
  }

  canDeactivate() {
    if (this.addUserForm) {
      return !this.addUserForm.dirty;
    }
    return true;
  }

  showEditPage() {
    if (this.userBeingEditedOrCopied) {
      this.initializeUserForm();

      this.activeStatusBeforeEditSaved = this.userBeingEditedOrCopied.basicUserInfo.active ? 'Active' : 'Inactive';

      this.getClientsForUser();

      this.resetFormValidators();
    }
    this.showSpinner.next(false);
  }

  private getClientsForUser() {
    const fetchedClients = this.userBeingEditedOrCopied.internalUser ? this.clientsObj : this.clientsObjForClientUser;
    if (fetchedClients) {
      const selectedClients = [];
      fetchedClients.forEach((clientObj) => {
        const foundIndex = this.userBeingEditedOrCopied.clients.findIndex((client) => {
          return clientObj.id === client.id;
        });
        if (foundIndex !== -1) {
          selectedClients.push(clientObj);
        }
      });
      if (this.userBeingEditedOrCopied.internalUser) {
        this.clients.setValue(selectedClients);
      } else {
        const clientsForClientUser = this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER);
        selectedClients.length > 0 ? clientsForClientUser.setValue(selectedClients[0]) : clientsForClientUser.setValue(null);
      }
    }
  }

  private initializeUserForm() {
    if (this.isEditMode()) {

      this.cardTitle = this.userBeingEditedOrCopied.basicUserInfo.firstName + ' ' + this.userBeingEditedOrCopied.basicUserInfo.lastName;

      this.addUserForm.get(AddUserFormGroup.FIRST_NAME).setValue(this.userBeingEditedOrCopied.basicUserInfo.firstName);
      this.addUserForm.get(AddUserFormGroup.MIDDLE_NAME).setValue(this.userBeingEditedOrCopied.basicUserInfo.middleName);
      this.addUserForm.get(AddUserFormGroup.LAST_NAME).setValue(this.userBeingEditedOrCopied.basicUserInfo.lastName);
      this.addUserForm.get(AddUserFormGroup.EMAIL).setValue(this.userBeingEditedOrCopied.basicUserInfo.email);
      this.addUserForm.get(AddUserFormGroup.EMAIL).disable();
      this.addUserForm.get(AddUserFormGroup.PHONE).setValue(this.userBeingEditedOrCopied.basicUserInfo.phoneNumber);
      this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue(this.userBeingEditedOrCopied.accessType);
      this.addUserForm.get(AddUserFormGroup.LOCATION).setValue(this.userBeingEditedOrCopied.onshore ? 'Onshore' : 'Offshore');
      this.addUserForm.get(AddUserFormGroup.ACTIVE).setValue(this.userBeingEditedOrCopied.basicUserInfo.active ? 'Active' : 'Inactive');

      if (this.userBeingEditedOrCopied.internalUser) {
        this.addUserForm.get(AddUserFormGroup.ADMIN_USER).setValue(this.userBeingEditedOrCopied.adminUser);
        this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).setValue(this.userBeingEditedOrCopied.employeeId);
        this.addUserForm.get(AddUserFormGroup.CORPORATION).setValue(this.userBeingEditedOrCopied.corporation);
        this.addUserForm.get(AddUserFormGroup.CLIENT_ADMIN_USER).setValue(this.userBeingEditedOrCopied.adminTypes.includes('Client'));
        this.setGeneralAccessFields();
      }
    }

    if (this.isConsultantUser()) {
      this.addUserForm.get(AddUserFormGroup.FIRM).setValue(
        this.firms.find(firm => firm.firmId.toString() === this.userBeingEditedOrCopied.firm)?.firmName
      );
      this.cdr.detectChanges();
    }
  }

  private setGeneralAccessFields() {
    const cvsStandardTemplatePermissions = this.userBeingEditedOrCopied.permissions?.filter(
      permission => permission.permissionCode.includes('_CVS_STANDARD_TEMPLATE'));

    cvsStandardTemplatePermissions.forEach(permission => {
      if (permission.permissionCode.endsWith('CVS_STANDARD_TEMPLATE_COMMERCIAL_LOB')) {
        this.generalAccess.controls[1].get('selected').setValue(true);
        this.generalAccess.controls[1].get('permissionCode').setValue(permission.permissionCode);
        this.generalAccess.controls[1].get('permissionType').setValue(permission.permissionType);
      } else {
        this.generalAccess.controls[0].get('selected').setValue(true);
        this.generalAccess.controls[0].get('permissionCode').setValue(permission.permissionCode);
        this.generalAccess.controls[0].get('permissionType').setValue(permission.permissionType);
      }
    });
  }

  makeEmailFieldReadOnly() {
    return this.isEditMode() ? 'long-field pds-read-only-field' : 'long-field';
  }

  getAppRoleClass(appFg, i) {
    return 'app-toggle-group-' + i + ' ' +
      (appFg && appFg.controls['appSelected'].value &&
      this.isFormControlInValid(appFg.get(AppAccessFormGroup.SELECTED_APP_ROLE)) ? 'group-red-outline' : '');
  }

  isFormControlInValid(control) {
    return control && control.invalid && this.formSubmitAttempt;
  }

  noWhitespaceValidator = (control: UntypedFormControl) => {
    return (control.value || '').trim().length !== 0 ? null : {noWhiteSpace: true};
  };

  initForm() {
    this.addUserForm = this.formBuilder.group({
      userType: [null, Validators.required],
      firstName: ['', [Validators.required, this.noWhitespaceValidator]],
      middleName: [''],
      lastName: ['', [Validators.required, this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('^[0-9]{10}$')]],
      adminUser: [false],
      clientAdminUser: [false],
      employeeId: [''],
      corporation: [null],
      userLocation: [null, Validators.required],
      userFirm: [null],
      userAppAccess: this.formBuilder.array([]),
      usersClientAccess: this.formBuilder.array([]),
      usersClientAccessWithLob: this.formBuilder.array([]),
      usersGroups: this.formBuilder.array([]),
      clients: [null],
      clientsForClientUser: [null],
      active: ['Active'],
      generalAccess: this.formBuilder.array([])
    });

    this.addUserForm.setValidators([
      requiredClientAccessValidator(),
      onlyOneClientAccessValidator(),
      requiredEmployeeIdValidator(),
      validEmployeeIdPatternValidator(),
      requiredColleagueAffiliationValidator()
    ]);

    const generalAccessFormGroup = {
      selected: false,
      permissionType: '',
      permissionCode: ''
    };

    const generalAccessFormGroupLOB = {
      selected: false,
      permissionType: '',
      permissionCode: ''
    };

    const formGroup = this.formBuilder.group(generalAccessFormGroup, {validators: generalAccessValidator});
    const formGroupLOB = this.formBuilder.group(generalAccessFormGroupLOB, {validators: generalAccessValidator});

    this.generalAccess.push(formGroup);
    this.generalAccess.push(formGroupLOB);
  }

  fetchAllData() {
    forkJoin({
      clientsForCvsHealthColleagues: this.userManagementService.getClients('CVSHealthColleague'),
      clientsForClientUsers: this.userManagementService.getClients('ClientUser'),
      appData: this.appManagementService.getAllForAdmin(),
      existingUser: this.activatedRoute.snapshot.params.id ?
        this.userManagementService.getUserByEmail(this.activatedRoute.snapshot.params.id) : of(null),
      amsFirms: this.amsManagementService.getAMSFirms().pipe(catchError(error => of([])))
    }).subscribe((response) => {
      this.fetchAllDataComplete = true;
      this.clientsObj = response.clientsForCvsHealthColleagues;
      this.clientsObjForClientUser = response.clientsForClientUsers;
      if(response.existingUser){
        this.userBeingEditedOrCopied = response.existingUser;
      }
      this.pbmApps = response.appData;
      this.firms = response.amsFirms;
      this.firmOptions = this.firms.map(firm => firm.firmName);
      this.filteredFirmOptions = this.userFirm.valueChanges
        .pipe(startWith(''), map(value => this.filterFirms(value || '')));
      this.buildUserAppAccess();
      this.showEditPage();
      this.addUserForm.markAsPristine();
    });
  }

  clearInternalUserValidators() {
    this.addUserForm.get(AddUserFormGroup.CORPORATION).setErrors(null);
    this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).setErrors(null);
    this.addUserForm.get(AddUserFormGroup.GENERAL_ACCESS).setErrors(null);
    this.addUserForm.get(AddUserFormGroup.CORPORATION).clearValidators();
    this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).clearValidators();
    this.addUserForm.get(AddUserFormGroup.GENERAL_ACCESS).clearValidators();
    this.addUserForm.updateValueAndValidity();
  }

  addInternalUserValidators() {
    if (this.employeeId && this.employeeId.valueChanges) {
      this.employeeId.valueChanges.subscribe(value => {
        if (this.employeeId && this.employeeId.errors && this.employeeId.errors['notUnique'] !== value) {
          this.employeeId.setErrors({notUnique: null});
        }
      });
    }
    if (this.email && this.email.valueChanges) {
      this.email.valueChanges.subscribe(value => {
        if (this.email && this.email.errors && this.email.errors['emailNotFound'] !== value) {
          this.email.setErrors({emailNotFound: null});
        }
      });
    }
  }

  addConsultantUserValidators() {
    let modalOpened = false;
    this.userAppAccess.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(changes => {
        const amsSelected = changes.filter(change => change.appSelected === true && change.appCode === 'AMS');
        if (amsSelected.length) {
          if (!this.userFirm.value && !modalOpened) {
            this.openFirmDropdownErrorModal();
            modalOpened = true;
            this.userFirm.setValidators([Validators.required]);
            this.userFirm.updateValueAndValidity();
          }
        }
        const amsUnselected = changes.filter(change => change.appSelected === false && change.appCode === 'AMS');
        if (amsUnselected.length) {
          modalOpened = false;
          this.userFirm.clearValidators();
          this.userFirm.updateValueAndValidity();
          this.userFirm.reset();
        }
      });
  }

  changeUserType(event) {
    if (!this.currentUserType) {
      this.resetFormValidators();
      this.currentUserType = event.value;
    }

    if (event && this.currentUserType !== event.value) {
      this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue(this.currentUserType);

      this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
        data: {
          headline: 'Are you sure you want to change user type?',
          body: 'Information that has been entered will be lost.',
          actionLabel: 'Confirm',
          cancelLabel: 'Cancel',
          noCloseX: false
        }
      });

      this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
        this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue(event.value);
        this.resetFormFields(event);

        this.currentUserType = event.value;
        this.resetFormValidators();
        this.confirmationDialog.close();
      });

      this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
        this.confirmationDialog.close();
      });
    }
  }

  private resetFormFields(event) {
    switch (event.value) {
      case 'CVS Health Colleague':
        this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue('CVS Health Colleague');
        this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).reset();
        break;
      case 'Client User':
        this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue('Client User');
        this.addUserForm.get(AddUserFormGroup.CLIENTS).reset();
        break;
      case 'Consultant User':
        this.addUserForm.get(AddUserFormGroup.USER_TYPE).setValue('Consultant User');
        this.addUserForm.get(AddUserFormGroup.CLIENTS).reset();
        this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).reset();
        break;
    }

    this.addUserForm.get(AddUserFormGroup.FIRST_NAME).reset();
    this.addUserForm.get(AddUserFormGroup.LAST_NAME).reset();
    this.addUserForm.get(AddUserFormGroup.EMAIL).reset();
    this.addUserForm.get(AddUserFormGroup.PHONE).reset();
    this.addUserForm.get(AddUserFormGroup.ADMIN_USER).reset();
    this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).reset();
    this.addUserForm.get(AddUserFormGroup.CORPORATION).reset();
    this.addUserForm.get(AddUserFormGroup.LOCATION).reset();
    this.addUserForm.get(AddUserFormGroup.FIRM).reset();
    this.addUserForm.get(AddUserFormGroup.GENERAL_ACCESS).reset();
    this.userAppAccess.controls.forEach((appFg) => {
      appFg.get(AppAccessFormGroup.APP_SELECTED).reset();
      appFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).setValue([]);
      appFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).setErrors(null);
    });
  }

  resetFormValidators() {
    this.clearInternalUserValidators();
    this.resetValidatorsBasedOnUserType();
    this.addUserForm.updateValueAndValidity();
  }

  private resetValidatorsBasedOnUserType() {
    this.userTypeMap.get(this.userType.value)();
  }

  setClientNameLabel() {
    return this.isInternalUser() ? this.clientNameOptionalLabel : this.clientNameLabel;
  }

  isInternalUser() {
    return this.userType && this.userType.value === 'CVS Health Colleague';
  }

  isClientUser() {
    return this.userType && this.userType.value === 'Client User';
  }

  isConsultantUser() {
    return this.userType && this.userType.value === 'Consultant User';
  }

  buildUserAppAccess() {
    if (this.pbmApps && this.pbmApps.length > 0) {
      this.pbmApps.filter((app: PBMAppInfo) => app.appCode.toLowerCase() !== 'mypbm' && app.active).forEach(app => {

        const userAppAccessObj = {
          appSelected: false,
          appCode: app.appCode,
          appId: app.id,
          appName: app.appName,
          selectedAppRole: [[]],
          roles: [app.roles],
          appAccessTypes: [app.appAccessTypes],
          active: app.active
        };

        const formGroup = this.formBuilder.group(userAppAccessObj);
        formGroup.setValidators(appSelectedAndRoleSelectedValidator());

        this.userAppAccess.push(formGroup);
      });
    }
    if (!this.currentUserService.currentUser$.value.adminUser) {
      this.populatePatchRequests();
    }
  }

  private populatePatchRequests() {
    const patchMap = new Map<string, JsonPatch>();
    this.userAppAccess.controls.forEach(appAccessFormControl => {
      appAccessFormControl.valueChanges.subscribe(changes => {
        const roleCodes = changes.selectedAppRole.map(role => role.roleCode);
        const rolesPatch = this.buildPatchRequest('add', '/appRoles/' + changes.appCode, roleCodes);
        patchMap.set(changes.appCode, rolesPatch);
        this.jsonPatchRequests = [...patchMap.values()];
      });
    });
  }

  appAccessCheckBoxSelection(event, appAccessFg) {
    if (!event.checked) {
      this.formSubmitAttempt = false;
      appAccessFg.get(AppAccessFormGroup.APP_SELECTED).setValue(false);
      appAccessFg.get(AppAccessFormGroup.APP_SELECTED).setErrors(null);
      appAccessFg.get(AppAccessFormGroup.APP_SELECTED).clearValidators();

      appAccessFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).setValue([]);
      appAccessFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).setErrors(null);
      appAccessFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).clearValidators();
    }
    appAccessFg.get(AppAccessFormGroup.APP_SELECTED).updateValueAndValidity();
    appAccessFg.get(AppAccessFormGroup.SELECTED_APP_ROLE).updateValueAndValidity();
    this.addUserForm.updateValueAndValidity();
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    this.addUserForm.updateValueAndValidity();
    if (this.addUserForm.invalid) {
      let headLine;
      let errorMessage;
      let bannerLinkMethod;

      if (this.addUserForm.controls[AddUserFormGroup.USERS_CLIENT_ACCESS].errors?.moreThanOneClientAssigned) {
        headLine = 'Only One Client Permitted';
        errorMessage = 'Client users may only have access to one client in their profile.';
        bannerLinkMethod = this.createClientAccessToBeReviewedBannerLink();
      } else {
        headLine = 'Required Information Needed';
        errorMessage = 'Provide the following required information in order to proceed with adding a user';
        bannerLinkMethod = this.createInvalidFieldFocusLinks();
      }

      const errorBannerData = {
        hideX: true,
        outletId: '#addUserBanner',
        headline: headLine,
        body: errorMessage,
        bannerLinks: bannerLinkMethod
      };
      this.sendAlert(errorBannerData, CVSBannerType.Error);
    } else {
      this.buildAndSendUserRequest();
    }
  }

  private buildAndSendUserRequest() {
    const pbmUserRequest = this.buildCreateUserRequest();
    this.showSpinner.next(true);
    if (this.userBeingEditedOrCopied && this.isEditMode()) {
      if (this.currentUserService.currentUser$.value.adminUser) {
        this.submitUpdateUserRequest(pbmUserRequest);
      } else {
        this.submitPatchUserRequest(this.buildPatchRequestList(), this.userBeingEditedOrCopied.userPK);
      }
    } else {
      this.submitAddUserRequest(pbmUserRequest);
    }
  }

  submitUpdateUserRequest(pbmUserRequest) {
    this.userManagementService.updateUser(pbmUserRequest)
      .subscribe({
        next: (response: PBMUserInfo) => {
          this.showSpinner.next(false);
          if (response) {
            if (this.currentUserService.currentUser$.value.userPK === response.userPK) {
              const currentUser = {...response};
              currentUser.appRoles = [].concat(...Object.values(response.appRoles));
              this.currentUserService.currentUser$.next(currentUser);
            }
            this.userManagementService.userManagementNotification.next(
              new UserManagementActions(UserActions.USER_EDIT, response, 'SUCCESS')
            );
            this.addUserForm.markAsPristine();
            this.formSubmitAttempt = false;
            this.router.navigate(['/user-management/users']);
          }
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.showSpinner.next(false);
          let errorHeader = 'Failed to save user';
          let errorMessage = 'Please try again or enter a different user.';
          if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().startsWith('Employee ID Already Exists'.toLowerCase())) {
            errorHeader = 'User Employee ID Already Exists';
            errorMessage = 'A user with the same information already exists. Please try again or enter a different user.';
            this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID)
              .setErrors({notUnique: this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).value});
            this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).markAsTouched();
          } else if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().includes('Role cannot be assigned'.toLowerCase())) {
            errorHeader = 'Invalid Role Assignment';
            errorMessage = httpErrorResponse.error.message;
            this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS)
              .setErrors({invalidRoleAssignment: this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS).value});
            this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS).markAsTouched();
          }
          const errorBannerData = {
            hideX: true,
            outletId: '#addUserBanner',
            headline: errorHeader,
            body: errorMessage,
            bannerLinks: this.createInvalidFieldFocusLinks()
          };
          this.sendAlert(errorBannerData, CVSBannerType.Error);
        },
      });
  }

  submitAddUserRequest(pbmUserRequest) {
    if(this.isCopyMode()) {
      pbmUserRequest.userPK = null;
    }
    this.userManagementService.addUser(pbmUserRequest)
      .subscribe({
        next: (response: PBMUserInfo) => {
          this.showSpinner.next(false);
          if (response) {
            this.addUserResponse = response;
            this.userManagementService.userManagementNotification.next(
              new UserManagementActions(UserActions.USER_ADD, response, 'SUCCESS')
            );
            this.addUserForm.markAsPristine();
            this.formSubmitAttempt = false;
            this.router.navigate(['/user-management/users']);
          }
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.showSpinner.next(false);
          let errorHeader = 'Failed to add new user';
          let errorMessage = 'Please try again or enter a different user.';
          if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().startsWith('User Already Exists'.toLowerCase())) {
            errorHeader = 'User Already Exists';
            errorMessage = 'A user with the same information already exists. Please try again or enter a different user.';
          } else if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().startsWith('Employee ID Already Exists'.toLowerCase())) {
            errorHeader = 'User Employee ID Already Exists';
            errorMessage = 'A user with the same information already exists. Please try again or enter a different user.';
            this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID)
              .setErrors({notUnique: this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).value});
            this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).markAsTouched();
          } else if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().includes('Internal User does not exist'.toLowerCase())) {
            errorHeader = 'Invalid Email Address';
            errorMessage = 'The provided email address is not valid, please try again.';
            this.addUserForm.get(AddUserFormGroup.EMAIL)
              .setErrors({emailNotFound: this.addUserForm.get(AddUserFormGroup.EMAIL).value});
            this.addUserForm.get(AddUserFormGroup.EMAIL).markAsTouched();
          } else if (httpErrorResponse.status === 400 && httpErrorResponse.error && httpErrorResponse.error.message &&
            httpErrorResponse.error.message.trim().toLowerCase().includes('Role cannot be assigned'.toLowerCase())) {
            errorHeader = 'Invalid Role Assignment';
            errorMessage = httpErrorResponse.error.message;
            this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS)
              .setErrors({invalidRoleAssignment: this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS).value});
            this.addUserForm.get(AddUserFormGroup.USER_APP_ACCESS).markAsTouched();
          }
          const errorBannerData = {
            hideX: true,
            outletId: '#addUserBanner',
            headline: errorHeader,
            body: errorMessage,
            bannerLinks: this.createInvalidFieldFocusLinks()
          };
          this.sendAlert(errorBannerData, CVSBannerType.Error);
        },
      });
  }

  submitPatchUserRequest(pbmUserPatchRequest, userId) {
    this.userManagementService.patchUser(pbmUserPatchRequest, userId).subscribe({
      next: (user: PBMUserInfo) => {
        this.showSpinner.next(false);
        this.userManagementService.userManagementNotification.next(
          new UserManagementActions(UserActions.USER_EDIT, user, 'SUCCESS')
        );
        this.addUserForm.markAsPristine();
        this.formSubmitAttempt = false;
        this.router.navigate(['/user-management/users']);
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        this.showSpinner.next(false);
        let errorHeader = 'Failed to Update user';
        let errorMessage = 'Please try again or enter a different user.';
        let bannerType = CVSBannerType.Error;

        if (httpErrorResponse.status === 400) {
          if (httpErrorResponse.error?.message?.includes('Cannot edit myPBM Admin')) {
            errorHeader = 'Unable to edit user';
            errorMessage = 'As a product administrator you do not have the authorization to modify this user\'s profile, ' +
              'please contact your myPBM administrator to edit the user\'s profile.';
            bannerType = CVSBannerType.Warning;
          } else {
            errorMessage = httpErrorResponse.error.message + ' Please reach out to myPBM administrator.';
          }
        }

        const errorBannerData = {
          hideX: true,
          outletId: '#addUserBanner',
          headline: errorHeader,
          body: errorMessage,
          bannerLinks: this.createInvalidFieldFocusLinks()
        };
        this.sendAlert(errorBannerData, bannerType);
      }
    });
  }

  private buildPatchRequest(op, path, value) {
    const patch: JsonPatch = {
      op,
      path,
      value
    };
    return patch;
  }

  private buildPatchRequestList() {
    this.buildGeneralPermissionPatchRequest();
    this.buildClientAccessPatchRequest();
    this.buildBasicDetailsInfoPatchRequest();
    this.buildGroupPatchRequest();
    return this.jsonPatchRequests;
  }

  private buildGeneralPermissionPatchRequest() {
    const permissionList = [];
    this.generalAccess.controls.forEach(generalAccessControl => {
      if (generalAccessControl.value.permissionCode) {
        const permission = {permissionCode: generalAccessControl.value.permissionCode};
        permissionList.push(permission);
      }
    });
    const jsonPatch = this.buildPatchRequest('add', '/permissions', permissionList);
    this.jsonPatchRequests.push(jsonPatch);
  }

  private buildClientAccessPatchRequest() {
    const clientAccessList = this.getUsersAssignedClients();
    const jsonPatch = this.buildPatchRequest('add', '/assignedPortalClients', clientAccessList);
    this.jsonPatchRequests.push(jsonPatch);
  }

  private buildBasicDetailsInfoPatchRequest() {
    const pbmUserInfo = this.buildCreateUserRequest();
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/basicUserInfo/firstName', pbmUserInfo.basicUserInfo.firstName));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/basicUserInfo/middleName', pbmUserInfo.basicUserInfo.middleName));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/basicUserInfo/lastName', pbmUserInfo.basicUserInfo.lastName));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/basicUserInfo/phoneNumber', pbmUserInfo.basicUserInfo.phoneNumber));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/basicUserInfo/active', pbmUserInfo.basicUserInfo.active));

    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/onshore', pbmUserInfo.onshore));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/employeeId', pbmUserInfo.employeeId));
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/corporation', pbmUserInfo.corporation));

    if (this.isConsultantUser()) {
      this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/firm', pbmUserInfo.firm));
    }

    // TODO: remove this after the old client table is removed
    this.jsonPatchRequests.push(this.buildPatchRequest('replace', '/clients', pbmUserInfo.clients));
  }

  private buildGroupPatchRequest() {
    const usersAssignedGroups = this.getUsersAssignedGroups();
    const jsonPatch = this.buildPatchRequest('add', '/assignedClientGroups', usersAssignedGroups);
    this.jsonPatchRequests.push(jsonPatch);
  }

  buildCreateUserRequest() {
    const pbmUserRequest = new PBMUserInfo();
    const user = new BasicUserInfo();
    user.firstName = this.addUserForm.get(AddUserFormGroup.FIRST_NAME).value;
    user.middleName = this.addUserForm.get(AddUserFormGroup.MIDDLE_NAME).value;
    user.lastName = this.addUserForm.get(AddUserFormGroup.LAST_NAME).value;
    user.email = this.addUserForm.get(AddUserFormGroup.EMAIL).value;
    user.phoneNumber = this.addUserForm.get(AddUserFormGroup.PHONE).value;
    user.active = this.addUserForm.get(AddUserFormGroup.ACTIVE).value === 'Active';
    pbmUserRequest.basicUserInfo = user;
    pbmUserRequest.onshore = this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Onshore';
    pbmUserRequest.employeeId = this.isInternalUser() ? this.addUserForm.get(AddUserFormGroup.EMPLOYEE_ID).value : null;
    pbmUserRequest.corporation = this.isInternalUser() ? this.addUserForm.get(AddUserFormGroup.CORPORATION).value : null;
    pbmUserRequest.adminUser = this.isInternalUser() ? this.addUserForm.get(AddUserFormGroup.ADMIN_USER).value : null;
    pbmUserRequest.clientAdminUser = this.isInternalUser() ? this.addUserForm.get(AddUserFormGroup.CLIENT_ADMIN_USER).value : null;
    pbmUserRequest.internalUser = this.isInternalUser();
    pbmUserRequest.accessType = this.userType.value;
    pbmUserRequest.appRoles = {};
    this.userAppAccess.controls.forEach((userApp) => {

      if (userApp.get(AppAccessFormGroup.APP_SELECTED).value) {
        pbmUserRequest.appRoles[userApp.get(AppAccessFormGroup.APP_CODE).value] =
          this.createSelectedAppRoleMap(userApp);
      }

    });

    pbmUserRequest.assignedPortalClients = [];
    pbmUserRequest.assignedPortalClients = this.getUsersAssignedClients();

    pbmUserRequest.assignedClientGroups = [];
    pbmUserRequest.assignedClientGroups = this.getUsersAssignedGroups();

    pbmUserRequest.clients = [];
    if (this.isInternalUser() && this.addUserForm.get(AddUserFormGroup.CLIENTS).value) {
      pbmUserRequest.clients = this.addUserForm.get(AddUserFormGroup.CLIENTS).value;
    } else if (this.userType.value === UserAccessType.CLIENT_USER && this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).value) {
      pbmUserRequest.clients.push(this.addUserForm.get(AddUserFormGroup.CLIENTS_FOR_CLIENT_USER).value);
    }

    if (this.isConsultantUser()) {
      pbmUserRequest.firm = this.firms.find(firm => firm.firmName === this.addUserForm.get(AddUserFormGroup.FIRM).value)?.firmId.toString();
    }

    this.setUserRequestGeneralAccessPermissions(pbmUserRequest);

    pbmUserRequest.userPK = this.userBeingEditedOrCopied ? this.userBeingEditedOrCopied.userPK : null;

    pbmUserRequest.termsAccepted = this.userBeingEditedOrCopied ? this.userBeingEditedOrCopied.termsAccepted : null;
    pbmUserRequest.emailPreference = this.userBeingEditedOrCopied ? this.userBeingEditedOrCopied.emailPreference : true;

    return pbmUserRequest;
  }

  private getUsersAssignedGroups() {
    const assignedGroups = [];

    if (this.userClientGroups.value) {
      this.userClientGroups.value.forEach(control => {
        const groupToBeAdded = control.group;

        groupToBeAdded.owner = this.currentUserService.currentUser$.value.basicUserInfo;

        assignedGroups.push(groupToBeAdded);
      });
    }

    return assignedGroups;
  }

  getUsersAssignedClients() {
    const assignedClients = [];
    if (this.usersClientAccess.value) {
      this.usersClientAccess.value.forEach(client => assignedClients.push(client));
    }

    if (this.usersClientAccessWithLob.controls) {
      this.usersClientAccessWithLob.controls.forEach(clientWithLob => {
        if (clientWithLob.get(ClientAccessFromGroup.CLIENT).value) {
          const client = clientWithLob.get(ClientAccessFromGroup.CLIENT).value;
          const clientChecked = clientWithLob.get(ClientAccessFromGroup.CLIENT_SELECTED)?.value;
          if (clientChecked) {
            client.lineOfBusinesses = clientWithLob.get(ClientAccessFromGroup.LOBS_SELECTED)?.value;
            assignedClients.push(client);
          }
        }
      });
    }
    return assignedClients;
  }

  private setUserRequestGeneralAccessPermissions(pbmUserRequest: PBMUserInfo) {
    const addUserFormObj = this.addUserForm.get(AddUserFormGroup.GENERAL_ACCESS).value;

    const cvsStandardTemplatePermissions = [];

    addUserFormObj.forEach(formGroup => {
      if (formGroup.selected && formGroup.permissionCode) {
        cvsStandardTemplatePermissions.push(
          {
            permissionCode: formGroup.permissionCode
          }
        );
      }
    });

    pbmUserRequest.permissions = this.isInternalUser() ? cvsStandardTemplatePermissions as PBMPermissionInfo[] : null;
  }

  createSelectedAppRoleMap(userApp) {
    const selectedAppRolesArray = [];

    userApp.get(AppAccessFormGroup.SELECTED_APP_ROLE).value.forEach(appRole => {
      if (appRole.roleCode) {
        selectedAppRolesArray.push(appRole.roleCode);
      }
    });

    return selectedAppRolesArray;
  }

  createClientAccessToBeReviewedBannerLink(): BannerLink[] {
    return [{
      linkText: 'Client Access to be reviewed.',
      linkFunc: () => {
        this.userAccessTabIndex = 1;
        setTimeout(
          () => this.bannerLinkHelperService.scrollToElement(this.el, 'app-user-client-access'),
          50
        );
      }
    }];
  }

  createInvalidFieldFocusLinks(): BannerLink[] {
    const linkFuncs: BannerLink[] = [];
    const controls = this.addUserForm.controls;
    for (const name in controls) {
      if ((controls[AddUserFormGroup.USER_TYPE].errors?.required || !this.isInternalUser()) &&
        (name === AddUserFormGroup.EMPLOYEE_ID || name === AddUserFormGroup.CORPORATION)) {
        continue;
      }

      if (name === AddUserFormGroup.USER_APP_ACCESS) {
        const appAccessFormArray: UntypedFormArray = controls[name] as UntypedFormArray;
        appAccessFormArray.controls.forEach((fg: UntypedFormGroup, index) => {
          if (fg.controls[AppAccessFormGroup.SELECTED_APP_ROLE].errors?.required) {
            const linkText = 'Role Access for ' + fg.controls['appName'].value + ' is required.';
            linkFuncs.push({
              linkText,
              linkFunc: () => {
                this.userAccessTabIndex = 0;
                this.appAccessExpansionPanel.get(index).expanded = true;
                setTimeout(() => this.bannerLinkHelperService.focusOnToggleAppElement(index, this.el), 500);
              }
            });
          }
        });
        continue;
      }

      if (name === AddUserFormGroup.LOCATION) {
        if (controls[AddUserFormGroup.LOCATION].errors?.required) {
          const linkText = 'Location required.';
          linkFuncs.push({linkText, linkFunc: () => this.bannerLinkHelperService.focusOnElement(name, this.el)});
        }
        continue;
      }

      if (name === AddUserFormGroup.FIRM) {
        if (controls[AddUserFormGroup.FIRM].errors?.required) {
          const linkText = 'Firm is required for Audit Management access. ';
          linkFuncs.push({linkText, linkFunc: () => this.bannerLinkHelperService.focusOnElement(name, this.el)});
        }
        continue;
      }

      if (name === AddUserFormGroup.CLIENTS_FOR_CLIENT_USER && this.userType.value === UserAccessType.CLIENT_USER) {
        let linkText = '';
        this.userAppAccess.controls.forEach((userApp) => {
          if (userApp.get(AppAccessFormGroup.APP_SELECTED).value &&
            controls[AddUserFormGroup.CLIENTS_FOR_CLIENT_USER].errors?.required) {
            linkText = 'Client name required.';
          }
        });
        if (linkText) {
          linkFuncs.push({linkText, linkFunc: () => this.bannerLinkHelperService.openDropdown(name, this.el)});
        }
        continue;
      }

      if (name === AddUserFormGroup.GENERAL_ACCESS) {
        if (this.generalAccess.controls.some(control => !!control.get('permissionType').errors?.required)) {
          linkFuncs.push({
            linkText: 'Permission Type for CVS Caremark Standard Template required.',
            linkFunc: () => {
              this.userAccessTabIndex = 2;
              setTimeout(
                () => this.bannerLinkHelperService.scrollToElement(this.el, 'app-user-general-access-grid'),
                50
              );
            }
          });
        }
        continue;
      }

      if (controls[name].invalid && controls[name].errors &&
        (name !== AddUserFormGroup.CLIENTS && name !== AddUserFormGroup.CLIENTS_FOR_CLIENT_USER &&
          name !== AddUserFormGroup.USERS_CLIENT_ACCESS)) {
        let linkText = this.controlNameToLabelMap[name] + ' required.';
        if (name === AddUserFormGroup.EMAIL && (controls[name].errors?.email || controls[name].errors?.emailNotFound)) {
          linkText = 'Valid email address required.';
        }
        if (name === AddUserFormGroup.EMPLOYEE_ID && controls[name].errors?.pattern) {
          linkText = 'Employee ID must be 7 digits.';
        }
        if (name === AddUserFormGroup.EMPLOYEE_ID && controls[name].errors?.notUnique) {
          linkText = 'Unique Employee ID required.';
        }
        if (name === AddUserFormGroup.PHONE && controls[name].errors?.pattern) {
          linkText = 'Valid phone number required.';
        }
        linkFuncs.push({linkText, linkFunc: () => this.bannerLinkHelperService.focusOnElement(name, this.el)});
      }
    }
    return linkFuncs;
  }

  isNullOrEmpty(value) {
    return (value === null || value.length === 0);
  }

  sendAlert(bannerData, alert: CVSBannerType) {
    this.bannerData = {
      bannerType: alert,
      hideX: bannerData.hideX,
      isHorizontalBannerLinks: this.isHorizontalBannerLinks,
      closeCallBack: this.bannerService.close,
      headline: bannerData.headline,
      body: bannerData.body,
      outletId: bannerData.outletId,
      bannerLinks: bannerData.bannerLinks
    };
    this.bannerService.sendAlert(this.bannerData);
  }

  userAccessTypesContainValue(formGroupField: AbstractControl, userType: string) {
    return formGroupField.value.some((pbmAccessTypeInfo: PBMAccessTypeInfo) => pbmAccessTypeInfo.accessType === userType);
  }

  cleanActiveFormFieldIfUnchanged() {
    if (this.activeStatusBeforeEditSaved === this.active.value) {
      this.active.markAsPristine();
    }
  }

  statusSelectionChange(event) {
    const previousValue = event.value === 'Active' ? 'Inactive' : 'Active';

    // Changing values back to original in case ESC key is pressed to bypass modal
    this.active.setValue(previousValue);

    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure?',
        body: `By changing the user status from ${previousValue} to ${event.value}, ` +
          `the user will ${previousValue === 'Active' ? 'not ' : ''}<br>have ` +
          'access to myPBM applications. Would you like to continue with this change?',
        actionLabel: 'Update Status',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.active.setValue(event.value);
      this.active.markAsDirty();
      this.cleanActiveFormFieldIfUnchanged();
    });

    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
      this.active.setValue(previousValue);
      this.cleanActiveFormFieldIfUnchanged();
    });

    this.cleanActiveFormFieldIfUnchanged();
  }

  openFirmDropdownErrorModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Select Firm',
        body:
          '<br>'
          + 'When Audit Management is selected, the firm field is also required for a Consultant User type.'
          + '<br>',
        actionLabel: 'OK',
        noCloseX: false
      }
    });
    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.userFirm.setErrors({required: true});
      this.formSubmitAttempt = true;
      this.userFirm.markAsTouched();
    });
  }

  exitAddUser() {
    this.router.navigate(['/user-management/users']);
  }

  markFormAsTouched(event, formGroup) {
    event.stopPropagation();
    formGroup.markAsTouched();
  }

  containsAppAdminRole(appFg: FormGroup) {
    return appFg.get('selectedAppRole').value.some(role => role.roleCode.includes(AuthRoles.APP_ADMIN));
  }

  clearFirm(event) {
    event.preventDefault();
    this.userFirm.reset();
  }

  updateFirmDropdownOnOptionSelected() {
    if (this.userFirm.value) {
      this.formSubmitAttempt = true;
    } else {
      this.formSubmitAttempt = false;
    }
    this.markAuditManagementAppAsTouched();
  }

  private markAuditManagementAppAsTouched() {
    this.userAppAccess.controls.map(app => {
      if (app.value.appCode === 'AMS') {
        app.markAsTouched();
      }
    });
  }

  filterFirms(firmValue: string): string[] {
    return this.firmOptions.filter(option => option.toLowerCase().includes(firmValue.toLowerCase()));
  }

  isEditMode() {
    return this.mode === AddEditModeEnum.EDIT;
  }

  isCopyMode() {
    return this.mode === AddEditModeEnum.ADD && !!this.userBeingEditedOrCopied;
  }

  get employeeId() {
    return this.addUserForm.get('employeeId');
  }

  get firstName() {
    return this.addUserForm.get('firstName');
  }

  get middleName() {
    return this.addUserForm.get('middleName');
  }

  get lastName() {
    return this.addUserForm.get('lastName');
  }

  get email() {
    return this.addUserForm.get('email');
  }

  get phone() {
    return this.addUserForm.get('phone');
  }

  get userType() {
    return this.addUserForm.get('userType');
  }

  get corporation() {
    return this.addUserForm.get('corporation');
  }

  get userLocation() {
    return this.addUserForm.get('userLocation');
  }

  get userFirm() {
    return this.addUserForm.get('userFirm') as FormControl;
  }

  get userAppAccess() {
    return this.addUserForm.get('userAppAccess') as UntypedFormArray;
  }

  get usersClientAccess() {
    return this.addUserForm.get('usersClientAccess') as UntypedFormArray;
  }

  get usersClientAccessWithLob() {
    return this.addUserForm.get('usersClientAccessWithLob') as UntypedFormArray;
  }

  get clients() {
    return this.addUserForm.get('clients');
  }

  get clientsForClientUser() {
    return this.addUserForm.get('clientsForClientUser');
  }

  get userClientGroups() {
    return this.addUserForm.get('usersGroups') as FormArray;
  }

  get active() {
    return this.addUserForm.get('active');
  }

  get adminUser() {
    return this.addUserForm.get('adminUser');
  }

  get clientAdminUser() {
    return this.addUserForm.get('clientAdminUser');
  }

  get generalAccess() {
    return this.addUserForm.get('generalAccess') as UntypedFormArray;
  }

  protected readonly UserAccessType = UserAccessType;
}
