import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpHeaderService} from '../http-header/http-header.service';
import {Observable} from 'rxjs';
import {LineOfBusinessInfo} from '../../models/LineOfBusinessInfo';

@Injectable({
  providedIn: 'root'
})
export class LineOfBusinessService {

  private lineOfBusinessBaseUrl = environment.portApiBaseUrl + '/api/v1/lob';

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) { }

  getLineOfBusinesses(): Observable<LineOfBusinessInfo[]> {
    return this.httpClient.get<LineOfBusinessInfo[]>(this.lineOfBusinessBaseUrl, this.httpHeaderService.buildSimpleHeader());
  }
}
