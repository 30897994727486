/* eslint-disable max-len */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'ag-grid-enterprise';
import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('CompanyName=Insight (Orem, UT)_on_behalf_of_CVS Health Corporation,LicensedGroup=myPBM,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=7,AssetReference=AG-037718,SupportServicesEnd=3_February_2024_[v2]_MTcwNjkxODQwMDAwMA==b17e6964e0fd5ae3082363fbe4a9dfb7');


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
