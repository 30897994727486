<div style="width:100%;height:100%">
  <h1 class="header-padding">{{title}}</h1>
  <div class="tile__background-border">
    <cvs-loader-overlay-container [isLoading]="showSpinner | async" [opacity]="0" loadingMessage="Loading">
      <div class="box">
        <ng-container *ngFor="let tile of tiles; let i=index">
          <app-tile [id]="'tile' + i" [tile]="tile"></app-tile>
        </ng-container>
      </div>
    </cvs-loader-overlay-container>
  </div>
</div>
