import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppManagementComponent} from './app-management/app-management.component';
import {ClientManagementComponent} from './client-management/client-management.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {NoAccessLandingPageComponent} from './no-access-landing-page/no-access-landing-page.component';
import {AppDashboardComponent} from './app-management/app-dashboard/app-dashboard.component';
import {
  AddEditPermissionComponent
} from './app-management/app-dashboard/app-permissions/add-edit-permission/add-edit-permission.component';
import {AddEditRoleComponent} from './app-management/app-dashboard/app-roles/add-edit-role/add-edit-role.component';
import {UnsavedChangesGuard} from './guards/unsaved-changes/unsaved-changes.guard';
import {LandingPageGuard} from './guards/landing-page/landing-page.guard';
import {ActiveGuard} from './guards/active/active.guard';
import {AddClientComponent} from './client-management/add-client/add-client.component';
import {ClientProfileComponent} from './client-management/client-profile/client-profile.component';
import {UserTableComponent} from './user-management/user-table/user-table.component';
import {UserAddEditComponent} from './user-management/user-add-edit/user-add-edit.component';
import {AdminTypeEnum} from './enums/admin-type.enum';
import {AdminTypeGuard} from './guards/admin-type-guard/adminType.guard';
import {UsersReportComponent} from './report-management/users-report/users-report.component';
import {ClientsReportComponent} from './report-management/clients-report/clients-report.component';
import {
  ClientGroupManagementComponent
} from './client-management/client-group-management/client-group-management.component';
import {AddEditGroupComponent} from './client-management/add-edit-group/add-edit-group.component';


export const routes: Routes = [
  {
    path: 'landing-page',
    component: LandingPageComponent,
    canActivate: [ActiveGuard, LandingPageGuard],
    data: {
      title: 'myPBM - Landing Page'
    }
  },
  {
    path: 'no-access-apps',
    component: NoAccessLandingPageComponent,
    data: {
      title: 'myPBM - No Access'
    }
  },
  {
    path: 'user-management/users',
    component: UserTableComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - User Management'
    }
  },
  {
    path: 'user-management/users/add',
    component: UserAddEditComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - User Management - Add User'
    }
  },
  {
    path: 'user-management/users/edit/:id',
    component: UserAddEditComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - User Management - Edit User'
    }
  },
  {
    path: 'app-management',
    component: AppManagementComponent,
    canActivate: [ActiveGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - App Management'
    }
  },
  {
    path: 'app-management/manage/:appCode',
    component: AppDashboardComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - :appCode - App Dashboard'
    }
  },
  {
    path: 'app-management/manage/:appCode/permission',
    component: AddEditPermissionComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - :appCode - Add / Edit Permission'
    }
  },
  {
    path: 'app-management/manage/:appCode/role',
    component: AddEditRoleComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP],
      title: 'myPBM - :appCode - Add / Edit Role'
    }
  },
  {
    path: 'client-management',
    component: ClientManagementComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - Client Profile - Client Access'
    }
  },
  {
    path: 'client-management/add',
    component: AddClientComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - Client Profile - Add Client'
    }
  },
  {
    path: 'client-management/client-profile',
    component: ClientProfileComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - View Client Profile'
    }
  },
  {
    path: 'client-group-management',
    component: ClientGroupManagementComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - Client Group Management'
    }
  },
  {
    path: 'client-group-management/add',
    component: AddEditGroupComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - Client Group Management - Add Group'
    }
  },
  {
    path: 'client-group-management/edit',
    component: AddEditGroupComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.CLIENT],
      title: 'myPBM - Client Group Management - Edit Group'
    }
  },
  {
    path: 'report-management/clients-report',
    component: ClientsReportComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP, AdminTypeEnum.CLIENT],
      title: 'myPBM - Clients Reports'
    }
  },
  {
    path: 'report-management/users-report',
    component: UsersReportComponent,
    canActivate: [ActiveGuard, AdminTypeGuard],
    data: {
      adminTypes: [AdminTypeEnum.MYPBM, AdminTypeEnum.APP, AdminTypeEnum.CLIENT],
      title: 'myPBM - Users Reports'
    }
  },
  {
    path: '**',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
