import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Direction} from 'angular-component-library';

@Component({
  selector: 'app-user-app-access-renderer',
  templateUrl: './user-app-access-renderer.component.html',
  styleUrls: ['./user-app-access-renderer.component.scss']
})
export class UserAppAccessRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  numberOfAppsText = '';

  left = Direction.LEFT;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.createNumberOfAppsText();
  }

  createNumberOfAppsText() {
    this.numberOfAppsText =
      this.params.value.length === 1 ? `${this.params.value.length} application` : `${this.params.value.length} applications`;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

}
