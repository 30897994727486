import {CellClickedEvent, ColumnApi, GetDataPath, GridApi, RowNode} from 'ag-grid-community';
import {CVSBannerService} from 'angular-component-library';
import {PBMRoleInfo} from '../../models/PBMRoleInfo';
import {StatusCodes} from '../../enums/add-edit-form.enum';
import {AdminTypeEnum} from '../../enums/admin-type.enum';


export class AgGridHelper {
  static focusFirstCell(event, gridApi: GridApi, columnApi: ColumnApi) {
    // prevents tabbing into the url section
    event.preventDefault();

    // scrolls to the first row
    gridApi.ensureIndexVisible(0);

    // scrolls to the first column
    const firstCol = columnApi.getAllDisplayedColumns()[0];
    gridApi.ensureColumnVisible(firstCol);

    // sets focus into the first grid cell
    gridApi.setFocusedCell(0, firstCol);
  }

  static suppressTab(params) {
    return (params.event.key === 'Tab') || (params.event.key === 'Tab' && params.event.shiftKey);
  }

  public static insertRowsPerPageSelector(gridId?: string, paginatorId?: string) {
    const gridIdQuery = gridId ? `#${gridId} ` : '';
    const paginatorIdQuery = paginatorId ? `#${paginatorId} ` : '';
    const paginator = document.querySelector(gridIdQuery + '.ag-paging-row-summary-panel');
    const pageSizeContainer = document.querySelector(paginatorIdQuery + '.paginator-page-size-container');
    paginator.insertAdjacentElement('beforebegin', pageSizeContainer);
  }

  public static disableRowSelectOnSpecifiedColumns(event: CellClickedEvent, columnsToDisableSelect: string[]) {
    if (!columnsToDisableSelect.includes(event.colDef.headerName)) {
      event.node.setSelected(!event.node.isSelected());
    }
  }

  public static disableRowSelectOnChildNodesAndSpecifiedColumns(event: CellClickedEvent, columnsToDisableSelect: string[]) {
    if (!columnsToDisableSelect.includes(event.colDef.headerName) && event.node.level === 0) {
      event.node.setSelected(!event.node.isSelected());
    }
  }

  public static setUnableToDeleteStyle(unableToDelete: boolean, data, gridApi: GridApi) {
    data.forEach(pbmRoleInfo => {
      pbmRoleInfo.unableToDelete = unableToDelete;
    });
    gridApi.applyTransaction({update: data});
  }

  public static setUnableToDeleteStyleAndCloseBanner(unableToDelete: boolean, data, gridApi: GridApi, cvsBannerService: CVSBannerService) {
    AgGridHelper.setUnableToDeleteStyle(unableToDelete, data, gridApi);
    cvsBannerService.close();
  }

  public static doesExternalFilterPass(node: RowNode, searchValue: string, fieldsIncludedInSearch: string[]) {
    const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const regExp = new RegExp(escapedSearchValue, 'i');

    for (const searchField of fieldsIncludedInSearch) {
      if (node.data && node.data[searchField] && regExp.test(node.data[searchField])) {
        return true;
      }
    }
    return false;
  }

  public static convertRolesToTreeStructure(roles: PBMRoleInfo[]) {
    const rolePermissionList = Object.assign([], roles);
    rolePermissionList.forEach(role => {
      role.permissions.forEach(permission => {
        const treeRoleCode = role.roleCode + ',' + permission.permissionCode;
        rolePermissionList.push({
          ...permission, roleCode: treeRoleCode
        });
      });
    });
    return rolePermissionList;
  }

  public static isRole(rowNode: RowNode) {
    if (rowNode.data?.roleName) {
      return true;
    }
  }

  public static isAppAdminRole(rowNode: RowNode) {
    return rowNode.data?.adminType === AdminTypeEnum.APP;
  }

  public static getRoleDataPath: GetDataPath = (data: any) => {
    return data.roleCode.split(',');
  };

  public static toggleGridSelectable(isGridSelectable: boolean, gridApi: GridApi) {
    if (gridApi) {
      const updateSelectable = (rowNode: RowNode) => {
        rowNode.setRowSelectable(isGridSelectable);
      };
      gridApi.forEachNode(updateSelectable);
    }
  }

  public static filterForOnlyActiveRoles(roles: PBMRoleInfo[]) {
    return roles.filter(role => role.status === StatusCodes.ACTIVE);
  }

  public static dateFilterParams() {
    return {
      filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan'],
      defaultOption: 'equals',
      closeOnApply: false,
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }

        const dateParts = dateAsString.split('/');
        const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      }
    };
  }

}
