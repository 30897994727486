<div class="client-profile-super-clients">
  <div *ngIf="!fromModal" class="back-button-container">
    <button class="cvs-btn-text-only back-button"
            aria-label="Back"
            (click)="onBack()">
      <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
      Back
    </button>
  </div>
  <h1 class="header-padding" *ngIf="!fromModal">{{title}}</h1>
  <div class="tile__background-border" *ngIf="clientProfile">
    <h2 class="header-sub-title">{{clientProfile?.superClientName}}</h2>
    <div class="client-profile-container">
      <div class="label-container">
        <label class="label">Super Client Account ID</label>
        <p>{{clientProfile?.accountId}}</p>
      </div>
      <div class="label-container">
        <label class="label">Account Name</label>
        <p class="client-name">{{clientProfile?.accountName}}</p>
      </div>
      <div class="label-container">
        <label class="label">Account Level</label>
        <p>{{clientProfile?.accountLevel}}</p>
      </div>
      <div class="label-container">
        <label class="label">Status</label>
        <p>{{clientProfile?.status}}</p>
      </div>
      <div class="label-container">
        <label class="label">Super Client ID</label>
        <p>{{clientProfile?.superClientId}}</p>
      </div>
      <div class="label-container">
        <label class="label">Super Client Name</label>
        <p class="client-name">{{clientProfile?.superClientName}}</p>
      </div>
      <div class="label-container">
        <label class="label">Restricted Data Client</label>
        <p>{{clientProfile?.isRDCAccount}}</p>
      </div>
      <div class="label-container">
        <label class="label">Restricted Data Client Level</label>
        <p>{{clientProfile?.superClientRdcLevel ? clientProfile?.superClientRdcLevel : 'N/A'}}</p>
      </div>
      <div class="label-container">
        <label class="label">Market Segment</label>
        <p>{{clientProfile?.marketSegment}}</p>
      </div>
    </div>
  </div>
</div>

<div class="client-profile-clients-grid-container tile__background-border">
  <h2 class="client-codes-header-padding">Client Codes</h2>
  <div class="header-padding">Click a client code to view associated carriers.</div>

  <div class="search-bar-container">
    <div class="search-bar">
      <mat-form-field appearance="legacy">
        <input matInput placeholder="Search" (keydown.enter)="initiateClientSearch()" [(ngModel)]="clientSearchValue"/>
      </mat-form-field>
      <mat-icon class="client-close"
                svgIcon="close-btn--s"
                [tabIndex]="0"
                *ngIf="clientSearchValue"
                (click)="clearClientSearchText()"
                (keydown.enter)="clearClientSearchText()"
                aria-label="Clear Search">
      </mat-icon>
      <button class="cvs-btn-ghost search-button"
              type="button"
              (click)="initiateClientSearch()">
        Search
      </button>
    </div>
  </div>

  <ag-grid-angular
    [columnDefs]="clientColumnDefs"
    [defaultColDef]="defaultClientsColDef"
    (gridReady)="onClientGridReady($event)"
    [rowHeight]="32"
    [context]="context"
    [suppressContextMenu]="true"
    [suppressCellFocus]="true"
    [suppressMenuHide]="true"
    [headerHeight]="0"
    tabIndex="-1"
    class="ag-theme-alpine client-profile-clients-grid">
  </ag-grid-angular>

  <app-client-paginator
    #clientsPaginator
    [totalRowCount]="totalClientsCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="[25, 50, 100]"
    (page)="handlePageEvent($event)">
  </app-client-paginator>
</div>

<div class="client-profile-carriers-grid-container tile__background-border" *ngIf="showCarriers" tabIndex="0">
  <h2 class="client-codes-header-padding">[{{selectedClientCode}}] Client Code - Carriers</h2>
  <div class="header-padding">Below is a list of carriers associated with your chosen client code.</div>

  <div class="search-bar-container">
    <div class="search-bar">
      <mat-form-field appearance="legacy">
        <input matInput placeholder="Search" (keydown.enter)="initiateCarrierSearch()" [(ngModel)]="carrierSearchValue"/>
      </mat-form-field>
      <button class="cvs-btn-ghost search-button"
              type="button"
              (click)="initiateCarrierSearch()">
        Search
      </button>
      <mat-icon class="client-close"
                svgIcon="close-btn--s"
                [tabIndex]="0"
                *ngIf="carrierSearchValue"
                aria-label="Clear Search"
                (click)="clearCarrierSearchText()"
                (keydown.enter)="clearCarrierSearchText()">
      </mat-icon>
    </div>
  </div>
  <p class="carriers-count">[{{carriersCount}}] Carrier(s)</p>
  <ag-grid-angular
    [columnDefs]="carrierColumnDefs"
    [defaultColDef]="defaultCarrierColDef"
    (gridReady)="onCarrierGridReady($event)"
    [rowHeight]="32"
    [context]="context"
    [suppressMenuHide]="true"
    [doesExternalFilterPass]="doesExternalFilterPass"
    [isExternalFilterPresent]="isExternalFilterPresent"
    [pagination]="true"
    class="ag-theme-alpine carrier-grid"
    id="carrierGrid">
  </ag-grid-angular>

  <app-paginator-rows-per-page
    [defaultPaginatorSize]="defaultCarrierPaginatorSize"
    [pageSizeOptions]="[25, 50, 100]"
    [gridApi]="carrierGridApi"
    [columnApi]="carrierColumnApi"
    id="carrierPaginator">
  </app-paginator-rows-per-page>

</div>

