import {AddEditModeEnum} from '../enums/add-edit-form.enum';
import {HttpErrorResponse} from '@angular/common/http';

export function getErrorMessageOnAddOrEdit(map: Map<number, any>, error: HttpErrorResponse, mode: AddEditModeEnum) {
  if (error.status === 500 && mode === AddEditModeEnum.ADD) {
    return map.get(-1);
  } else if (error.status === 500 && mode === AddEditModeEnum.EDIT) {
    return map.get(-2);
  } else {
    return {
      headline: error.error.message.split('|')[0],
      body: error.error.message.split('|')?.[1]
    };
  }
}
