import {
  animate,
  group,
  keyframes,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';

export const tileTrigger = trigger('openState', [
  transition(':enter', [
    group([
      query(':self', [
        animate('100ms cubic-bezier(0,.61,.52,1.01)', keyframes([
          style({
            opacity: 1,
            transform: 'translateY(40px)',
            offset: 0
          }),
          style({
            opacity: 1,
            transform: 'translateY(0px)',
            offset: 1
          })
        ])),
      ]),
    ])
  ]),

  transition(':leave', [
    query(':self', [
      animate('400ms cubic-bezier(.7,.1,.52,1.01)', keyframes([
        style({
          opacity: 1,
          transform: 'translateY(0px)',
          offset: 0
        }),
        style({
          opacity: 1,
          transform: 'translateY(40px)',
          offset: 1
        })
      ])),
    ]),
  ])
]);

