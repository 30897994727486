import {Component, ElementRef, OnInit} from '@angular/core';
import {AppDataService} from '../../../../services/app-management/data/app-data.service';
import {PBMPermissionInfo} from '../../../../models/PBMPermissionInfo';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {map, Observable, of, startWith} from 'rxjs';
import {AppManagementService} from '../../../../services/app-management/app-management.service';
import {Router} from '@angular/router';
import {CVSBannerType, CVSConfirmationDialogContentComponent, Direction} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddEditModeEnum, AddPermissionFormGroup, StatusCodes} from '../../../../enums/add-edit-form.enum';
import {BannerLinkHelperService} from '../../../../services/bannerlink-helper/banner-link-helper.service';
import {PBMUserInfo} from '../../../../models/User';
import {CurrentUserService} from '../../../../services/current-user/current-user.service';
import {AuthRoles} from '../../../../enums/auth-roles.enum';
import {
  asyncValidCharactersValidator,
  isRequiredValidator,
  validCharactersValidator
} from '../../../../validator/validator.utils';
import {HttpErrorResponse} from '@angular/common/http';
import {getErrorMessageOnAddOrEdit} from '../../../../utils/getErrorMessageOnAddOrEdit';
import {PBMAppInfo} from '../../../../models/AppInfo';
import {UserAccessType} from '../../../../enums/add-user-form.enum';
import {FormUtils} from '../../../../utils/form-utils/formUtils';
import {BannerService} from '../../../../services/banner-service/banner.service';

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-edit-permission.component.html',
  styleUrls: ['./add-edit-permission.component.scss']
})
export class AddEditPermissionComponent implements OnInit {

  protected readonly UserAccessType = UserAccessType;
  existingCapabilities$: Observable<any>;
  existingCapabilities: Set<string>;
  selectedPermission: PBMPermissionInfo;
  addEditPermissionForm: UntypedFormGroup;
  right = Direction.RIGHT;
  statusOptions = [
    [StatusCodes.PENDING, StatusCodes.ACTIVE, StatusCodes.INACTIVE]
  ];

  permissionTypeOptions = [
    ['Read', 'Write', 'Create', 'Delete', 'Administration']
  ];
  statusOption = this.statusOptions[0];
  permissionTypeOption = this.permissionTypeOptions[0];
  confirmationDialog: MatDialogRef<any>;
  isMyPbmAdmin: boolean;
  currentUser: PBMUserInfo;
  selectedApp: PBMAppInfo;
  formSubmitted = false;

  editPermissionData: PBMPermissionInfo;
  mode: AddEditModeEnum;
  permissionModeEnum = AddEditModeEnum;

  formControlNameToDisplayNameMap: Map<string, string> = new Map([
    [AddPermissionFormGroup.PERMISSION_ACCESS_TYPES, 'User Access Type'],
    [AddPermissionFormGroup.CAPABILITY_NAME, 'App Capability'],
    [AddPermissionFormGroup.PERMISSION_NAME, 'Permission Name'],
    [AddPermissionFormGroup.PERMISSION_ID, 'Permission ID'],
    [AddPermissionFormGroup.PERMISSION_DESCRIPTION, 'Permission Description'],
    [AddPermissionFormGroup.PERMISSION_TYPE, 'Permission Type']
  ]);

  errorCodeOnAddToBannerDataMap: Map<number, any> = new Map([
    // Default key when error code is not in map
    [-1, {
      headline: 'Failed to Add New Permission',
      body: 'Please try again or enter alternative permission details.'
    }],
    [-2, {
      headline: 'Failed to Update Permission',
      body: 'Please try again or enter alternative permission details.'
    }]
  ]);

  constructor(
    public appDataService: AppDataService,
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private appManagementService: AppManagementService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private bannerService: BannerService,
    private bannerLinkHelperService: BannerLinkHelperService,
    private el: ElementRef,
  ) {
    this.currentUserService.currentUser$.subscribe((user: PBMUserInfo) => this.currentUser = user);
    this.appDataService.currentApp$.subscribe((app: PBMAppInfo) => this.selectedApp = app);

    this.mode = this.router.getCurrentNavigation().extras.state.mode;

    if (this.mode === AddEditModeEnum.EDIT) {
      this.editPermissionData = this.mapRouterDataToPermission(this.router.getCurrentNavigation().extras.state.data);
    }
  }

  ngOnInit(): void {
    this.checkIfMyPbmAdmin();
    this.initForm();
    this.setExistingCapabilities();
    this.setAutocompleteOptions();

    if (this.mode === AddEditModeEnum.EDIT) {
      this.populateDataForEdit();
    }
    this.appDataService.selectedTabIndex = 1;
  }

  private setAutocompleteOptions() {
    this.existingCapabilities$ = this.capabilityName.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCapabilities(value)),
    );
  }

  private filterCapabilities(value: string): string[] {
    return Array.from(this.existingCapabilities).filter(item => item.toLowerCase().includes(value.toLowerCase()));
  }

  private setExistingCapabilities() {
    this.existingCapabilities = new Set(this.selectedApp.permissions.map(permission => permission.capabilityName));
    this.existingCapabilities$ = of(this.existingCapabilities);
  }

  initForm() {
    this.addEditPermissionForm = this.formBuilder.group({
      permissionAccessTypes: FormUtils.createAccessTypeFormGroup(this.formBuilder),
      capabilityName: ['',
        Validators.compose([isRequiredValidator()]),
        Validators.composeAsync([asyncValidCharactersValidator()]),
      ],
      permissionName: ['', [
        isRequiredValidator(),
        validCharactersValidator(),
      ]],
      permissionType: ['', [
        isRequiredValidator()
      ]],
      permissionId: [''],
      permissionDescription: ['', [
        isRequiredValidator()
      ]],
      permissionStatus: [
        this.isMyPbmAdmin ? '' : 'Pending', Validators.required
      ],
    });
  }

  private mapRouterDataToPermission(stateData: any): PBMPermissionInfo {
    return {
      id: stateData.id,
      appId: stateData.appId,
      accessTypes: stateData.accessTypes,
      permissionCode: stateData.permissionCode,
      name: stateData.name,
      permissionDescription: stateData.permissionDescription,
      status: stateData.status,
      capabilityName: stateData.capabilityName,
      permissionType: stateData.permissionType
    } as PBMPermissionInfo;
  }

  checkIfMyPbmAdmin() {
    const adminRole: string[] = Object.values(this.currentUser.appRoles).filter((role: string) => AuthRoles.MYPBM_ADMIN === role);
    this.isMyPbmAdmin = !!adminRole.length;
  }

  populateDataForEdit() {
    this.capabilityName.setValue(this.editPermissionData.capabilityName);
    this.permissionName.setValue(this.editPermissionData.name);
    this.permissionId.setValue(this.editPermissionData.permissionCode);
    this.permissionDescription.setValue(this.editPermissionData.permissionDescription);
    this.permissionStatus.setValue(this.editPermissionData.status);
    this.permissionType.setValue(this.editPermissionData.permissionType);
    this.editPermissionData.accessTypes.forEach(accessType => {
      this.permissionAccessTypes.controls[accessType.accessType].setValue(true);
    });
  }

  onSubmitForm() {
    this.formSubmitted = true;
    this.permissionStatus.setValue(this.permissionStatus.value ? this.permissionStatus.value : StatusCodes.PENDING);
    if (this.addEditPermissionForm.valid) {
      const pbmPermissionInfo: PBMPermissionInfo = {
        id: (this.mode === AddEditModeEnum.ADD ? null : this.editPermissionData.id),
        appId: this.selectedApp.id,
        permissionCode: this.permissionId.value,
        name: this.permissionName.value,
        permissionDescription: this.permissionDescription.value,
        status: this.permissionStatus.value,
        capabilityName: this.capabilityName.value,
        permissionType: this.permissionType.value,
        accessTypes: FormUtils.getAccessTypes(this.permissionAccessTypes),
      };

      switch (this.mode) {
        case AddEditModeEnum.ADD: {
          this.appManagementService.addAppPermission(pbmPermissionInfo).subscribe( {
            next: this.handleAddEditSuccess.bind(this),
            error: this.handleAddEditFailure.bind(this)
          });
          break;
        }

        case AddEditModeEnum.EDIT: {
          if(this.permissionId.dirty) {
            this.openUpdatePermissionIdWarningModal(pbmPermissionInfo);
          } else {
            this.updateAppPermission(pbmPermissionInfo);
          }
          break;
        }
      }

    } else if(FormUtils.getFormValidationErrors(this.addEditPermissionForm).every(error => !error?.duplicate)) {
      const errorBannerData = {
        hideX: true,
        outletId: '#addEditPermissionBanner',
        headline: 'Required Information Needed',
        body: 'Provide the following required information in order to proceed with ' +
          (this.mode === AddEditModeEnum.ADD ? 'adding a' : 'editing the') + ' permission',
        bannerLinks: this.bannerLinkHelperService.createInvalidFieldFocusLinks(
            this.addEditPermissionForm,
            this.formControlNameToDisplayNameMap,
            this.el)
      };
      this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
    }
  }

  private openUpdatePermissionIdWarningModal(pbmPermissionInfo: PBMPermissionInfo) {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to update this Permission ID?',
        body:
          '<br>'
          + 'If this Permission is currently linked to any Roles or User Profiles, this change'
          + '<br>'
          + 'will not be represented in the linked User Profiles.'
          + '<br><br>'
          + 'Warning: This cannot be undone.'
          + '<br><br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.updateAppPermission(pbmPermissionInfo);
    });
  }

  private updateAppPermission(pbmPermissionInfo: PBMPermissionInfo) {
    this.appManagementService.updateAppPermission(pbmPermissionInfo).subscribe({
      next: this.handleAddEditSuccess.bind(this),
      error: this.handleAddEditFailure.bind(this)
    });
  }

  handleAddEditSuccess(response: PBMPermissionInfo) {
    this.addEditPermissionForm.markAsPristine();
    this.formSubmitted = false;

    const headLineText = this.mode === AddEditModeEnum.ADD ?
      `${response.name} has been added` :
      `${response.name} has been updated` ;

    const bodyText = this.mode === AddEditModeEnum.ADD ?
      `The permission ${response.name} has been successfully added and pending approval.` :
      `The permission ${response.name} has been successfully updated.` ;

    this.appManagementService.appManagementNotification.next({
      headLine : headLineText,
      body : bodyText,
      removedAfterMilliseconds: 5000
    });
    this.router.navigate(['/app-management/manage/' + this.selectedApp.appCode]);
  }

  handleAddEditFailure(error: HttpErrorResponse) {
    const errorBannerData = {
      hideX: true,
      outletId: '#addEditPermissionBanner',
      headline: getErrorMessageOnAddOrEdit(this.errorCodeOnAddToBannerDataMap, error, this.mode).headline,
      body: getErrorMessageOnAddOrEdit(this.errorCodeOnAddToBannerDataMap, error, this.mode).body,
    };
    this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
    this.setFieldLevelErrors(error);
  }

  private setFieldLevelErrors(error: HttpErrorResponse) {
    if (error.error?.message.includes('Permission Name')) {
      this.permissionName.setErrors({duplicate: true});
    }
  }

  canDeactivate(): boolean {
    return !this.addEditPermissionForm.dirty;
  }

  exitAddEditPermission() {
    this.router.navigate(['/app-management/manage/' + this.selectedApp.appCode]);
  }

  shouldStyleErrorForPermissionAccessType(){
    return !this.addEditPermissionForm.controls[AddPermissionFormGroup.PERMISSION_ACCESS_TYPES].valid && this.formSubmitted;
  }

  get capabilityName() {
    return this.addEditPermissionForm.get('capabilityName');
  }

  get permissionName() {
    return this.addEditPermissionForm.get('permissionName');
  }

  get permissionId() {
    return this.addEditPermissionForm.get('permissionId');
  }

  get permissionDescription() {
    return this.addEditPermissionForm.get('permissionDescription');
  }

  get permissionStatus() {
    return this.addEditPermissionForm.get('permissionStatus');
  }

  get permissionType() {
    return this.addEditPermissionForm.get('permissionType');
  }

  get permissionAccessTypes() {
    return this.addEditPermissionForm.get('permissionAccessTypes') as UntypedFormGroup;
  }

  defaultEnumOrder = (): number => {
    return 0;
  };
}
