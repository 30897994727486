export enum ClientSearchType {
  SUPER_CLIENT_ID = 'Super Client ID',
  SUPER_CLIENT_NAME = 'Super Client Name',
  CLIENT_CODE = 'Client Code',
  CLIENT_NAME = 'Client Name',
  CARRIER_ID = 'Carrier ID',
  CARRIER_NAME = 'Carrier Name',
  SALESFORCE_ACCOUNT_ID = 'Salesforce Account ID',
  SALESFORCE_ACCOUNT_NAME = 'Salesforce Account Name',
  ALL_CLIENTS = 'All Clients',
  CLIENT_GROUP_NAME = 'Client Group Name'
}
