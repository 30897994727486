import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaderService} from '../http-header/http-header.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {Carrier, ClientCode, ClientHierarchy, SuperClient} from '../../models/ClientHierarchy';
import {BannerData} from '../../models/BannerData';
import {ServerSideUtils} from '../../utils/server-side/serverSideUtils';
import {ClientDTO} from '../../models/ClientDTO';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {
  private clientBaseUrl = environment.portApiBaseUrl + '/api/v1/client/';
  globalSearchValue = '';

  clientManagementNotification: BehaviorSubject<BannerData> = new BehaviorSubject<BannerData>(null);

  clientHierarchyServiceResult: SuperClient;

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) {
  }

  searchClients(accountLevel: string, searchParam: string,
                starRow: number = 0, endRow: number = 50, filterModel: any = []): Observable<SuperClient[]> {
    const queryParamsObject = this.getQueryParamsObject(accountLevel, searchParam, starRow, endRow, filterModel);
    return this.httpClient.get<SuperClient[]>(this.clientBaseUrl + 'search',
      this.httpHeaderService.buildSimpleHeader(queryParamsObject));
  }

  addSuperClient(superClient: SuperClient) {
    return this.httpClient.post(this.clientBaseUrl + 'superClient', superClient, this.httpHeaderService.buildSimpleHeader(null));
  }

  addFullSuperClientHierarchy(superClient: SuperClient) {
    return this.httpClient.post(this.clientBaseUrl + 'superClientHierarchy', superClient, this.httpHeaderService.buildSimpleHeader(null));
  }

  addFullSuperClientHierarchyByCarrierId(carrier: Carrier) {
    return this.httpClient.post(this.clientBaseUrl + 'superClientHierarchyByCarrierId',
      carrier, this.httpHeaderService.buildSimpleHeader(null));
  }

  getClientHierarchyBySuperClientId(superClientId: string): Observable<SuperClient[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/superClient/' + superClientId,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => res.data?.superClient));
  }

  getSuperClientBySuperClientId(superClientId: string): Observable<ClientDTO> {
    return this.httpClient
      .get<ClientDTO>(this.clientBaseUrl + 'superClient/' + superClientId,
        this.httpHeaderService.buildSimpleHeader(null));
  }

  getClientHierarchyBySuperClientName(superClientName: string): Observable<SuperClient[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/superClient/accountName/' + superClientName,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => res.data?.superClient));
  }

  getClientHierarchyByAccountId(accountId: string): Observable<SuperClient[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/' + accountId,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => res.data?.superClient));
  }

  getClientHierarchyByAccountName(accountName: string): Observable<SuperClient[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/accountName/' + accountName,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => res.data?.superClient));
  }

  getClientHierarchyByClientCode(clientCode: string): Observable<ClientCode[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/clientCode/' + clientCode,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => {
          this.clientHierarchyServiceResult = res.data?.superClient[0];
          return res.data?.superClient[0].clients;
        }
      ));
  }

  getClientHierarchyByCarrierId(carrierId: string): Observable<Carrier[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/carrierId/' + carrierId,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => {
        this.clientHierarchyServiceResult = res.data?.superClient[0];
        const clientCode = res.data?.superClient[0].clients[0].clientCode;
        return res.data?.superClient[0].clients[0].carriers.map(carrier => carrier = {...carrier, clientCode});
      }));
  }

  getClientHierarchyByCarrierName(carrierName: string): Observable<Carrier[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/carrierName/' + carrierName,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res =>
        res.data?.superClient[0].clients[0].carriers
      ));
  }

  getFullClientHierarchyByCarrierId(carrierId: string): Observable<SuperClient[]> {
    return this.httpClient
      .get<ClientHierarchy>(this.clientBaseUrl + 'clientHierarchy/fullHierarchy/carrierId/' + carrierId,
        this.httpHeaderService.buildSimpleHeader(null)).pipe(map(res => res.data?.superClient));
  }

  private getQueryParamsObject(accountLevel: string, searchParam: string, starRow: number = 0, endRow: number = 50, filterModel: any = []) {
    const queryParamMap = new Map<string, any>();
    ServerSideUtils.addPaginationQueryParams(starRow, endRow, queryParamMap);
    ServerSideUtils.addFilterQueryParams(filterModel, queryParamMap);

    if (accountLevel){
      queryParamMap.set('accountLevel', 'equals,' + accountLevel);
    }

    if (queryParamMap.has(searchParam)) {
      queryParamMap.set(
        searchParam, [queryParamMap.get(searchParam), this.getGlobalSearchParamFilterType(searchParam) + this.globalSearchValue]);
    } else {
      queryParamMap.set(searchParam, this.getGlobalSearchParamFilterType(searchParam) + this.globalSearchValue);
    }

    return Object.fromEntries(queryParamMap);
  }

  private getGlobalSearchParamFilterType(searchParam: string) {
    let filterType = '';
    switch (searchParam) {
      case 'superClientId':
      case 'carrierId':
      case 'clientCode':
      case 'accountId':
        filterType = 'equals,';
        break;

      default:
        filterType = 'contains,';
    }
    return filterType;
  }
}
