import {Injectable} from '@angular/core';
import {CVSSnackBarData, CVSSnackBarService} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class SnackBarWrapperService {

  constructor(
    private snackBarService: CVSSnackBarService,
  ) {
  }

  postCenterBottomSuccessSnackBar(message: string) {
    this.snackBarService.open({
      iconName: 'check-circle--s',
      message,
      duration: 5,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    } as CVSSnackBarData);
  }

}
