export const enum AddUserFormGroup {
    USER_TYPE = 'userType',
    FIRST_NAME = 'firstName',
    MIDDLE_NAME = 'middleName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    PHONE = 'phone',
    ADMIN_USER = 'adminUser',
    CLIENT_ADMIN_USER = 'clientAdminUser',
    EMPLOYEE_ID = 'employeeId',
    CORPORATION = 'corporation',
    LOCATION = 'userLocation',
    FIRM = 'userFirm',
    USER_APP_ACCESS = 'userAppAccess',
    CLIENTS = 'clients',
    CLIENTS_FOR_CLIENT_USER = 'clientsForClientUser',
    USERS_CLIENT_ACCESS = 'usersClientAccess',
    USERS_CLIENT_ACCESS_WITH_LOB = 'usersClientAccessWithLob',
    GROUPS = 'usersGroups',
    ACTIVE = 'active',
    GENERAL_ACCESS = 'generalAccess'
}

export const enum AppAccessFormGroup {
    APP_SELECTED = 'appSelected',
    APP_CODE = 'appCode',
    SELECTED_APP_ROLE = 'selectedAppRole',
    ROLES = 'roles'
}

export enum ClientAccessFromGroup {
  CLIENT = 'client',
  CLIENT_SELECTED = 'clientSelected',
  LOBS_SELECTED = 'lineOfBusinesses'
}

export enum UserAccessType {
  CVS_HEALTH_COLLEAGUE = 'CVS Health Colleague',
  CLIENT_USER = 'Client User',
  CONSULTANT_USER = 'Consultant User'
}

export const enum AppOverviewFormFields {
  CVS_HEALTH_COLLEAGUE = 'cvsHealthColleague',
  CLIENT_USER = 'clientUser',
  CONSULTANT_USER = 'consultantUser',
  ONSHORE = 'onshore',
  OFFSHORE = 'offshore'
}
