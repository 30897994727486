import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {PageEvent} from '@angular/material/paginator';
import {ClientPaginatorComponent} from '../../ag-grid-utils/client-paginator/client-paginator.component';
import {ClientProfileModalService} from '../../services/client-profile/modal/client-profile-modal.service';
import {ClientDTO} from '../../models/ClientDTO';

@Component({
  selector: 'app-client-group-modal',
  templateUrl: './client-group-modal.component.html',
  styleUrls: ['./client-group-modal.component.scss']
})
export class ClientGroupModalComponent {
  clients: ClientDTO[];
  groupName: string;
  groupType: string;
  groupDescription: string;
  context = {this: this};
  clientGroupingGridApi: GridApi;
  pageSize = 25;
  totalClientsCount: number;
  clientSearchValue = '';
  @ViewChild('clientsPaginator') clientPaginator: ClientPaginatorComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private clientProfileModalService: ClientProfileModalService) {
    this.clients = data?.clients;
    this.groupName = data?.groupName;
    this.groupType = data?.groupType;
    this.groupDescription = data?.groupDescription;
  }

  createHyperlinkElements(firstSpanElementInnerText: string, secondSpanElementInnerText: string){
    const firstSpanElement = document.createElement('span');
    firstSpanElement.classList.add('modal-hyperlink');
    const secondSpanElement = document.createElement('span');

    firstSpanElement.innerText = firstSpanElementInnerText;
    secondSpanElement.innerText = secondSpanElementInnerText;

    return [firstSpanElement, secondSpanElement];
  }

  defaultClientsColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    cellStyle: {display: 'block', paddingLeft: 0},
    cellRenderer: (params: any) => {
      const anchorElement = document.createElement('a');
      let elementsArray: HTMLSpanElement[];

      if (params.value) {
        let filteredClient: ClientDTO;

        switch (params.context.this.groupType) {
          case 'Super Client':
            filteredClient = this.clients.find((client: ClientDTO) => client.superClientName === params.value);
            elementsArray = this.createHyperlinkElements(filteredClient?.superClientName, `(${filteredClient?.superClientId})`);
            break;
          case 'Client Code':
            filteredClient = this.clients.find((client: ClientDTO) => client.clientCode === params.value);
            elementsArray = this.createHyperlinkElements(filteredClient?.clientCode, '');
            break;
          case 'Carrier':
            filteredClient = this.clients.find((client: ClientDTO) => client.carrierId === params.value);
            elementsArray = this.createHyperlinkElements(filteredClient?.carrierName, `(${filteredClient?.carrierId})`);
            break;
          default:
            break;
        }

        if (elementsArray.length) {
          elementsArray.forEach((el: HTMLSpanElement) => anchorElement.appendChild(el));
        }

        ['click', 'keydown'].forEach(evt =>
            anchorElement.addEventListener(evt, (event: any) => {
              if (event.code === 'Space' || event.type === 'click') {
                this.onClientGroupingClicked(params.value);
                event.stopPropagation();
              }
            })
        );
        anchorElement.tabIndex = 0;
        return anchorElement;
      }
    },
    suppressKeyboardEvent: (params: any) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params: any) => AgGridHelper.suppressTab(params),
  };

  clientColumnDefs = [
    {
      headerName: 'Client Grouping',
      field: 'col1',
    },
    {
      headerName: 'Client Grouping',
      field: 'col2',
    },
    {
      headerName: 'Client Grouping',
      field: 'col3',
    },
    {
      headerName: 'Client Grouping',
      field: 'col4',
    },
    {
      headerName: 'Client Grouping',
      field: 'col5',
    },
  ];

  onGridReady(event: GridReadyEvent) {
    this.clientGroupingGridApi = event.api;
    this.clients = this.data.clients;
    this.createAndSetClientRowData(this.clients);
  }

  createAndSetClientRowData(clients: any) {
    let keyValue = '';

    if (this.groupType === 'Super Client') {
      keyValue = 'superClientName';
    } else if (this.groupType === 'Client Code') {
      keyValue = 'clientCode';
    } else {
      keyValue = 'carrierId';
    }

    const data = [];
    let counter = 1;
    let clientRow = {};

    const paginatedClients = this.paginateClients(clients);

    paginatedClients?.forEach( (client: any) => {
      if (counter === 6) {
        data.push(clientRow);
        clientRow = {};
        counter = 1;
      }

      clientRow[`col${counter}`] = client[keyValue];
      counter++;
    });

    if (counter !== 1) {
      data.push(clientRow);
      clientRow = {};
      counter = 1;
    }

    this.clientGroupingGridApi.setRowData(data);
  }

  private paginateClients(clients: any) {
    this.totalClientsCount = clients.length;

    const start = this.clientPaginator.paginator.pageIndex * this.pageSize;
    const end = start + this.pageSize;

    return clients?.slice(start, end);
  }

  initiateClientSearch() {
    this.clientPaginator.paginator.pageIndex = 0;
    this.filterClients();
  }

  clearClientSearchText() {
    this.clientSearchValue = '';
    this.createAndSetClientRowData(this.clients);
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.clientPaginator.paginator.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.filterClients();
  }

  private filterClients() {
    let filteredClients: any;

    if (this.groupType === 'Super Client') {
      filteredClients = this.clients.filter((client: any) => {
        return client.superClientName?.toLowerCase().includes(this.clientSearchValue.toLowerCase());
      });
    } else if (this.groupType === 'Client Code') {
      filteredClients = this.clients.filter((client: any) => {
        return client.clientCode?.toLowerCase().includes(this.clientSearchValue.toLowerCase());
      });
    } else {
      filteredClients = this.clients.filter((client: any) => {
        return client.carrierId?.toLowerCase().includes(this.clientSearchValue.toLowerCase());
      });
    }
    this.createAndSetClientRowData(filteredClients);
  }

  onClientGroupingClicked(clientKey: string) {
    let clientDTO: any;

    if (this.groupType === 'Super Client') {
      clientDTO = this.clients.filter((client: any) => client.superClientName === clientKey);
    } else if (this.groupType === 'Client Code') {
      clientDTO = this.clients.filter((client: any) => client.clientCode === clientKey);
    } else {
      clientDTO = this.clients.filter((client: any) => client.carrierId === clientKey);
    }

    this.clientProfileModalService.openClientProfileModal(clientDTO[0], this.groupType === 'Carrier');
  }
}
