import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {UserAccessType} from '../../enums/add-user-form.enum';
import {AppManagementService} from '../../services/app-management/app-management.service';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {ReportManagementService} from '../../services/report-management/report-management.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {CVSBannerType, CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DownloadFileUtils} from '../../utils/download-file/downloadFileUtils';
import {isRequiredMultiSelectValidator} from '../../validator/validator.utils';
import {BannerLinkHelperService} from '../../services/bannerlink-helper/banner-link-helper.service';
import {BannerService} from '../../services/banner-service/banner.service';
import {UserReportFormGroup} from '../../enums/add-edit-form.enum';
import {ClientDTO} from '../../models/ClientDTO';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {ClientSelectorComponent} from '../../ag-grid-utils/client-selector/client-selector.component';

@Component({
  selector: 'app-users-report',
  templateUrl: './users-report.component.html',
  styleUrls: ['./users-report.component.scss']
})
export class UsersReportComponent implements OnInit {
  @ViewChildren('selectAllCheckbox') private selectAllCheckbox: QueryList<MatCheckbox>;
  @ViewChild('clientSelector') private clientSelector: ClientSelectorComponent;

  protected readonly ClientSearchType = ClientSearchType;

  userReportFormGroup: UntypedFormGroup;
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;
  applicationOptions = [];

  userTypeOptions = [
    UserAccessType.CVS_HEALTH_COLLEAGUE,
    UserAccessType.CLIENT_USER,
    UserAccessType.CONSULTANT_USER
  ];

  formControlNameToDisplayNameMap: Map<string, string> = new Map([
    [UserReportFormGroup.USER_ACCESS_TYPES, 'User Type']
  ]);

  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private appManagementService: AppManagementService,
    private reportManagementService: ReportManagementService,
    private bannerLinkHelperService: BannerLinkHelperService,
    private bannerService: BannerService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.appManagementService.getAllApps().subscribe(apps => {
      this.applicationOptions = apps.filter(app => app.appCode !== 'MYPBM').map(app => app.appName);
    });
  }

  initForm() {
    this.userReportFormGroup = this.formBuilder.group({
      userTypeFormControl: [[], isRequiredMultiSelectValidator()],
      applicationFormControl: [[]],
      clientFormControl: [{}],
    });
  }

  toggleOption(formControl: AbstractControl, options: string[], index: number) {
    if ((this.selectAllCheckbox.get(index).checked) && (formControl.value.length < options.length)) {
      this.selectAllCheckbox.get(index).toggle();
    }
    if ((!this.selectAllCheckbox.get(index).checked) && (formControl.value.length === options.length)) {
      this.selectAllCheckbox.get(index).toggle();
    }
  }

  toggleAllSelection(formControl: AbstractControl, options: string[], index: number) {
    if (this.selectAllCheckbox.get(index).checked) {
      formControl.patchValue([]);
    } else {
      formControl.patchValue([...options.map(item => item)]);
      this.userReportFormGroup.markAsDirty();
    }
    this.selectAllCheckbox.get(index).toggle();
  }

  isIndeterminate(formControl: AbstractControl, options: string[]): boolean {
    return formControl.value && formControl.value.length > 0 &&
      formControl.value.length < options.length;
  }

  onClientDropDownChange() {
    this.userReportFormGroup.markAsDirty();
  }

  onExport(){
    if (this.userReportFormGroup.valid) {
      this.bannerService.closeBanner();
      this.showSpinner.next(true);
      this.reportManagementService.generateUserReport(this.createQueryParamMap()).subscribe({
        next: (blob: Blob) => {
          DownloadFileUtils.downloadFile(blob);
          this.showSpinner.next(false);
        },
        error: () => this.showSpinner.next(false)
      });
    } else {
      const errorBannerData = {
        hideX: true,
        outletId: '#userReportBanner',
        headline: 'Required Information Needed',
        body: 'Provide the following required information in order to generate a report',
        bannerLinks: this.bannerLinkHelperService.createInvalidFieldFocusLinks(
          this.userReportFormGroup,
          this.formControlNameToDisplayNameMap,
          this.el)
      };
      this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
    }
  }

  reset(){
    this.initForm();
    this.selectAllCheckbox.filter(checkBox => checkBox.checked).map(checkbox => checkbox.toggle());
    this.clientSelector.reset();
  }

  onClientSelected(selectedClient: ClientDTO) {
    this.client.setValue(selectedClient);
  }

  openCancelModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Cancel Generating a Report?',
        body:
          '<br>'
          + 'You have started a process to generate a report.'
          + '<br>'
          + '<br>'
          + '<br>If you leave this page before generating a report, another search will be required.'
          + '<br>',
        actionLabel: 'Continue',
        cancelLabel: 'Discard Changes',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.userReportFormGroup.markAsDirty();
    });

    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
      this.reset();
    });
  }

  private createQueryParamMap(): Map<string, any> {
    const queryParamMap = new Map<string, any>();
    queryParamMap.set('accessType', this.userType.value.join());

    if (this.application.value?.length > 0) {
      queryParamMap.set('appName', this.application.value.join());
    }

    this.addClientQueryParam(queryParamMap);

    return queryParamMap;
  }

  private addClientQueryParam(queryParamMap: Map<string, any>) {
    const selectedClient: ClientDTO = this.client.value;

    switch (selectedClient?.accountLevel) {
      case ClientHierarchyEnum.SUPER_CLIENT:
        queryParamMap.set('superClientId', selectedClient.superClientId);
        break;

      case ClientHierarchyEnum.CLIENT_CODE:
        queryParamMap.set('clientCode', selectedClient.clientCode);
        break;

      case ClientHierarchyEnum.CARRIER:
        queryParamMap.set('carrierId', selectedClient.carrierId);
        break;

      default:
        break;
    }
  }

  get userType() {
    return this.userReportFormGroup.get('userTypeFormControl');
  }

  get application() {
    return this.userReportFormGroup.get('applicationFormControl');
  }

  get client() {
    return this.userReportFormGroup.get('clientFormControl');
  }
}
