<div class="expand-button-container">
    <button [disabled]="allRowsExpandedState===AllRowsExpandedStateEnum.ALL_EXPANDED"
            (click)="setRowsExpanded(true)" class="cvs-btn-text-only">
        <mat-icon svgIcon="expand"></mat-icon>
        Expand All
    </button>
    <button [disabled]="allRowsExpandedState===AllRowsExpandedStateEnum.ALL_COLLAPSED"
            (click)="setRowsExpanded(false)" class="cvs-btn-text-only">
        <mat-icon svgIcon="collapse"></mat-icon>
        Collapse All
    </button>
</div>
