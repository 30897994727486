import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {GridApi} from 'ag-grid-community';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() gridApi: GridApi;
  @Output() search = new EventEmitter<string>();

  searchValue: string;

  constructor(private el: ElementRef) {
    this.searchValue = '';
  }

  clearText() {
    this.searchValue = '';
    this.updateFilter();
    this.focusSearchBar();
    this.gridApi?.setQuickFilter('');
  }

  private focusSearchBar() {
    const searchBar = this.el.nativeElement.querySelector('.search-bar input');
    if (searchBar) {
      searchBar.focus();
    }
  }

  updateFilter() {
    this.gridApi?.onFilterChanged();
    this.search.emit(this.searchValue);
  }

  onKeyUp() {
    const searchBar = this.el.nativeElement.querySelector('.search-bar input');
    const value = searchBar.value;
    this.gridApi?.setQuickFilter(value);
  }
}
