import {Component, Input} from '@angular/core';
import {Tile} from '../models/tile.interface';
import {tileTrigger} from '../common/animations';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  animations: [
    tileTrigger
  ]
})
export class TileComponent {

  @Input() tile: Tile;

  constructor(
    private window: Window,
  ) { }

   navigateTo(navigationUrl){
    this.window.location.href = navigationUrl;
   }

}
