<div style="width:100%;height:100%">
  <div id="appDashboardBanner"></div>
  <h1 class="header-padding">{{title}}</h1>
  <div class="tile__background-border">
    <cvs-loader-overlay-container>
      <div class="client-management-header-container">
        <div>
        <h2 class="header-padding">Client Search</h2>
        <p class="client-management-description">Search for existing client access. If your client is not found, proceed
          by adding the client.</p>
        </div>
        <button class="cvs-btn-ghost" (click)="navigateToAddClient()">
          Add Client Profile
        </button>
      </div>
      <div class="client-management-container">
        <div class="client-management-search-container">
          <mat-form-field class="client-management-select">
            <mat-select id="clientManagementSelection" [disableOptionCentering]="true"
                        [(value)]="clientManagementOption" (selectionChange)='onDropDownChange()'>
              <mat-option *ngFor="let clientManagementOption of clientManagementOptions;"
                          [value]="clientManagementOption">
                {{clientManagementOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-client-search style="display: inline-block" #clientSearchComponent
                             [buttonClass]="'cvs-btn-primary'"
                             (searchOutput)="getClientManagementData($event)"
                             [clientSearchType]="clientSearchType"></app-client-search>
        </div>
      </div>
      <div class="error-div" *ngIf="errorTextMessage">
        <mat-icon svgIcon = "info-circle--s"></mat-icon>
        <span> {{errorTextMessage}} </span>
      </div>

      <ag-grid-angular *ngIf="showGrid"
                       [columnDefs]="superClientIdColumnDefs"
                       [defaultColDef]="defaultColDef"
                       (gridReady)="onGridReady($event)"
                       [rowHeight]="32"
                       [context]="context"
                       [rowModelType]="rowModelType"
                       [serverSideInfiniteScroll]="true"
                       [pagination]="true"
                       [suppressMenuHide]="true"
                       class="ag-theme-alpine client-access-grid">
      </ag-grid-angular>
      <app-paginator-rows-per-page
        *ngIf="showGrid"
        [defaultPaginatorSize]="defaultPaginatorSize"
        [pageSizeOptions]="[50, 100, 150]"
        [gridApi]="gridApi"
        [columnApi]="columnApi">
      </app-paginator-rows-per-page>
    </cvs-loader-overlay-container>
  </div>
</div>
