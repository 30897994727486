import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ClientDTO} from '../../models/ClientDTO';
import {BehaviorSubject, Subject} from 'rxjs';
import {ReportManagementService} from '../../services/report-management/report-management.service';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {DownloadFileUtils} from '../../utils/download-file/downloadFileUtils';
import {ClientSelectorComponent} from '../../ag-grid-utils/client-selector/client-selector.component';
import {BannerLink, CVSBannerType, CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BannerService} from '../../services/banner-service/banner.service';
import {ClientReportFormGroup} from '../../enums/add-edit-form.enum';

@Component({
  selector: 'app-clients-report',
  templateUrl: './clients-report.component.html',
  styleUrls: ['./clients-report.component.scss']
})
export class ClientsReportComponent implements OnInit {
  @ViewChild('clientSelector') private clientSelector: ClientSelectorComponent;

  clientReportFormGroup: UntypedFormGroup;
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;

  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  formControlNameToDisplayNameMap: Map<string, string> = new Map([
    [ClientReportFormGroup.CLIENT_OPTION, 'Client Option required.']
  ]);

  constructor(
    private matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private reportManagementService: ReportManagementService,
    private bannerService: BannerService,
    private el: ElementRef,
    ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.clientReportFormGroup = this.formBuilder.group({
      clientOption: [null, Validators.required],
      clientFormControl: [{}],
    });
  }

  onClientSelected(selectedClient: ClientDTO) {
    this.client.setValue(selectedClient);
  }

  onClientDropDownChange() {
    this.clientReportFormGroup.markAsDirty();
  }

  onExport() {
    if (this.clientReportFormGroup.valid) {
      this.bannerService.closeBanner();
      this.showSpinner.next(true);
      this.reportManagementService.generateClientReport(this.createQueryParamMap()).subscribe({
          next: (blob: Blob) => {
            DownloadFileUtils.downloadFile(blob);
            this.showSpinner.next(false);
          },
          error: () => this.showSpinner.next(false)
        });
    } else {
      const errorBannerData = {
        hideX: true,
        outletId: '#clientReportBanner',
        headline: 'Required Information Needed',
        body: 'Provide the following required information in order to generate a report',
        bannerLinks: this.createInvalidFieldFocusLinks()
      };
      this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
    }
  }

  private createQueryParamMap(): Map<string, any> {
    const queryParamMap = new Map<string, any>();

    const selectedClient: ClientDTO = this.client.value;

    switch (selectedClient?.accountLevel) {
      case ClientHierarchyEnum.SUPER_CLIENT:
        queryParamMap.set('superClientId', selectedClient.superClientId);
        queryParamMap.set('accountLevel', selectedClient?.accountLevel);
        break;

      case ClientHierarchyEnum.CLIENT_CODE:
        queryParamMap.set('clientCode', selectedClient.clientCode);
        queryParamMap.set('accountLevel', selectedClient?.accountLevel);
        break;

      case ClientHierarchyEnum.CARRIER:
        queryParamMap.set('carrierId', selectedClient.carrierId);
        queryParamMap.set('accountLevel', selectedClient?.accountLevel);
        break;

      default:
        break;
    }

    return queryParamMap;
  }

  private createInvalidFieldFocusLinks() {
    const linkFuncs: BannerLink[] = [];
    const controls = this.clientReportFormGroup.controls;

    for (const name in controls) {
      if (controls[name].errors) {
        linkFuncs.push({
          linkText: this.formControlNameToDisplayNameMap.get(name),
          linkFunc: () => { this.el.nativeElement.querySelector('#' + name)?.focus(); }
        });
      }
    }

    return linkFuncs;
  }

  get client() {
    return this.clientReportFormGroup.get('clientFormControl');
  }

  get clientOption() {
    return this.clientReportFormGroup.get('clientOption') as FormControl;
  }

  openCancelModalForClientReport() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Cancel Generating a Report?',
        body:
          '<br>'
          + 'You have started a process to generate a report.'
          + '<br>'
          + '<br>'
          + '<br>If you leave this page before generating a report, another search will be required.'
          + '<br>',
        actionLabel: 'Continue',
        cancelLabel: 'Discard Changes',
        noCloseX: false
      }
    });
    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.clientReportFormGroup.markAsDirty();
    });
    this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
      this.reset();
    });
  }

  reset(){
    this.initForm();
    this.clientSelector.reset();
  }

}
