
export class DownloadFileUtils {

  public static downloadFile(blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    downloadLink.download = blob['name'] || 'download';
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

}
