import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {ColumnApi, GridApi, RowClassRules} from 'ag-grid-community';
import {
  RoleCellRendererComponent
} from '../../ag-grid-utils/cell-renderers/role-cell-renderer/role-cell-renderer.component';
import {ComponentTypeEnum} from '../../enums/component-types.enum';
import {AppAccessFormGroup} from '../../enums/add-user-form.enum';
import {PBMRoleInfo} from '../../models/PBMRoleInfo';
import {StatusCodes} from '../../enums/add-edit-form.enum';
import {PBMUserInfo} from '../../models/User';
import {AdminTypeEnum} from '../../enums/admin-type.enum';


@Component({
  selector: 'app-user-app-access-role-grid',
  templateUrl: './user-app-access-role-grid.component.html',
  styleUrls: ['./user-app-access-role-grid.component.scss']
})
export class UserAppAccessRoleGridComponent implements OnInit, OnChanges {
  @Input() gridInputData: any;
  @Input() appFgControls: any;
  @Input() userBeingEditedOrCopied: any;
  @Input() userTypeValue: any;
  @Input() adminUserValue: any;
  @Input() firmValue: any;
  @Input() cdr: ChangeDetectorRef;
  @Input() currentUser: PBMUserInfo;

  protected readonly agGridHelper = AgGridHelper;

  gridApi: GridApi;
  columnApi: ColumnApi;
  context = {this: this};
  rolesByUserTypeAndActive = [];
  userBeingEditedLastSavedRoles = [];
  componentType: ComponentTypeEnum = ComponentTypeEnum.USER_APP_ACCESS_ROLE_GRID;

  frameworkComponents = {
    roleCellRendererComponent: RoleCellRendererComponent,
  };

  autoGroupColumnDef = {
    headerName: 'Role Name',
    minWidth: 220,
    headerCheckboxSelection: false,
    showDisabledCheckboxes: true,
    checkboxSelection: (params) => {
      return (AgGridHelper.isRole(params) && this.currentUser.adminUser)
        || this.isNotAdminUserAndRoleIsNotAppAdminRole(params);
    },
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'roleCellRendererComponent'
    },
    valueGetter: (params) => {
      return AgGridHelper.isRole(params) ? params.data.roleName : null;
    }
  };

  private isNotAdminUserAndRoleIsNotAppAdminRole(params) {
    return !this.currentUser.adminUser && !AgGridHelper.isAppAdminRole(params) && AgGridHelper.isRole(params);
  }

  colDefs = [
    {
      headerName: 'Permission Name',
      field: 'name',
    },
    {
      headerName: 'Permission ID',
      field: 'permissionCode',
    },
    {
      headerName: 'Permission Description',
      field: 'description',
      width: 150,
      flex: 2,
      cellRenderer: (params) => {
        if(!AgGridHelper.isRole(params)) {
          return params.value;
        }
      }
    },
    {
      headerName: 'Permission Type',
      field: 'permissionType',
    }
  ];

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: false,
    filter: false,
    suppressMenu: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  ngOnChanges(changes: SimpleChanges) {
    const currentAppSelectedState = !!changes.gridInputData?.currentValue?.appSelected;
    const previousCurrentAppSelectedState = !!changes.gridInputData?.previousValue?.appSelected;

    this.refreshRolesOnUserTypeChange(changes);

    this.selectAppAndAdminRolesOnCheckingMyPbmAdmin(changes);
    this.checkAuditManagementAppOnFirmChange(changes);

    this.toggleGridSelectableOnAppSelected(previousCurrentAppSelectedState, currentAppSelectedState);
  }

  private refreshRolesOnUserTypeChange(changes: SimpleChanges) {
    if (!!changes.userTypeValue && this.gridApi) {
      this.rolesByUserTypeAndActive = this.convertRolesToTreeStructureForActivePermissionsOnly(
        AgGridHelper.filterForOnlyActiveRoles(this.filterRolesByAccessType(this.gridInputData.roles)));

      this.gridApi.setRowData(this.rolesByUserTypeAndActive);

      AgGridHelper.toggleGridSelectable(false, this.gridApi);
    }
  }

  private selectAppAndAdminRolesOnCheckingMyPbmAdmin(changes: SimpleChanges) {
    if (changes.adminUserValue?.currentValue === true && !changes.adminUserValue?.firstChange) {
      this.appFgControls.appSelected.setValue(true);
      this.cdr.detectChanges();

      this.gridApi.forEachNode(node => {
        if (node.data.adminType === AdminTypeEnum.APP && AgGridHelper.isRole(node)) {
          node.setSelected(true);
        }
      });

    } else if (changes.adminUserValue?.currentValue === false && !changes.adminUserValue?.firstChange) {
      this.gridApi.forEachNode(node => {
        if (node.data.adminType === AdminTypeEnum.APP && AgGridHelper.isRole(node)) {
          node.setSelected(false);
        }
      });

      if (this.gridApi.getSelectedRows().length === 0) {
        this.appFgControls.appSelected.setValue(false);
        this.cdr.detectChanges();
      }
    }
  }

  private toggleGridSelectableOnAppSelected(previousCurrentAppSelectedState: boolean, currentAppSelectedState: boolean) {
    if (previousCurrentAppSelectedState === false &&
      currentAppSelectedState === true &&
      this.gridApi) {
      AgGridHelper.toggleGridSelectable(true, this.gridApi);
      this.markExistingRolesAsChecked();
    } else if (previousCurrentAppSelectedState === true &&
      currentAppSelectedState === false &&
      this.gridApi) {
      this.gridApi.deselectAll();
      AgGridHelper.toggleGridSelectable(false, this.gridApi);
    }
  }

  ngOnInit(): void {
    this.rolesByUserTypeAndActive = this.convertRolesToTreeStructureForActivePermissionsOnly(
      AgGridHelper.filterForOnlyActiveRoles(this.filterRolesByAccessType(this.gridInputData.roles)));

    this.setUserBeingEditedLastSavedRoles();
  }

  filterRolesByAccessType(roles: any[]) {
    if (roles && roles.length > 0) {

      return roles.filter(
          (role) => role.accessTypes.some(type => type.accessType === 'ALL') ||
              role.accessTypes.some(type => type.accessType === this.userTypeValue));
    }
    return [];
  }

  markExistingRolesAsChecked() {
    if (this.userBeingEditedOrCopied) {

      if (this.userBeingEditedOrCopied.appRoles.hasOwnProperty(this.gridInputData.appCode) &&
          this.userBeingEditedOrCopied.appRoles[this.gridInputData.appCode].length > 0) {
        this.appFgControls[AppAccessFormGroup.APP_SELECTED].setValue(true);
      }

      this.gridApi.forEachNode((node) => {
        if (AgGridHelper.isRole(node) &&
            this.userBeingEditedOrCopied.appRoles.hasOwnProperty(this.gridInputData.appCode) &&
            this.userBeingEditedOrCopied.appRoles[this.gridInputData.appCode].includes(node.data.roleCode)) {
          node.setSelected(true);
        }
      });

    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setRowData(this.rolesByUserTypeAndActive);
    this.markExistingRolesAsChecked();

    if (this.gridInputData['appSelected']) {
      AgGridHelper.toggleGridSelectable(true, this.gridApi);
    } else {
      AgGridHelper.toggleGridSelectable(false, this.gridApi);
    }
  }

  public isRowSelectable = () => {
    return true;
  };

  setUserBeingEditedLastSavedRoles() {
    if (this.userBeingEditedOrCopied) {
      const appCode = this.appFgControls[AppAccessFormGroup.APP_CODE].value;

      if (this.userBeingEditedOrCopied.appRoles.hasOwnProperty(appCode)) {
        const userBeingEditedAppRoles = this.userBeingEditedOrCopied.appRoles[appCode];
        this.userBeingEditedLastSavedRoles = this.rolesByUserTypeAndActive.filter(role => userBeingEditedAppRoles.includes(role.roleCode));
      }
    }
  }

  rowSelected() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.appFgControls[AppAccessFormGroup.SELECTED_APP_ROLE].setValue(selectedRows);

    if (this.userBeingEditedOrCopied) {
      this.markAsDirtyOnRolesSelectionChange(selectedRows);
    }
  }

  private markAsDirtyOnRolesSelectionChange(selectedRows){
    if (this.userBeingEditedLastSavedRoles.length !== selectedRows.length ||
        !this.userBeingEditedLastSavedRoles.every(lastSavedRole => selectedRows.some(
            selectedRow => selectedRow.roleCode === lastSavedRole.roleCode))) {
      this.appFgControls?.[AppAccessFormGroup.SELECTED_APP_ROLE].markAsDirty();
    }
  }

  rowClassRules: RowClassRules = {
    'hide-permission-checkbox': (params) => {
      if (!AgGridHelper.isRole(params.node)) {
        return true;
      } else {
        return false;
      }
    }
  };

  convertRolesToTreeStructureForActivePermissionsOnly(roles: PBMRoleInfo[]) {
    const rolePermissionList = Object.assign([], roles);
    rolePermissionList.forEach(role => {
      role.permissions.forEach(permission => {
        if (permission.status === StatusCodes.ACTIVE) {
          const treeRoleCode = role.roleCode + ',' + permission.permissionCode;
          rolePermissionList.push({
            ...permission, roleCode: treeRoleCode
          });
        }
      });
    });
    return rolePermissionList;
  }

  private checkAuditManagementAppOnFirmChange(changes: SimpleChanges) {
    const appCode = this.appFgControls.appCode.value;
    if (changes.firmValue?.currentValue && appCode === 'AMS') {
      this.appFgControls.appSelected.setValue(true);
      this.cdr.detectChanges();
    } else if (changes.firmValue?.currentValue === null && appCode === 'AMS') {
      this.appFgControls.appSelected.setValue(false);
      this.cdr.detectChanges();
    }
  }
}
