import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserManagementService} from 'src/app/services/user-management/user-management.service';

@Component({
  selector: 'app-legal-modal',
  templateUrl: './legal-modal.component.html',
  styleUrls: ['./legal-modal.component.scss']
})
export class LegalModalComponent {
  submitted = false;

  noCloseX: boolean;
  headline: string;
  body: any;
  errorMsg: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private userManagementService: UserManagementService) {}

  continueClick(){
    this.submitted = true;
    this.userManagementService.updateTerms(this.submitted).subscribe();
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

  cancelClick(){
    document.querySelector('a.sign-out').setAttribute('id', 'sign-out');
    document.getElementById('sign-out').click();
  }
}
