import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {HttpHeaderService} from '../http-header/http-header.service';
import {environment} from '../../../environments/environment';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportManagementService {
  private baseUrl = environment.portApiBaseUrl + '/api/v1/report';

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) {
  }

  generateUserReport(queryParamMap: Map<string, any>): Observable<Blob> {
    return this.httpClient.get<Blob>( this.baseUrl + '/csv/users',
      this.httpHeaderService.buildSimpleBlobHeader(Object.fromEntries(queryParamMap)) as any)
          .pipe(map((response: HttpResponse<Blob>) => {
            return this.mapHttpResponseToBlob(response);
          }));
  }

  generateClientReport(queryParamMap: Map<string, any>): Observable<Blob> {
    return this.httpClient.get<Blob>( this.baseUrl + '/csv/clients',
      this.httpHeaderService.buildSimpleBlobHeader(Object.fromEntries(queryParamMap)) as any)
      .pipe(map((response: HttpResponse<Blob>) => {
        return this.mapHttpResponseToBlob(response);
      }));
  }

  private mapHttpResponseToBlob(response: HttpResponse<Blob>): Blob {
    const headers = response.headers;
    const blob = new Blob([response.body], {type: headers.get('content-type')});
    blob['name'] = this.getFileNameFromContentDisposition(headers);
    return blob;
  }

  private getFileNameFromContentDisposition(headers: HttpHeaders): string {
    const contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return 'download';
  }

}
