
<div class="tile"
tabindex="0"
(click)="navigateTo(tile.navigationUrl)"
(keydown.enter)="navigateTo(tile.navigationUrl)"
(keydown.space)="navigateTo(tile.navigationUrl)"
*ngIf="tile">
    <div class="tile__app-icon-container">
        <mat-icon class="tile__app-icon" svgIcon={{tile.imageUrl}} *ngIf="tile.imageUrl"></mat-icon>
        <mat-icon class="tile__app-icon" svgIcon='laptop--m' *ngIf="!tile.imageUrl"></mat-icon>
    </div>
    <div class="tile__container">
        <div class="tile__container__sub-title">{{tile.subtitle}}</div>
        <div class="tile__container__description"> {{tile.description}}
        </div>
    </div>
</div>
