import {Component, Input} from '@angular/core';
import {AllRowsExpandedState} from '../../enums/all-rows-expanded-state.enum';

@Component({
  selector: 'app-expand-collapse-buttons',
  templateUrl: './expand-collapse-buttons.component.html',
  styleUrls: ['./expand-collapse-buttons.component.scss']
})
export class ExpandCollapseButtonsComponent {

  protected readonly AllRowsExpandedStateEnum = AllRowsExpandedState;
  protected allRowsExpandedState: AllRowsExpandedState = AllRowsExpandedState.ALL_COLLAPSED;
  @Input() gridApi: any;


  setRowsExpanded(isExpanded: boolean) {
    this.allRowsExpandedState = isExpanded ? AllRowsExpandedState.ALL_EXPANDED : AllRowsExpandedState.ALL_COLLAPSED;

    this.gridApi.forEachNode(node => {
      node.expanded = isExpanded;
    });

    this.gridApi.onGroupExpandedOrCollapsed();
  }

  updateExpandCollapseButtons() {
    const allGroupedRows = this.gridApi.getModel().rowsToDisplay.filter(row => row.allChildrenCount > 0);

    if (allGroupedRows.length > 0) {
      const areAllGroupRowsExpanded = !allGroupedRows.some(row => {
        if (row.expanded !== allGroupedRows[0].expanded) {
          this.allRowsExpandedState = AllRowsExpandedState.EXPANDED_AND_COLLAPSED;
          return true;
        }
      });

      if (areAllGroupRowsExpanded) {
        this.allRowsExpandedState = allGroupedRows[0].expanded ?
          AllRowsExpandedState.ALL_EXPANDED : AllRowsExpandedState.ALL_COLLAPSED;
      }
    }
  }
}
