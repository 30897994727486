import {PBMUserInfo} from 'src/app/models/User';

export enum UserActions {
  USER_ADD,
  USER_DELETE,
  USER_EDIT
}
export class UserManagementActions {
  action: UserActions;
  user: PBMUserInfo;
  status: string;
  statusMessage: string;

  constructor(action: UserActions, user: PBMUserInfo, status: string, statusMessage?: string) {
    this.action = action;
    this.user = user;
    this.status = status;
    this.statusMessage= statusMessage;
  }
}
