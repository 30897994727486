<span>  |
    <a tabindex="0"
       aria-label="Privacy Policy"
       (keydown.enter)="showModal('privacy')"
       (keydown.space)="showModal('privacy')"
       (click)="showModal('privacy')">Privacy Policy</a>
</span>
<span>  |
    <a tabindex="0"
       aria-label="Terms and Conditions"
       (keydown.enter)="showModal('terms')"
       (keydown.space)="showModal('terms')"
       (click)="showModal('terms')">Terms and Conditions</a>
</span>
