import {CVSAuthConfig} from 'angular-component-library';

export const environment = {
  production: false,
  portApiBaseUrl: 'https://portal-api-dev.core-aks-usc.mypbm-np.caremark.com',
  auditManagementApiUrl: 'https://dev.api.mypbm-np.caremark.com/ams-api-dev',
  region: '- DEV',
  appManagementFlag: true,
  authConfig: {
    authorizationUrl: 'https://portal-api-dev.core-aks-usc.mypbm-np.caremark.com//api/v1/auth/authorizedUser/mypbm',
    ssoBaseUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com',
    issuer: 'https://ngamypbmnonprod.b2clogin.com/2a2762e7-d56f-47ab-a72a-df0102c4579a/v2.0/',
    clientId: '2b118e9a-44c9-4b8c-b26e-6955159050f9',
    scope: 'openid offline_access',
    tokenUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin',
    accessDeniedMessage: 'It appears as though you do not have access to use this application.',
    authenticationEnabled: true,
    useAccessToken: false,
    idle: 1800,
    timeOut: 60,
    loginUrl: '/B2C_1A_signup_signin/oauth2/v2.0/authorize?prompt=login',
    logoutUrl: '/B2C_1A_signup_signin/oauth2/v2.0/logout'
  } as CVSAuthConfig,
  appDynamicsKey: 'AD-AAB-ACV-AFE'
};
