import {FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {accessTypeRequiredValidator} from '../../validator/validator.utils';
import {UserAccessType} from '../../enums/add-user-form.enum';

export class FormUtils {
  public static getAccessTypes(accessTypeFormGroup: FormGroup) {
    const accessTypeArray = [];
    Object.keys(accessTypeFormGroup.controls).forEach(controlName => {
      if(accessTypeFormGroup.controls[controlName].value){
        accessTypeArray.push({accessType: controlName});
      }
    });
    return accessTypeArray;
  }

  public static createAccessTypeFormGroup(formBuilder: FormBuilder) {
    const config = {};
    Object.keys(UserAccessType).forEach(accessType => {
      config[UserAccessType[accessType]] = [false];
    });
    return formBuilder.group(config, {validators: accessTypeRequiredValidator()});
  }

  public static getFormValidationErrors(form: UntypedFormGroup) {
    const formErrors = [];
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        formErrors.push(controlErrors);
      }
    });
    return formErrors;
  }
}
