import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientGroupTypeEnum} from '../../enums/client-group-type.enum';
import {CVSBannerComponentData, CVSBannerType, CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {isRequiredValidator, validEmailValidator} from '../../validator/validator.utils';
import {Router} from '@angular/router';
import {AddEditModeEnum, ClientGroupFormGroup, StatusCodes} from '../../enums/add-edit-form.enum';
import {BannerService} from '../../services/banner-service/banner.service';
import {BannerLinkHelperService} from '../../services/bannerlink-helper/banner-link-helper.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {BehaviorSubject, debounceTime, Observable, Subject} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {PBMUserInfo} from '../../models/User';
import {UserManagementService} from '../../services/user-management/user-management.service';
import {MatCheckbox} from '@angular/material/checkbox';
import {PortalGroupInfo} from '../../models/PortalGroupInfo';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {ColDef, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {ClientManagementService} from '../../services/client-management/client-management.service';
import {ClientDTO} from '../../models/ClientDTO';
import {MatTableDataSource} from '@angular/material/table';
import {ClientSearchComponent} from '../../ag-grid-utils/client-search/client-search.component';
import {ClientGroupManagementService} from '../../services/client-group-management/client-group-management.service';
import {LineOfBusinessInfo} from '../../models/LineOfBusinessInfo';
import {LineOfBusinessService} from '../../services/line-of-business/line-of-business.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {FormUtils} from '../../utils/form-utils/formUtils';
import {UserAccessType} from '../../enums/add-user-form.enum';
import {ClientProfileModalComponent} from '../../common/client-profile-modal/client-profile-modal.component';
import {ClientDataService} from '../../services/client-profile/data/client-data.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-edit-group.component.html',
  styleUrls: ['./add-edit-group.component.scss']
})
export class AddEditGroupComponent implements OnInit, AfterViewInit {

  clientGroupModeEnum = AddEditModeEnum;
  currentClientGroupType: string;
  confirmationDialog: any;
  addGroupFormGroup: UntypedFormGroup;
  editOrCopyGroupData: PortalGroupInfo;
  isButtonShown = false;

  initialUserOptions: PBMUserInfo[] = [];
  private userOptions: PBMUserInfo[];

  userOptSubj: Subject<PBMUserInfo[]> = new BehaviorSubject<PBMUserInfo[]>([]);
  userOptSubj$: Observable<PBMUserInfo[]> = this.userOptSubj.asObservable();

  usersSearchInput = new FormControl();

  userChipItems: PBMUserInfo[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  dataSource = new MatTableDataSource<ClientDTO>();
  selectedDataSource = new MatTableDataSource<ClientDTO>();

  @ViewChild('userChipInputRef', { static: true }) userChipInputRef: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('selectAllCheckbox') private selectAllCheckbox: MatCheckbox;
  @ViewChild('clientSearch') private clientSearch: ClientSearchComponent;

  mode: AddEditModeEnum;

  clientGroupOptions = [
    ClientGroupTypeEnum.SUPER_CLIENT,
    ClientGroupTypeEnum.CLIENT_CODE,
    ClientGroupTypeEnum.CARRIER,
  ];

  clientSearchOptions =  [
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      groupType: ClientGroupTypeEnum.SUPER_CLIENT,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      groupType: ClientGroupTypeEnum.SUPER_CLIENT,
      searchParam: 'superClientName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      groupType: ClientGroupTypeEnum.CLIENT_CODE,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CARRIER_ID,
      groupType: ClientGroupTypeEnum.CARRIER,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      groupType: ClientGroupTypeEnum.CARRIER,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
  ];
  clientSearchOption: any;
  isClientNotFoundErrorShown: boolean;

  formControlNameToDisplayNameMap: Map<string, string> = new Map([
    [ClientGroupFormGroup.OWNER_EMAIL, 'Owner\'s Email Address'],
    [ClientGroupFormGroup.GROUP_NAME, 'Client Group Name'],
    [ClientGroupFormGroup.GROUP_DESCRIPTION, 'Client Group Description'],
    [ClientGroupFormGroup.GROUP_USER_ACCESS_TYPE, 'User Access Type']
  ]);

  lobs: LineOfBusinessInfo[] = [];
  showSpinner: Subject<boolean> = new BehaviorSubject(false);

  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;
  defaultPaginatorSize = 25;
  isRowsPerPageSelectorInserted = false;

  statusOptions = [
    [StatusCodes.PENDING, StatusCodes.ACTIVE, StatusCodes.INACTIVE]
  ];
  statusOption = this.statusOptions[0];

  defaultColDef= {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['contains'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Last Name',
      field: 'basicUserInfo.lastName',
      checkboxSelection: true,
      minWidth: 150,
    },
    {
      headerName: 'First Name',
      field: 'basicUserInfo.firstName',
      minWidth: 150,
    },
    {
      headerName: 'Middle Initial',
      field: 'basicUserInfo.firstName',
      minWidth: 75,
      valueGetter: (params) => {
        return params.data.basicUserInfo.middleName?.charAt(0);
      },
    },
    {
      headerName: 'Email Address',
      field: 'basicUserInfo.email',
      minWidth: 250
    },
    {
      headerName: 'Status',
      field: 'basicUserInfo.active',
      minWidth: 250,
      valueGetter: (params) => {
        return params.data.basicUserInfo.active ? 'Active' : 'Inactive';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [StatusCodes.ACTIVE, StatusCodes.INACTIVE],
      },
    },
    {
      headerName: 'Location',
      field: 'onshore',
      minWidth: 200,
      valueGetter: (params) => {
        return params.data.onshore ? 'Onshore' : 'Offshore';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Onshore','Offshore'],
      },
    }
  ];
  bannerData: CVSBannerComponentData;
  subscriptions: any[] = [];

  constructor(private matDialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private bannerService: BannerService,
              private bannerLinkHelperService: BannerLinkHelperService,
              private el: ElementRef,
              private userManagementService: UserManagementService,
              private clientManagementService: ClientManagementService,
              private clientGroupManagementService: ClientGroupManagementService,
              private lineOfBusinessService: LineOfBusinessService,
              private clientDataService: ClientDataService) {
    this.mode = this.router.getCurrentNavigation().extras.state.mode;
    if (this.router.getCurrentNavigation().extras.state.data) {
      this.editOrCopyGroupData = {... this.router.getCurrentNavigation().extras.state.data } as PortalGroupInfo;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.onUserTypeChanges();

    this.showSpinner.next(true);
    this.lineOfBusinessService.getLineOfBusinesses().subscribe({
      next: (result) => {
        this.showSpinner.next(false);
        this.lobs = result;

        if (this.isOnEditMode() || this.isOnCopyMode()) {
          this.populateDataForEditOrCopy();
        }
      },
      error: () => { this.showSpinner.next(false); }
    });
  }

  ngAfterViewInit() {
    document.querySelector('#selectResultsButton')?.setAttribute('type', 'button');
    document.querySelector('#removeResultsButton')?.setAttribute('type', 'button');
  }

  initForm() {
    this.addGroupFormGroup = this.formBuilder.group({
      clientGroupType: [''],
      clientGroupUserAccessTypes: FormUtils.createAccessTypeFormGroup(this.formBuilder),
      ownerEmailAddress: ['', [isRequiredValidator(), validEmailValidator()]],
      clientGroupName: ['', isRequiredValidator()],
      clientGroupDescription: ['', isRequiredValidator()],
      lineOfBusiness: [[]],
      groupStatus: ['', Validators.required],
      clients: [[]],
      users: [[]]
    });
  }

  initUserSearchDropDown(selectedUserTypes: string[]) {
    if (selectedUserTypes.length !== 0) {
      const queryParamMap = new Map<string, any>();
      queryParamMap.set('accessType', selectedUserTypes.join());

      this.userManagementService.getActiveUsers(queryParamMap).subscribe(result => {
        this.initialUserOptions = result;
        this.onUserSearchInputChanges();

        const map: Record<number, boolean> = {};
        this.userChipItems.forEach(user => map[user.userPK] = true);
        const newUserOptions = result.filter(user => !map[user.userPK]);

        this.userOptSubj.next(newUserOptions);
        this.userOptions = [...newUserOptions];
      });
    } else {
      this.initialUserOptions = [];
      this.userOptSubj.next([]);
      this.userOptions = [];
    }
  }

  private populateDataForEditOrCopy() {
    if (this.isOnEditMode()) {
      this.ownerEmail.setValue(this.editOrCopyGroupData.owner?.basicUserInfo.email);
      this.groupName.setValue(this.editOrCopyGroupData.groupName);
      this.groupDescription.setValue(this.editOrCopyGroupData.groupDescription);
      this.groupStatus.setValue(this.editOrCopyGroupData.groupStatus);
      this.editOrCopyGroupData.accessTypes.forEach(accessType => {
        this.groupUserAccessTypes.controls[accessType.accessType].setValue(true);
      });
    }

    this.clientGroupType.setValue(this.editOrCopyGroupData.groupType);
    this.lineOfBusiness.setValue([... this.editOrCopyGroupData.lineOfBusinesses.map(lob => lob.id)]);
    this.editOrCopyGroupData.clients.forEach(clientItem => this.setFormattedClientSearchResult(clientItem));
    this.selectedDataSource.data = this.editOrCopyGroupData.clients;
    this.setDualPicklistCellsOnClick(this.selectedDataSource.data, 'right');
  }

  private setDualPicklistCellsOnClick(clients: ClientDTO[], leftOrRight: string) {
    const selectionComponent = leftOrRight === 'left' ? 'leftSelectionComponent' : 'rightSelectionComponent';
    const singlePicklistCells: NodeListOf<HTMLElement> = document.querySelectorAll(`#${selectionComponent} .mat-cell`);
    singlePicklistCells.forEach(cell => {
      if (clients.length) {
        if (cell.getAttribute('clickListener') !== 'true') {
          cell.addEventListener('click', event => {
            event.stopPropagation();
            this.showCellSuperClient(clients, cell);
          });
          cell.setAttribute('clickListener', 'true');
        }
      }
    });
  }

  private showCellSuperClient(clients: ClientDTO[], cell: HTMLElement) {
    const cellsClient = clients.find(client => client.formattedSearchResult === cell.innerText);
    if (cellsClient) {
      this.clientManagementService.getSuperClientBySuperClientId(cellsClient.superClientId).subscribe( {
          next: this.handleGetSuperClientBySuperClientIdSuccess.bind(this),
          error: this.handleGetSuperClientBySuperClientIdFailure.bind(this)
      });
      this.showSpinner.next(true);
    }
  }

  handleGetSuperClientBySuperClientIdSuccess(superClient: ClientDTO) {
    this.clientDataService.setCurrentClient(superClient as any);
    this.openClientProfileModal(superClient);
    this.showSpinner.next(false);
  }

  handleGetSuperClientBySuperClientIdFailure(err: HttpErrorResponse) {
    this.showSpinner.next(false);
    const errorBannerData = {
      hideX: true,
      outletId: '#addEditGroupBanner',
      headline: 'Error getting super client info',
      body: 'We encountered an error while retrieving the super client information. Please try again.',
    };
    this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
  }

  onGridReady(event: GridReadyEvent) {
    if (this.isOnEditMode() || this.isOnCopyMode()) {
      this.gridApi = event.api;
      this.columnApi = event.columnApi;
      this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
      this.gridApi.setRowData(this.editOrCopyGroupData.users);
      this.gridApi.selectAll();
    }
  }

  onTabChange(event){
    if(this.gridApi && event.tab.textLabel === 'Users' && !this.isRowsPerPageSelectorInserted){
      AgGridHelper.insertRowsPerPageSelector();
      this.isRowsPerPageSelectorInserted = true;
    }
  }

  changeGroupType(event) {
    if (!this.currentClientGroupType) {
      this.currentClientGroupType = event.value;
    }
    if (event && this.currentClientGroupType !== event.value) {
      this.addGroupFormGroup.get('clientGroupType').setValue(this.currentClientGroupType);

      this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
        data: {
          headline: 'Are you sure you want to change client group type?',
          body: 'Information that has been entered will be lost.',
          actionLabel: 'Confirm',
          cancelLabel: 'Cancel',
          noCloseX: false
        }
      });

      this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
        this.initForm();
        this.onUserTypeChanges();
        this.resetSelectedClientsData();
        this.resetSelectedUsersData();

        this.addGroupFormGroup.get('clientGroupType').setValue(event.value);
        this.currentClientGroupType = event.value;
        this.confirmationDialog.close();
      });

      this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
        this.confirmationDialog.close();
      });
    }
  }

  private resetSelectedClientsData() {
    this.selectedDataSource.data = [];
    this.dataSource.data = [];
    this.clientSearch.searchValue.reset();
  }

  private resetSelectedUsersData() {
    this.userChipItems = [];
    this.userOptSubj.next(this.initialUserOptions);
    this.userOptions = [...this.initialUserOptions];
  }

  onSubmitForm() {
    this.groupStatus.setValue(this.groupStatus.value ? this.groupStatus.value : StatusCodes.PENDING);
    if (this.addGroupFormGroup.valid) {
      this.showSpinner.next(true);
      this.users.setValue(this.getSelectedUsers());
      this.clients.setValue(this.selectedDataSource.data);

      if (!this.hasAtLeastOneClient()) {
        this.showSpinner.next(false);
        this.bannerService.closeBanner();
        this.openAtLeastOneClientErrorModal();
        return;
      }

      const portalGroupInfo: PortalGroupInfo = {
        id: (this.mode === AddEditModeEnum.ADD ? null : this.editOrCopyGroupData.id),
        groupType: this.clientGroupType.value,
        accessTypes: FormUtils.getAccessTypes(this.groupUserAccessTypes),
        groupName: this.groupName.value,
        groupDescription: this.groupDescription.value,
        groupStatus: this.groupStatus.value,
        owner: {
          basicUserInfo: {
            email: this.ownerEmail.value
          }
        } as PBMUserInfo,
        clients: this.clients.value,
        users: this.users.value,
        lineOfBusinesses: this.lineOfBusiness.value.map((lobId: number) => ({ id: lobId }))
      };

      switch (this.mode) {
        case AddEditModeEnum.ADD:
          this.clientGroupManagementService.createGroup(portalGroupInfo).subscribe({
            next: this.handleAddEditGroupSuccess.bind(this),
            error: this.handleAddEditGroupFailure.bind(this)
          });
          break;
        case AddEditModeEnum.EDIT:
          this.clientGroupManagementService.updateGroup(portalGroupInfo).subscribe({
            next: this.handleAddEditGroupSuccess.bind(this),
            error: this.handleAddEditGroupFailure.bind(this)
          });
          break;
      }
    } else {
      if (this.ownerEmail.errors && this.ownerEmail.errors['invalidEmail']
        && FormUtils.getFormValidationErrors(this.addGroupFormGroup).length === 1) {
        const errorBannerData = {
          hideX: true,
          outletId: '#addEditGroupBanner',
          headline: 'Invalid Email Address',
          body: 'Provide the following required information in order to proceed with adding a group.',
          bannerLinks: [{
            linkText: 'Valid email address required.',
            linkFunc: () => {
              const formControl = this.el.nativeElement.querySelector('[formcontrolname="' + ClientGroupFormGroup.OWNER_EMAIL + '"]');
              formControl.focus();
            }
          }]
        };
        this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
      } else if (FormUtils.getFormValidationErrors(this.addGroupFormGroup)
          .every(error => !error?.ownerNotFound && !error?.duplicateGroupName)) {
        this.setOwnerEmailValidationErrorMessage();
        const errorBannerData = {
          hideX: true,
          outletId: '#addEditGroupBanner',
          headline: 'Required Information Needed',
          body: 'Provide the following required information in order to proceed with ' +
            (this.mode === AddEditModeEnum.ADD ? 'adding a' : 'editing the') + ' group',
          bannerLinks: this.bannerLinkHelperService.createInvalidFieldFocusLinks(
            this.addGroupFormGroup,
            this.formControlNameToDisplayNameMap,
            this.el)
        };
        this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
      }
    }
  }

  private setOwnerEmailValidationErrorMessage() {
    if (this.ownerEmail.errors) {
      if (this.ownerEmail.errors['invalidEmail']) {
        this.formControlNameToDisplayNameMap.set(ClientGroupFormGroup.OWNER_EMAIL, 'Valid Email Address');
      } else if (this.ownerEmail.errors['required']){
        this.formControlNameToDisplayNameMap.set(ClientGroupFormGroup.OWNER_EMAIL, 'Owner\'s Email Address');
      }
    }
  }

  handleAddEditGroupSuccess(group: PortalGroupInfo) {
    this.showSpinner.next(false);
    this.addGroupFormGroup.markAsPristine();

    const headLineText = this.mode === AddEditModeEnum.ADD ?
      'Client Group Successfully Added' :
      'Client Group Successfully Updated';
    const bodyText = this.mode === AddEditModeEnum.ADD ?
      `The client group ${group.groupName} has been successfully added to myPBM.` :
      `The client group ${group.groupName} has been successfully updated to myPBM.`;
    this.clientGroupManagementService.clientGroupManagementNotification.next({
      headLine: headLineText,
      body: bodyText,
      removedAfterMilliseconds: 5000
    });
    this.router.navigate(['/client-group-management']);
  }

  handleAddEditGroupFailure(error: HttpErrorResponse) {
    this.showSpinner.next(false);
    if (error.status === 409) {
      const errorBannerData = {
        hideX: true,
        outletId: '#addEditGroupBanner',
        headline: 'Client Group Already Exists',
        body: 'A client group with the same name already exists. Please try again or enter a different client group name.',
        bannerLinks: [{
          linkText: 'Client Group Name to be reviewed.',
          linkFunc: () => {
            const formControl = this.el.nativeElement.querySelector('[formcontrolname="' + ClientGroupFormGroup.GROUP_NAME + '"]');
            formControl.focus();
          }
        }]
      };
      this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
      this.setFieldLevelErrors(error);
    } else if (error.status === 404) {
      const errorBannerData = {
        hideX: true,
        outletId: '#addEditGroupBanner',
        headline: 'Owner\'s Email Address Not Found',
        body: 'Provide the following required information in order to proceed with adding a group.',
        bannerLinks: [{
          linkText: 'Owner\'s Email Address not found.',
          linkFunc: () => {
            const formControl = this.el.nativeElement.querySelector('[formcontrolname="' + ClientGroupFormGroup.OWNER_EMAIL + '"]');
            formControl.focus();
          }
        }]
      };
      this.bannerService.showValidationErrorBanner(errorBannerData, CVSBannerType.Error);
      this.setFieldLevelErrors(error);
    }
  }

  private setFieldLevelErrors(error: HttpErrorResponse) {
    if (error.status === 409) {
      this.groupName.setErrors({duplicateGroupName: true});
    }
    if (error.status === 404) {
      this.ownerEmail.setErrors({ownerNotFound: true});
    }
  }

  exitAddEditGroup() {
    this.router.navigate(['/client-group-management']);
  }

  canDeactivate(): boolean {
    return !this.addGroupFormGroup.dirty;
  }

  toggleAllSelection() {
    if (this.selectAllCheckbox.checked) {
      this.lineOfBusiness.patchValue([]);
    } else {
      this.lineOfBusiness.patchValue([...this.lobs.map(item => item.id)]);
    }
    this.selectAllCheckbox.toggle();
  }

  isIndeterminate() {
    return this.lineOfBusiness.value && this.lineOfBusiness.value.length > 0 &&
      this.lineOfBusiness.value.length < this.lobs.length;
  }

  areAllLineOfBusinessesChecked() {
    return this.lineOfBusiness.value && this.lineOfBusiness.value.length > 0 &&
      this.lineOfBusiness.value.length === this.lobs.length;
  }

  toggleOption() {
    if ((this.selectAllCheckbox.checked) && (this.lineOfBusiness.value.length < this.lobs.length)) {
      this.selectAllCheckbox.toggle();
    }
    if ((!this.selectAllCheckbox.checked) && (this.lineOfBusiness.value.length === this.lobs.length)) {
      this.selectAllCheckbox.toggle();
    }
  }

  onSearch(searchValue: string) {
    if (this.clientSearchOption) {
      this.clientManagementService.globalSearchValue = searchValue;
      this.clientManagementService.searchClients(
                this.clientSearchOption.accountLevel,
                this.clientSearchOption.searchParam).subscribe({
        next: this.handleSearchClientSuccess.bind(this),
        error: this.handleSearchClientError.bind(this)
      });
      this.isButtonShown = true;
    }
  }

  onClientSearchValidationError() {
    this.isClientNotFoundErrorShown = false;
  }

  private handleSearchClientSuccess(client) {
    this.isClientNotFoundErrorShown = false;
    const unSelectedClients = this.getUnSelectedClients(client.data);
    unSelectedClients.forEach(client => this.setFormattedClientSearchResult(client));
    this.dataSource.data = unSelectedClients;
    this.onLeftClientDataUpdate();
  }

  private getUnSelectedClients(clients: ClientDTO[]) {
    const map: Record<number, boolean> = {};
    this.selectedDataSource.data.forEach(client => map[client.id] = true);
    return clients.filter(client => !map[client.id]);
  }

  private handleSearchClientError(error) {
    if (error.status === 404) {
      this.isClientNotFoundErrorShown = true;
    }
  }

  private setFormattedClientSearchResult(client: ClientDTO) {
    switch (this.clientGroupType.value) {
      case ClientGroupTypeEnum.SUPER_CLIENT:
        client.formattedSearchResult = `${client.superClientName} (${client.superClientId})`;
        break;
      case ClientGroupTypeEnum.CLIENT_CODE:
        client.formattedSearchResult = `${client.clientCode}`;
        break;
      case ClientGroupTypeEnum.CARRIER:
        client.formattedSearchResult = `${client.carrierName} (${client.carrierId})`;
        break;
      default:
        client.formattedSearchResult = `${client.superClientId}`;
        break;
    }
  }

  onLeftClientDataUpdate() {
    this.addGroupFormGroup.markAsDirty();
    this.setDualPicklistCellsOnClick(this.dataSource.data, 'left');
  }

  onRightClientDataUpdate() {
    this.addGroupFormGroup.markAsDirty();
    this.setDualPicklistCellsOnClick(this.selectedDataSource.data, 'right');
  }

  onUserChipRemove(userChip: PBMUserInfo): void {
    const index =  this.userChipItems.findIndex((c: PBMUserInfo) => c.userPK === userChip.userPK);
    if (index >= 0) {
      this.addGroupFormGroup.markAsDirty();
      this.userChipItems.splice(index, 1);
      this.updateUserOptions(userChip);
    }
  }

  addUserToChip(user: PBMUserInfo): void {
    this.userChipItems.push(user);
    this.userOptions =  this.userOptions.filter(o => o.userPK !== user.userPK);
    this.userOptSubj.next([...this.userOptions]);
  }

  onUserSelect(event: MatAutocompleteSelectedEvent): void {
    const selectedUser = event.option.value;
    if (!!selectedUser) {
      this.addGroupFormGroup.markAsDirty();
      this.addUserToChip(selectedUser);
      this.resetUsersSearchAndChipInput();
    }
  }

  onUserSearchTextClear(event: any) {
    event.preventDefault();
    this.resetUsersSearchAndChipInput();
  }

  onUserRowSelected() {
    if (this.gridApi.getSelectedRows()?.length !== this.editOrCopyGroupData.users?.length) {
      this.addGroupFormGroup.markAsDirty();
    }
  }

  private updateUserOptions(userInfo: PBMUserInfo): void {
    if (this.initialUserOptions.includes(userInfo) && !this.userOptions.includes(userInfo)) {
      this.userOptions.push(userInfo);
      this.userOptSubj.next([...this.userOptions]);
    }
  }

  private resetUsersSearchAndChipInput() {
    this.usersSearchInput.setValue('');
    this.userChipInputRef.nativeElement.value = '';
    this.userChipInputRef.nativeElement.focus();
  }

  private onUserSearchInputChanges(): void {
    this.usersSearchInput.valueChanges
      .pipe( debounceTime(200))
      .subscribe(val => this.filterUsers(val) );
  }

  private onUserTypeChanges(): void {
    this.groupUserAccessTypes.valueChanges
      .pipe( debounceTime(500))
      .subscribe(val => {
        const selectedUserTypes = [];
        const map: Map<string, boolean> = new Map(Object.entries(val));

        map.forEach((value: boolean, key: string) => {
          if(value) { selectedUserTypes.push(key); }
        });

        this.initUserSearchDropDown(selectedUserTypes);
    });
  }

  private filterUsers(searchText: string): void {
    this.userOptSubj.next(this.userOptions.filter(option => {
      return option.basicUserInfo.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
        option.basicUserInfo.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
        option.basicUserInfo.email?.toLowerCase().includes(searchText.toLowerCase());
    }));
  }

  private getSelectedUsers() {
    const selectedUserTypes = this.getSelectedUserAccessTypes();

    const validUsersFromChip = this.userChipItems.filter(user => selectedUserTypes.includes(user.accessType));
    const users = [... validUsersFromChip.map((user) => ( {userPK: user.userPK} as any) )];

    if (this.isOnEditMode() || this.isOnCopyMode()) {
      const validUsersFromGrid = this.gridApi.getSelectedRows().filter(user => selectedUserTypes.includes(user.accessType));
      return users.concat(validUsersFromGrid.map((user) => ( {userPK: user.userPK} as any) ));
    }

    return users;
  }

  private getSelectedUserAccessTypes(): string[] {
    const selectedUserTypes: string[] = [];
    const map: Map<string, boolean> = new Map(Object.entries(this.groupUserAccessTypes.value));
    map.forEach((value: boolean, key: string) => {
      if(value) { selectedUserTypes.push(key); }
    });
    return selectedUserTypes;
  }

  isOnEditMode() {
    return this.mode === AddEditModeEnum.EDIT;
  }

  isOnCopyMode() {
    return this.mode === AddEditModeEnum.ADD && !!this.editOrCopyGroupData;
  }

  isOnAddMode() {
    return this.mode === AddEditModeEnum.ADD && this.editOrCopyGroupData === undefined;
  }

  resetClientDualPicklist(){
    this.dataSource.data = [];
    this.clientSearchOption = null;
    this.isButtonShown = false;
    this.isClientNotFoundErrorShown = false;
    this.clientSearch.searchValue.clearValidators();
  }

  openAtLeastOneClientErrorModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'At least one client is required',
        body:
          '<br>'
          + 'At least one client is required for a client group to be created.'
          + '<br>',
        actionLabel: 'OK',
        noCloseX: false
      },
      panelClass: 'at-least-one-client-modal'
    });
  }

  openClientProfileModal(client: any, clientCode?: any, fromCarrier?: any) {
    this.matDialog.open(ClientProfileModalComponent, {
      data: {
        clientCode,
        fromCarrier,
      },
      panelClass: 'client-profile-modal',
      disableClose: true
    });
  }

  hasAtLeastOneClient() {
    if (this.users.value.length !== 0 && this.clients.value.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  get clientGroupType() {
    return this.addGroupFormGroup.get('clientGroupType');
  }

  get groupUserAccessTypes() {
    return this.addGroupFormGroup.get('clientGroupUserAccessTypes') as UntypedFormGroup;
  }

  get ownerEmail() {
    return this.addGroupFormGroup.get('ownerEmailAddress');
  }

  get groupName() {
    return this.addGroupFormGroup.get('clientGroupName');
  }

  get groupDescription() {
    return this.addGroupFormGroup.get('clientGroupDescription');
  }

  get lineOfBusiness() {
    return this.addGroupFormGroup.get('lineOfBusiness');
  }

  get groupStatus() {
    return this.addGroupFormGroup.get('groupStatus');
  }

  get clients() {
    return this.addGroupFormGroup.get('clients');
  }

  get users() {
    return this.addGroupFormGroup.get('users');
  }

  defaultEnumOrder = (): number => {
    return 0;
  };

  protected readonly UserAccessType = UserAccessType;
}
