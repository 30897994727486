import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {ColumnApi, GridApi, RowNode} from 'ag-grid-community';
import {Router} from '@angular/router';
import {Carrier, ClientCode, SuperClient} from '../../models/ClientHierarchy';
import {PageEvent} from '@angular/material/paginator';
import {carrierId, fromDate, status, thruDate} from '../../models/ClientHierarchyColumns';
import {ClientPaginatorComponent} from '../../ag-grid-utils/client-paginator/client-paginator.component';
import {ClientDataService} from '../../services/client-profile/data/client-data.service';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent {
  @Input() fromModal = false;
  @Input() fromCarrier = false;
  @Input() clientCode: any;
  @ViewChild('clientsPaginator') clientPaginator: ClientPaginatorComponent;

  title = 'Client Profile';
  clientProfile: SuperClient;

  context = {this: this};
  clientGridApi: GridApi;
  carrierGridApi: GridApi;
  carrierColumnApi: ColumnApi;

  defaultCarrierPaginatorSize = 25;

  pageSize = 25;
  totalClientsCount: number;

  clientSearchValue = '';
  clients: ClientCode[];
  clientCodeIdxMap: Map<string, number> = new Map<string, number>();

  carrierSearchValue = '';
  carriers: Carrier[];
  selectedClientCode: string;
  carriersCount: number;
  showCarriers = false;
  isGridReady: boolean;

  fieldsIncludedInSearch: string[] = ['carrierId', 'carrierName', 'clientCode', 'status',
    'isRDCAccount', 'carrierRdcLevel', 'fromDate', 'thruDate'];

  defaultClientsColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    cellStyle: {display: 'block', paddingLeft: 0},
    cellRenderer: (params) => {
      if (params.value) {
        const a = document.createElement('a');
        a.innerText = params.value;

        ['click', 'keydown'].forEach(evt =>
          a.addEventListener(evt, (event: any) => {
            if (event.code === 'Space' || event.type === 'click') {
              this.onClientCodeClicked(params.value);
              event.stopPropagation();
            }
          })
        );
        a.tabIndex = 0;
        return a;
      }
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  defaultCarrierColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset', 'apply'],
      filterOptions: ['contains'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  clientColumnDefs = [
    {
      headerName: 'Client Code',
      field: 'col1',
    },
    {
      headerName: 'Client Code',
      field: 'col2',
    },
    {
      headerName: 'Client Code',
      field: 'col3',
    },
    {
      headerName: 'Client Code',
      field: 'col4',
    },
    {
      headerName: 'Client Code',
      field: 'col5',
    },
  ];

  carrierColumnDefs = [
    carrierId,
    {
      headerName: 'Carrier Name',
      field: 'carrierName',
      minWidth: 180
    },
    {
      headerName: 'Client Code',
      field: 'clientCode',
      valueGetter: (params) => {
        return this.selectedClientCode;
      },
      minWidth: 180
    },
    status,
    {
      headerName: 'RDC Account',
      field: 'isRDCAccount',
      headerTooltip: 'Restricted Data Client Level',
      minWidth: 180,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'RDC Level',
      field: 'carrierRdcLevel',
      headerTooltip: 'Restricted Data Client Level',
      minWidth: 180,
      filter: 'agSetColumnFilter',
    },
    fromDate,
    thruDate
  ];

  constructor(private router: Router,
              private el: ElementRef,
              private cdRef: ChangeDetectorRef,
              private clientDataService: ClientDataService) {
  }

  onClientGridReady(params) {
    this.clientGridApi = params.api;
    this.clientProfile = this.clientDataService.currentClient$.value;
    this.clients = this.clientProfile.clients;
    this.createAndSetClientRowData(this.clients);
    this.initClientCodeIdxMap();
    this.showCarrierGridWithFocus();
  }

  private showCarrierGridWithFocus() {
    if (this.fromCarrier) {
      this.onClientCodeClicked(this.clientCode);
      this.cdRef.detectChanges();
      const carrierGrid = this.el.nativeElement.querySelector('.client-profile-carriers-grid-container');
      if (carrierGrid) {
        carrierGrid.focus();
      }
    }
  }

  onBack() {
    this.router.navigate(['/client-management']);
  }

  onCarrierGridReady(params) {
    this.carrierGridApi = params.api;
    this.carrierColumnApi = params.columnApi;

    this.isGridReady = true;

    const clientCodeIdx = this.clientCodeIdxMap.get(this.selectedClientCode);
    const carrier = this.clients[clientCodeIdx].carriers;

    AgGridHelper.insertRowsPerPageSelector('carrierGrid', 'carrierPaginator');
    this.carrierGridApi.paginationSetPageSize(this.defaultCarrierPaginatorSize);

    this.createAndSetCarrierRowData(carrier);
  }

  createAndSetClientRowData(clients: ClientCode[]) {
    const data = [];
    let counter = 1;
    let clientRow = {};

    const paginatedClients = this.paginateClients(clients);

    paginatedClients?.forEach(client => {
      if (counter === 6) {
        data.push(clientRow);
        clientRow = {};
        counter = 1;
      }

      clientRow[`col${counter}`] = client.clientCode;
      counter++;
    });

    if (counter !== 1) {
      data.push(clientRow);
      clientRow = {};
      counter = 1;
    }

    this.clientGridApi.setRowData(data);
  }

  createAndSetCarrierRowData(carriers: Carrier[]) {
    if (this.isGridReady) {
      this.carrierGridApi.setRowData(carriers);
    }
  }

  private paginateClients(clients: ClientCode[]) {
    this.totalClientsCount = clients.length;

    const start = this.clientPaginator.paginator.pageIndex * this.pageSize;
    const end = start + this.pageSize;

    return clients?.slice(start, end);
  }

  initiateClientSearch() {
    this.clientPaginator.paginator.pageIndex = 0;
    this.filterClients();
  }

  clearClientSearchText() {
    this.clientSearchValue = '';
    this.createAndSetClientRowData(this.clients);
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.clientPaginator.paginator.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.filterClients();
  }

  private filterClients() {
    const filteredClients = this.clients.filter(client => {
      return client.clientCode?.toLowerCase().includes(this.clientSearchValue.toLowerCase());
    });
    this.createAndSetClientRowData(filteredClients);
  }

  private initClientCodeIdxMap() {
    let idx = 0;
    for (const client of this.clients) {
      this.clientCodeIdxMap.set(client.clientCode, idx++);
    }
  }

  onClientCodeClicked(clientCode) {
    if (clientCode) {
      this.selectedClientCode = clientCode;
      this.showCarriers = true;
      const clientCodeIdx = this.clientCodeIdxMap.get(clientCode);
      const carriers = this.clients[clientCodeIdx].carriers;
      this.carriersCount = carriers.length;
      this.createAndSetCarrierRowData(carriers);
    }
  }

  initiateCarrierSearch() {
    this.carrierGridApi.onFilterChanged();
  }

  clearCarrierSearchText() {
    this.carrierSearchValue = '';
    this.carrierGridApi.onFilterChanged();
  }

  doesExternalFilterPass(node: RowNode) {
    return AgGridHelper.doesExternalFilterPass(node,
      this.context.this.carrierSearchValue,
      this.context.this.fieldsIncludedInSearch);
  }

  isExternalFilterPresent() {
    return !!this.context.this.carrierSearchValue;
  }
}
