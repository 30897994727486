import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {privacyModalContent} from '../common/modal-content/privacy-modal-content';
import {agreementModalContent} from '../common/modal-content/agreement-modal-content';
import {LegalModalComponent} from '../common/legal-modal/legal-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private confirmationDialog: MatDialog){}

  showModal(value: string) {
    this.confirmationDialog.open(LegalModalComponent, {
      data: {
        headline: value === 'privacy' ? 'myPBM™ Privacy Policy' : 'myPBM™ Terms and Conditions of Use',
        content: value === 'privacy' ? privacyModalContent : agreementModalContent,
        actionLabel: 'Close',
        source: 'Landing',
        privacy: value === 'privacy'
      },
      panelClass: 'legal-modal'
    });
  }
}
