import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {Direction} from 'angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {LineOfBusinessInfo} from '../../../models/LineOfBusinessInfo';

@Component({
  selector: 'app-client-group-lobs-renderer',
  templateUrl: './client-group-lobs-renderer.component.html',
  styleUrls: ['./client-group-lobs-renderer.component.scss']
})
export class ClientGroupLobsRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  lineOfBusinesses: LineOfBusinessInfo[];

  right = Direction.RIGHT;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setLineOfBusinesses();
  }

  setLineOfBusinesses() {
    this.lineOfBusinesses = this.params.data.lineOfBusinesses
      .sort((lob1: LineOfBusinessInfo, lob2: LineOfBusinessInfo) => lob1.name > lob2.name ? 1 : -1);
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
