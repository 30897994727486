import {Component} from '@angular/core';
import {PBMUserInfo} from '../models/User';
import {CurrentUserService} from '../services/current-user/current-user.service';

@Component({
  selector: 'app-no-access-landing-page',
  templateUrl: './no-access-landing-page.component.html',
  styleUrls: ['./no-access-landing-page.component.scss']
})
export class NoAccessLandingPageComponent {

  firstName: string;
  lastName: string;
  welcomeTitle: string;
  noAccessTitle: string;

  constructor(private currentUserService: CurrentUserService){
    this.currentUserService.currentUser$.subscribe( (user: PBMUserInfo) => {
      if(user){
        this.firstName = user.basicUserInfo.firstName;
        this.lastName = user.basicUserInfo.lastName;
        this.welcomeTitle = `Welcome, ${this.firstName} ${this.lastName}!`;
        this.noAccessTitle = 'Currently, you do not have access to any apps yet.';
      }
    });
  }
}
