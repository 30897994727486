<button class="cvs-icon-only-btn"
        aria-label="Open action menu"
        [matMenuTriggerFor]="menu">
  <mat-icon svgIcon="more-vert-f--s" role="img"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="after" yPosition="below">
  <div>
    <button class="cvs-btn-text-only action-menu-btn" mat-menu-item (click)="navigateToAddEditUserManagement()">
      <mat-icon svgIcon="pencil--s"></mat-icon>
      Edit
    </button>
  </div>
  <div>
    <button class="cvs-btn-text-only action-menu-btn" mat-menu-item (click)="navigateToCopy()" *ngIf="params.data.basicUserInfo?.active && isAllowedToCopyMyPBMAdmin">
      <mat-icon svgIcon="duplicate--s"></mat-icon>
      Copy
    </button>
  </div>
</mat-menu>
