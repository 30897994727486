import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {exactLengthValidator} from '../../validator/validator.utils';
import {ClientSearchType} from '../../enums/client-search-type.enum';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit, OnChanges {
  @Output() searchOutput = new EventEmitter<string>();
  @Output() validationError = new EventEmitter();
  @Output() ngOnChangeFinished = new EventEmitter();
  @Input() clientSearchType: ClientSearchType;
  @Input() buttonClass: string;

  searchValue: FormControl<string>;

  clientSearchLabel: string;
  clientSearchError: string;
  clientSearchTypeLabelMap = new Map<ClientSearchType, any>([
    [ClientSearchType.SUPER_CLIENT_ID, {label: 'Enter exact match'}],
    [ClientSearchType.SUPER_CLIENT_NAME, {label: 'Enter 3 or more characters', error: '3 or more characters required'}],
    [ClientSearchType.SALESFORCE_ACCOUNT_ID, {label: '18 characters required'}],
    [ClientSearchType.SALESFORCE_ACCOUNT_NAME, {label: 'Enter 3 or more characters', error: '3 or more characters required'}],
    [ClientSearchType.CLIENT_CODE, {label: 'Enter exact match'}],
    [ClientSearchType.CLIENT_NAME, {label: 'Enter 3 or more characters', error: '3 or more characters required'}],
    [ClientSearchType.CARRIER_ID, {label: 'Enter exact match'}],
    [ClientSearchType.CARRIER_NAME, {label: 'Enter 3 or more characters', error: '3 or more characters required'}],
    [ClientSearchType.CLIENT_GROUP_NAME, {label: 'Enter at least 3 characters', error: '3 or more characters required'}],
  ]);

  clientSearchTypeValidatorMap = new Map<ClientSearchType, ValidatorFn[]>([
    [ClientSearchType.SUPER_CLIENT_ID, [Validators.required]],
    [ClientSearchType.SUPER_CLIENT_NAME, [Validators.required, Validators.minLength(3)]],
    [ClientSearchType.SALESFORCE_ACCOUNT_ID, [Validators.required, exactLengthValidator(18)]],
    [ClientSearchType.SALESFORCE_ACCOUNT_NAME, [Validators.required, Validators.minLength(3)]],
    [ClientSearchType.CLIENT_CODE, [Validators.required]],
    [ClientSearchType.CLIENT_NAME, [Validators.required, Validators.minLength(3)]],
    [ClientSearchType.CARRIER_ID, [Validators.required]],
    [ClientSearchType.CARRIER_NAME, [Validators.required, Validators.minLength(3)]],
    [ClientSearchType.CLIENT_GROUP_NAME, [Validators.required, Validators.minLength(3)]],
  ]);

  constructor(private el: ElementRef) {
    this.searchValue = new FormControl('');
  }

  ngOnInit(): void {
    this.clientSearchLabel = this.clientSearchTypeLabelMap.get(this.clientSearchType)?.label;
    this.clientSearchError = this.clientSearchTypeLabelMap.get(this.clientSearchType)?.error;
    this.searchValue.setValidators(this.clientSearchTypeValidatorMap.get(this.clientSearchType));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchValue.reset();
    this.clientSearchLabel = this.clientSearchTypeLabelMap.get(this.clientSearchType)?.label;
    this.clientSearchError = this.clientSearchTypeLabelMap.get(this.clientSearchType)?.error;
    this.searchValue.setValidators(this.clientSearchTypeValidatorMap.get(this.clientSearchType));
    this.ngOnChangeFinished.emit();
  }

  clearText() {
    this.searchValue.reset();
    this.focusSearchBar();
  }

  private focusSearchBar() {
    const searchBar = this.el.nativeElement.querySelector('.search-bar input');
    if (searchBar) {
      searchBar.focus();
    }
  }

  initiateClientSearch(event) {
    event?.preventDefault();
    this.searchValue.markAsTouched();

    if (this.searchValue.valid) {
      this.searchOutput.emit(this.searchValue.value);
    } else {
      this.validationError.emit();
    }
  }

}
