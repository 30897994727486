import {Component, Input} from '@angular/core';
import {ColDef, ColumnApi, GridApi, RowSelectedEvent} from 'ag-grid-community';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {
  GeneralAccessPermissionTypeRendererComponent
} from '../../ag-grid-utils/cell-renderers/general-access-permission-type-renderer/general-access-permission-type-renderer.component';
import {AddUserFormGroup} from '../../enums/add-user-form.enum';

@Component({
  selector: 'app-user-general-access-grid',
  templateUrl: './user-general-access-grid.component.html',
  styleUrls: ['./user-general-access-grid.component.scss']
})
export class UserGeneralAccessGridComponent {
  @Input() addUserFormControl: any;
  @Input() formSubmitted: boolean;

  gridApi: GridApi;
  columnApi: ColumnApi;
  renderInstances;
  context = {this: this};

  rowSelected(event: RowSelectedEvent) {
    this.renderInstances = this.gridApi.getCellRendererInstances({columns: ['permissionType']});
    this.addUserFormControl[AddUserFormGroup.GENERAL_ACCESS].markAsDirty();
    if (event.node.isSelected()) {
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.selected.setValue(true);
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.selected.updateValueAndValidity();
    } else {
      this.renderInstances[event.node.rowIndex].generalAccessPermissionType = '';
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.selected.setValue(false);
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.permissionType.setValue('');
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.permissionCode.setValue('');
      this.addUserFormControl.generalAccess.controls[event.node.rowIndex].controls.selected.updateValueAndValidity();
    }
  }

  columnDefs: ColDef[] = [
    {
      headerName: 'Access Name',
      headerClass: 'access-name-header',
      field: 'accessName',
      checkboxSelection: true
    },
    {
      headerName: 'Permission Type',
      field: 'permissionType',
      cellRenderer: GeneralAccessPermissionTypeRendererComponent
    }
  ];

  defaultColDef: ColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: false,
    filter: false,
    suppressMenu: true,
    lockVisible: true,
    lockPosition: true,
    resizable: false,
    suppressKeyboardEvent: (params) => {
      return (params.event.key === 'Tab') || (params.event.key === 'Tab' && params.event.shiftKey)
        || (params.event.key === 'ArrowRight') || (params.event.key === 'ArrowLeft');
    },
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setRowData(
      [
        {accessName: 'CVS Caremark Standard Template', permissionType: '', permissionCodeSuffix: '_CVS_STANDARD_TEMPLATE'},
        {accessName: 'CVS Caremark Standard Template with Commercial Line of Business',
          permissionType: '', permissionCodeSuffix: '_CVS_STANDARD_TEMPLATE_COMMERCIAL_LOB'}
      ]
    );
  }
}
