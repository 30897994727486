<div class="dialog-wrapper">
  <div mat-dialog-title class="dialog-header">
    <h1 id="headlineValue">{{groupName}}</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <h2 id="group-description">{{groupDescription}}</h2>
  <mat-dialog-content class="dialog-main">

    <div class="client-profile-clients-grid-container">
      <div id="info-text">Below are the clients associated with this group.</div>

      <div class="search-bar-container">
        <div class="search-bar">
          <mat-form-field appearance="legacy">
            <input matInput placeholder="Search" (keydown.enter)="initiateClientSearch()"
                   [(ngModel)]="clientSearchValue"/>
          </mat-form-field>
          <mat-icon svgIcon="search--s" *ngIf="!clientSearchValue"></mat-icon>
          <mat-icon class="client-close"
                    svgIcon="close-btn--s"
                    [tabIndex]="0"
                    *ngIf="clientSearchValue"
                    (click)="clearClientSearchText()"
                    (keydown.enter)="clearClientSearchText()"
                    aria-label="Clear Search">
          </mat-icon>
        </div>
      </div>

      <ag-grid-angular
        [columnDefs]="clientColumnDefs"
        [defaultColDef]="defaultClientsColDef"
        (gridReady)="onGridReady($event)"
        [rowHeight]="32"
        [context]="context"
        [suppressMenuHide]="true"
        [suppressContextMenu]="true"
        [suppressCellFocus]="true"
        [headerHeight]="0"
        tabIndex="-1"
        class="ag-theme-alpine client-profile-clients-grid">
      </ag-grid-angular>

      <app-client-paginator
        #clientsPaginator
        [totalRowCount]="totalClientsCount"
        [pageSize]="pageSize"
        [pageSizeOptions]="[25, 50, 100]"
        (page)="handlePageEvent($event)">
      </app-client-paginator>
    </div>
  </mat-dialog-content>
</div>
