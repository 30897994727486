<div class="back-button-container">
  <button class="cvs-btn-text-only back-button"
          (click)="exitAddEditRole()">
    <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
    Back
  </button>
</div>
<div id="addEditRoleBanner"></div>
<div *ngIf="selectedApp">
  <h1 class="header-padding">{{ selectedApp.appName }}</h1>
</div>
<div class="content-card">
  <h2 class="content-card-add-edit-header" *ngIf="mode === roleModeEnum.ADD">Add Role</h2>
  <h2 class="content-card-add-edit-header" *ngIf="mode === roleModeEnum.EDIT">Edit Role</h2>

  <form [formGroup]="addEditRoleForm" id="role-form" (ngSubmit)=onSubmitForm()
        (keydown.enter)="$event.preventDefault()">
    <div class="access-type-container">
      <h3 class="header-padding">User Access Type</h3>
      <ng-container formGroupName="roleAccessTypes">
        <mat-checkbox [formControlName]="enum.value" class="accessType-{{enum.key}}"
                      [ngClass]="{'access-type-error' : shouldStyleErrorForRoleAccessType()}"
                      *ngFor="let enum of UserAccessType | keyvalue: defaultEnumOrder">
          {{ enum.value }}
        </mat-checkbox>
      </ng-container>
    </div>
    <div class="role-name-container">
      <mat-form-field>
        <mat-label>Role Name</mat-label>
        <input matInput
               maxlength="255"
               formControlName="roleName"
               [readonly]="mode === roleModeEnum.EDIT">
        <mat-error class="add-edit-error" data-cy="role-name-required-error"
                   *ngIf="addEditRoleForm.controls['roleName'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Role Name required.
        </mat-error>
        <mat-error class="add-edit-error" data-cy="role-name-character-error"
                   *ngIf="addEditRoleForm.controls['roleName'].errors?.pattern">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          May contain spaces, A-Z , a-z , 0-9 , - , or _
        </mat-error>
        <mat-error class="add-edit-error" data-cy="role-name-duplication-error"
                   *ngIf="addEditRoleForm.controls['roleName'].errors?.duplicate">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Role Name already exists.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="role-id-container" *ngIf="mode === roleModeEnum.EDIT">
      <mat-form-field>
        <mat-label>Role ID</mat-label>
        <input matInput
               maxlength="255"
               formControlName="roleId"
               [readonly]="mode === roleModeEnum.EDIT">
        <mat-error class="add-edit-error" data-cy="role-id-character-error"
                   *ngIf="addEditRoleForm.controls['roleId'].errors?.pattern">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          May contain A-Z , a-z , 0-9 , - , or _
        </mat-error>
      </mat-form-field>
    </div>
    <div class="role-description-container">
      <mat-form-field appearance="outline">
        <mat-label>Role Description</mat-label>
        <textarea id="roleDescriptionTextArea"
                  matInput
                  maxlength="255"
                  formControlName="roleDescription">
          </textarea>
        <mat-error class="add-edit-error" data-cy="role-description-required-error"
                   *ngIf="addEditRoleForm.controls['roleDescription'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Role Description required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="role-status-container" *ngIf="mode === roleModeEnum.EDIT">
      <mat-label class="radio-label">Status</mat-label>
      <mat-radio-group formControlName="roleStatus" ngDefaultControl>
        <mat-radio-button class="radio-buttons" *ngFor="let option of statusOption; first as isPending"
                          [value]="option" [disabled]="isPending"
        >{{ option }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <h2>Add Excluded Roles</h2>
    <div class="excluded-role-code-container">
      <cvs-chip-input id="cvs-chip"
                      ngDefaultControl
                      formArrayName="excludedRoles"
                      (onChipAdd)="onChipAdded($event)"
                      (onChipRemove)="onChipRemoved()"
                      [label]="'Excluded Roles'"
                      placeholder="Role Codes"
                      [initialDataOptions]="appRoleCodes"
                      [initialItems]="excludedRoleChips"
      >
      </cvs-chip-input>
      <mat-error class="add-edit-error" data-cy="excluded-role-code-error"
                 *ngIf="addEditRoleForm.controls['excludedRoles'].errors?.excluded">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        Invalid Role exclusion
      </mat-error>
      <mat-error class="add-edit-error" data-cy="excluded-role-code-error"
                 *ngIf="addEditRoleForm.controls['excludedRoles'].errors?.duplicateExclusion">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        Duplicated Role exclusion
      </mat-error>
      <mat-error class="add-edit-error" data-cy="excluded-role-code-error"
                 *ngIf="addEditRoleForm.controls['excludedRoles'].errors?.selfRoleExclusion">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        Self Role exclusion
      </mat-error>
    </div>

    <h2 class="content-card-add-edit-header">Add Permission(s) to Role</h2>

    <mat-tab-group class="pds-primary-tab" animationDuration="0ms" disableRipple color="primary"
                   (selectedTabChange)="onTabChanged($event)">

      <mat-tab class="tab-label" label="Available Permissions">
      </mat-tab>

      <mat-tab class="tab-label" label="Selected Permissions">
      </mat-tab>

    </mat-tab-group>
    <div class="header-container">
      <app-search #searchCapabilitiesComponent [gridApi]="gridApi"></app-search>
      <div class="roles-button-container">
        <app-expand-collapse-buttons #expandCollapseButtonsComponent
                                     [gridApi]="gridApi"></app-expand-collapse-buttons>
      </div>
    </div>
    <ag-grid-angular
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      [rowHeight]="32"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [groupSelectsChildren]="true"
      [context]="context"
      [rowSelection]="'multiple'"
      [rowMultiSelectWithClick]="true"
      [keepDetailRows]="true"
      [rowBuffer]="100"
      [isRowSelectable]="isRowSelectable"
      [isExternalFilterPresent]="isExternalFilterPresent"
      (rowGroupOpened)="updateAllRowsExpandedState()"
      [doesExternalFilterPass]="doesExternalFilterPass"
      [getRowId]="getRowId"
      [suppressRowClickSelection]="true"
      (cellClicked)="toggleSelection($event)"
      class="ag-theme-alpine permissions-grid">
    </ag-grid-angular>
    <button *ngIf="mode === roleModeEnum.ADD" class="cvs-btn-primary" type="submit">
      Add Role
    </button>
    <button *ngIf="mode === roleModeEnum.EDIT" class="cvs-btn-primary" type="submit">
      Save Changes
    </button>
    <button class="cvs-btn-ghost" type="button" (click)="exitAddEditRole()">
      Cancel
    </button>
  </form>
</div>
