import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpHeaderService} from '../http-header/http-header.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {PortalGroupInfo} from '../../models/PortalGroupInfo';
import {BannerData} from '../../models/BannerData';
import {ServerSideUtils} from '../../utils/server-side/serverSideUtils';

@Injectable({
  providedIn: 'root'
})
export class ClientGroupManagementService {

  private clientGroupBaseUrl = environment.portApiBaseUrl + '/api/v1/group';

  globalSearchValue = '';

  clientGroupManagementNotification: BehaviorSubject<BannerData> = new BehaviorSubject<BannerData>(null);
  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) { }

  getAllGroups(): Observable<PortalGroupInfo[]> {
    return this.httpClient.get<PortalGroupInfo[]>(this.clientGroupBaseUrl, this.httpHeaderService.buildSimpleHeader());
  }

  createGroup(portalGroupInfo: PortalGroupInfo): Observable<PortalGroupInfo> {
    return this.httpClient
      .post<PortalGroupInfo>(this.clientGroupBaseUrl, portalGroupInfo, this.httpHeaderService.buildSimpleHeader());
  }

  updateGroup(portalGroupInfo: PortalGroupInfo): Observable<PortalGroupInfo> {
    return this.httpClient
      .put<PortalGroupInfo>(this.clientGroupBaseUrl, portalGroupInfo, this.httpHeaderService.buildSimpleHeader());
  }

  deleteGroup(groupId: number): Observable<PortalGroupInfo> {
    return this.httpClient.delete<PortalGroupInfo>(this.clientGroupBaseUrl + '/' + groupId, this.httpHeaderService.buildSimpleHeader());
  }
  searchGroup(userType: string, searchParam: string,
              starRow: number = 0, endRow: number = 50, filterModel: any = []): Observable<PortalGroupInfo[]> {
    const queryParamObject = this.getQueryParamObject(userType, searchParam, starRow, endRow, filterModel);
    return this.httpClient.get<PortalGroupInfo[]>
    (this.clientGroupBaseUrl + '/search' , this.httpHeaderService.buildSimpleHeader(queryParamObject));
  }

  private getQueryParamObject(userType: string, searchParam: string, starRow: number = 0, endRow: number = 50, filterModel: any = []) {
    const queryParamMap = new Map<string, any>();
    queryParamMap.set('groupStatus', 'active');
    queryParamMap.set('accessTypes', userType); // get this from the user
    ServerSideUtils.addPaginationQueryParams(starRow, endRow, queryParamMap);
    ServerSideUtils.addFilterQueryParams(filterModel, queryParamMap);

    if (queryParamMap.has(searchParam)) {
      queryParamMap.set(
        searchParam, [queryParamMap.get(searchParam), this.getGlobalSearchParamFilterType(searchParam) + this.globalSearchValue]);
    } else {
      queryParamMap.set(searchParam, this.getGlobalSearchParamFilterType(searchParam) + this.globalSearchValue);
    }

    return Object.fromEntries(queryParamMap);
  }

  private getGlobalSearchParamFilterType(searchParam: string) {
    let filterType = '';
    switch (searchParam) {
      case 'superClientId':
      case 'carrierId':
      case 'clientCode':
      case 'accountId':
        filterType = 'equals,';
        break;

      default:
        filterType = 'contains,';
    }
    return filterType;
  }
}
