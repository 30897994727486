<cvs-loader-overlay-container [isLoading]="isLoading"
                              [withModal]="false"
                              [darkOverlay]="true"
                              [opacity]=".3"
                              [loadingHeader]="'Please Wait'"
                              loadingMessage="Loading Content...">
  <div class="loader">
<div class="users">
  <div id="userActionBanner"></div>
  <ng-template #customBannerContentTemplate>
    <cvs-banner [data]="bannerData">
      <h2 *ngIf="actionMessage">{{ actionMessage }}</h2>
      <p *ngIf="actionSubMessage">{{ actionSubMessage }}&trade;.</p>
    </cvs-banner>
  </ng-template>
  <div class="users__title header-padding">
    <h1>{{ title }}</h1>
  </div>

  <div class="card">

    <mat-tab-group class="pds-primary-tab" animationDuration="0ms" disableRipple color="primary"
                   (selectedTabChange)="onTabChanged($event)">
      <mat-tab class="tab-label" *ngFor="let tab of userTabs; let index = index">
        <ng-template mat-tab-label>
          <label>{{ tab.label }}</label>
          <mat-basic-chip class="pds-chip--small">
            {{ tab.contentSize }}
          </mat-basic-chip>
        </ng-template>
        <div class="grid-header" [ngClass]="{'pds-table__selected-row': selectionNormal.selected.length > 0 }">
          <h2 role="heading">{{ tab.label }}</h2>
        </div>
        <div class="help-text">Select a user to view or edit information.
          <span>
            <button class="pds-btn-ghost user-actions-btn" [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="angle-down--s" class="pds-transition-base pds-icon-rotation"></mat-icon>
              Actions
            </button>
              <mat-menu #menu="matMenu">
                <button class="menuItems" mat-menu-item (click)="downloadAccessRequestForm()">
                    <mat-icon svgIcon="download-arrow--s"></mat-icon>
                      Download Access Request Form
                </button>
                <button (click)="openUploadConfirmationModal()" class="menuItems" mat-menu-item>
                    <mat-icon svgIcon="upload-arrow--s"></mat-icon>
                      Upload Access Request Form
                </button>
              </mat-menu>
              <input id="upload-file-input"
                     style="display: none"
                     type="file" accept=".xlsx"
                     (change)="onFileSelected($event)">
            <button (keydown.tab)="focusFirstCell($event, gridApi, columnApi)" class="cvs-btn-primary user-add-btn"
              (click)="navigateToAdd()">
                <mat-icon svgIcon="user-add--s"></mat-icon>
              Add User
            </button>
          </span>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ag-grid-angular
      [columnDefs]="columnDefs"
      [modules]="modules"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      [suppressMenuHide]="true"
      [rowHeight]="32"
      (cellClicked)="navigateToEdit($event, ['App Access','User Actions'])"
      (cellKeyPress)="navigateOnEnterOrSpace($event)"
      [context]="context"
      [pagination]=true
      [rowModelType]="rowModelType"
      [components]="frameworkComponents"
      [serverSideStoreType]="serverSideStoreType"
      style="width: 100%; height: 67vh;"
      class="ag-theme-alpine">
    </ag-grid-angular>
    <app-paginator-rows-per-page [defaultPaginatorSize]="50"
                                 [pageSizeOptions]="[50, 100, 150]"
                                 [gridApi]="gridApi"
                                 [columnApi]="columnApi">
    </app-paginator-rows-per-page>
  </div>
</div>
  </div>
</cvs-loader-overlay-container>
