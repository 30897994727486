import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SuperClient} from '../../../models/ClientHierarchy';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  currentClient$: BehaviorSubject<SuperClient>;

  constructor() {
    this.currentClient$ = new BehaviorSubject<SuperClient>(null);
  }

  setCurrentClient(clientInfo: SuperClient) {
    this.currentClient$.next(clientInfo);
  }
}
