import {ElementRef, Injectable} from '@angular/core';
import {BannerLink} from 'angular-component-library';
import {UntypedFormGroup} from '@angular/forms';
import {AddPermissionFormGroup, AddRoleFormGroup, ClientGroupFormGroup} from '../../enums/add-edit-form.enum';

@Injectable({
  providedIn: 'root'
})
export class BannerLinkHelperService {

  focusOnElement(formControlKey: string, el: ElementRef) {
    const formControl = el.nativeElement.querySelector('[formcontrolname="' + formControlKey + '"]');
    const matChipInput = el.nativeElement.querySelector('.mat-chip-input');
    if (formControl) {
      formControl.focus();
    } else if (matChipInput) {
      matChipInput.focus();
    }
  }

  focusOnAccessType(el: ElementRef, querySelectorString: string) {
    const formElement = el.nativeElement.querySelector(querySelectorString);
    if (formElement) {
      formElement.focus();
    }
  }

  focusOnToggleAppElement(index: number, el: ElementRef) {
    const formControl = el.nativeElement.querySelector('.app-toggle-group-' + index);
    if (formControl) {
      formControl.focus();
    }
  }

  openDropdown(formControlKey: string, el: ElementRef) {
    const formControl = el.nativeElement.querySelector('[formcontrolname="' + formControlKey + '"]');
    if (formControl) {
      formControl.click();
    }
  }

  scrollToElement(el: ElementRef, querySelectorString: string) {
    const formElement = el.nativeElement.querySelector(querySelectorString);
    if (formElement) {
      formElement.scrollIntoView();
    }
  }

  createInvalidFieldFocusLinks(formGroup:  UntypedFormGroup,
                               formControlNameToDisplayNameMap: Map<string, string>,
                               el: ElementRef) {
    const linkFuncs: BannerLink[] = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (formGroup.controls[name].errors && formGroup.controls[name].errors.message) {
        if(name === AddRoleFormGroup.ROLE_ACCESS_TYPES || name === AddPermissionFormGroup.PERMISSION_ACCESS_TYPES ||
            name === ClientGroupFormGroup.GROUP_USER_ACCESS_TYPE){
          linkFuncs.push({
            linkText: formControlNameToDisplayNameMap.get(name) + formGroup.controls[name].errors.message,
            linkFunc: () => this.focusOnAccessType(el, '.accessType-CVS_HEALTH_COLLEAGUE .mat-checkbox-input')
          });
        } else {
          linkFuncs.push({
            linkText: formControlNameToDisplayNameMap.get(name) + formGroup.controls[name].errors.message,
            linkFunc: () => this.focusOnElement(name, el)
          });
        }
      }
    }
    return linkFuncs;
  }

}
