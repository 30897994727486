import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ClientDTO} from '../../../models/ClientDTO';
import {PortalGroupInfo} from '../../../models/PortalGroupInfo';
import {ClientHierarchyEnum} from '../../../enums/client-hierarchy-enum';
import {ClientProfileModalService} from '../../../services/client-profile/modal/client-profile-modal.service';
import {AgGridHelper} from '../../../ag-grid-utils/helpers/AgGridHelper';
import {ColDef, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {StatusCodes} from '../../../enums/add-edit-form.enum';
import {PageEvent} from '@angular/material/paginator';
import {ClientPaginatorComponent} from '../../../ag-grid-utils/client-paginator/client-paginator.component';
import {SearchComponent} from '../../../ag-grid-utils/search/search.component';

@Component({
  selector: 'app-client-group-detail',
  templateUrl: './client-group-detail.component.html',
  styleUrls: ['./client-group-detail.component.scss']
})
export class ClientGroupDetailComponent implements OnChanges, AfterViewInit {
  protected readonly ClientHierarchyEnum = ClientHierarchyEnum;

  @Input() selectedGroup: PortalGroupInfo;
  @Output() closeClientDetails: EventEmitter<any> = new EventEmitter();

  @ViewChild('clientsPaginator') clientPaginator: ClientPaginatorComponent;
  @ViewChild('clientAppSearch') clientAppSearch: SearchComponent;

  clients: ClientDTO[];
  groupType: string;

  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;
  isRowsPerPageSelectorInserted = false;

  filteredClients: ClientDTO[];
  clientSearchValue = '';

  pageSize = 25;
  totalClientsCount: number;
  defaultPaginatorSize = 50;

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['contains'],
      defaultOption: 'contains',
      closeOnApply: false,
    },

    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),

  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Email Address',
      field: 'basicUserInfo.email',
      minWidth: 250
    },
    {
      headerName: 'First Name',
      field: 'basicUserInfo.firstName',
      minWidth: 150,
    },
    {
      headerName: 'Last Name',
      field: 'basicUserInfo.lastName',
      minWidth: 150,
    },
    {
      headerName: 'Status',
      field: 'basicUserInfo.active',
      minWidth: 250,
      valueGetter: (params) => {
        return params.data.basicUserInfo.active ? 'Active' : 'Inactive';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [StatusCodes.ACTIVE, StatusCodes.INACTIVE],
      },
    },
    {
      headerName: 'Location',
      field: 'onshore',
      minWidth: 200,
      valueGetter: (params) => {
        return params.data.onshore ? 'Onshore' : 'Offshore';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Onshore','Offshore'],
      },
    }
  ];

  constructor(
    private clientProfileModalService: ClientProfileModalService,
    private cdRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedGroup) {
      if (!changes.selectedGroup.firstChange) {
        this.resetPaginator();
        this.clientAppSearch.clearText();
        this.paginateAndSetClients(this.selectedGroup.clients);
        this.gridApi.setRowData(this.selectedGroup.users);
      }
      this.groupType = this.selectedGroup.clients.length > 0 ? this.selectedGroup.clients[0].accountLevel : '';
    }
  }

  ngAfterViewInit() {
    this.paginateAndSetClients(this.selectedGroup.clients);
    this.cdRef.detectChanges();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.gridApi.setRowData(this.selectedGroup.users);
  }

  onTabChange(event){
    if(this.gridApi && event.tab.textLabel === 'Users' && !this.isRowsPerPageSelectorInserted){
      AgGridHelper.insertRowsPerPageSelector('user-grid','user-paginator');
      this.isRowsPerPageSelectorInserted = true;
    }
  }

  hideGroupDetail() {
    this.closeClientDetails.emit();
  }

  paginateAndSetClients(clientToBePaginated: ClientDTO[]) {
    this.totalClientsCount = clientToBePaginated.length;

    const start = this.clientPaginator.paginator.pageIndex * this.pageSize;
    const end = start + this.pageSize;

    this.clients = clientToBePaginated?.slice(start, end);
  }

  getClientLabel(client: ClientDTO): string {
    let label = '';
    switch (client?.accountLevel) {
      case ClientHierarchyEnum.SUPER_CLIENT:
        label = client.superClientName;
        break;

      case ClientHierarchyEnum.CLIENT_CODE:
        label = client.clientCode;
        break;

      case ClientHierarchyEnum.CARRIER:
        label = client.carrierId;
        break;

      default:
        label = client.superClientName;
        break;
    }
    return label;
  }

  onClientSearch(searchValue: string) {
    this.resetPaginator();

    this.clientSearchValue = searchValue;

    this.filteredClients = this.selectedGroup.clients
      .filter(client => this.getClientLabel(client)?.toLowerCase().includes(searchValue?.toLowerCase()));

    this.paginateAndSetClients(this.filteredClients);
  }

  openClientProfileModal(client: any, isFromCarrier?: boolean) {
    this.clientProfileModalService.openClientProfileModal(client, isFromCarrier);
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.clientPaginator.paginator.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;

    if (this.clientSearchValue.length > 0) {
      this.paginateAndSetClients(this.filteredClients);
    } else {
      this.paginateAndSetClients(this.selectedGroup.clients);
    }
  }

  private resetPaginator() {
    if (this.clientPaginator) {
      this.clientPaginator.paginator.pageIndex = 0;
    }
  }

}
