<div class="back-button-container">
  <button class="cvs-btn-text-only back-button"
          (click)="exitAddEditPermission()">
    <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
    Back
  </button>
</div>
<div id="addEditPermissionBanner"></div>
<div *ngIf="selectedApp">
  <h1 class="header-padding">{{selectedApp.appName}}</h1>
</div>
<div class="content-card">
  <h2 class="content-card-add-edit-header" *ngIf="mode === permissionModeEnum.ADD">Add Permission</h2>
  <h2 class="content-card-add-edit-header" *ngIf="mode === permissionModeEnum.EDIT">Edit Permission</h2>

  <form [formGroup]="addEditPermissionForm" id="capabilities-form" (ngSubmit)=onSubmitForm()
        (keydown.enter)="$event.preventDefault()">
    <div class="access-type-container">
      <h3 class="header-padding">User Access Type</h3>
      <ng-container formGroupName="permissionAccessTypes">
        <mat-checkbox [formControlName]="enum.value" class="accessType-{{enum.key}}"
                      [ngClass]="{'access-type-error' : shouldStyleErrorForPermissionAccessType()}"
                      *ngFor="let enum of UserAccessType | keyvalue: defaultEnumOrder">
          {{enum.value}}
        </mat-checkbox>
      </ng-container>
    </div>
    <div class="capabilities-container">

      <mat-form-field class="autocomplete-select" appearance="legacy">
        <mat-label>App Capability</mat-label>
        <input type="text"
               maxlength="255"
               aria-label="App Capability"
               matInput
               formControlName="capabilityName"
               aria-describedby="tool-tip"
               [matAutocomplete]="auto">
        <mat-hint align="start">Permission Grouping</mat-hint>
        <mat-error class="add-edit-error" data-cy="capability-name-required-error"
                   *ngIf="addEditPermissionForm.controls['capabilityName'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          App Capability required.
        </mat-error>
        <mat-error class="add-edit-error" data-cy="capability-name-character-error"
                   *ngIf="addEditPermissionForm.controls['capabilityName'].errors?.pattern">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          May contain spaces, A-Z , a-z , 0-9 , - , or _
        </mat-error>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of existingCapabilities$ | async"
                      [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-button
              id="tool-tip"
              role="tooltip"
              type="button"
              class="cvs-icon-only-btn"
              [cvsTooltip]="'App Capability represents the title of a group of permissions.'"
              [tooltip-class]="'text-nowrap'"
              placement="right"
              [showArrow]="true"
              aria-label="App Capability represents the title of a group of permissions.">
        <mat-icon svgIcon="info-circle--s" role="img" aria-describedby="tool-tip"></mat-icon>
      </button>
    </div>

    <div class="permissions-container">
      <mat-form-field>
        <mat-label>Permission Name</mat-label>
        <input matInput
               maxlength="255"
               formControlName="permissionName"
               [readonly]="mode === permissionModeEnum.EDIT">
        <mat-error class="add-edit-error" data-cy="permission-name-required-error"
                   *ngIf="addEditPermissionForm.controls['permissionName'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Permission Name required.
        </mat-error>
        <mat-error class="add-edit-error" data-cy="permission-name-character-error"
                   *ngIf="addEditPermissionForm.controls['permissionName'].errors?.pattern">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          May contain spaces, A-Z , a-z , 0-9 , - , or _
        </mat-error>
        <mat-error class="add-edit-error" data-cy="permission-name-duplication-error"
                   *ngIf="addEditPermissionForm.controls['permissionName'].errors?.duplicate">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Permission Name already exists.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="permissions-container">
      <mat-form-field>
        <mat-label>Permission Type</mat-label>
        <mat-select formControlName="permissionType">
          <mat-option *ngFor="let permissionType of permissionTypeOption;" [value]="permissionType">
            {{permissionType}}
          </mat-option>
        </mat-select>
        <mat-error class="add-edit-error" data-cy="permission-type-required-error"
                   *ngIf="addEditPermissionForm.controls['permissionType'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Permission Type required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="permissions-container" *ngIf="mode === permissionModeEnum.EDIT">
      <mat-form-field>
        <mat-label>Permission ID</mat-label>
        <input matInput
               maxlength="255"
               formControlName="permissionId"
               [readonly]="mode === permissionModeEnum.EDIT">
        <mat-error class="add-edit-error" data-cy="permission-id-character-error"
                   *ngIf="addEditPermissionForm.controls['permissionId'].errors?.pattern">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          May contain A-Z , a-z , 0-9 , - , or _
        </mat-error>
      </mat-form-field>
    </div>

    <div class="permissions-description-container">
      <mat-form-field appearance="outline">
        <mat-label>Permission Description</mat-label>
        <textarea id="permissionDescriptionTextArea"
                  matInput
                  maxlength="255"
                  formControlName="permissionDescription">
          </textarea>
        <mat-error class="add-edit-error" data-cy="permission-description-required-error"
                   *ngIf="addEditPermissionForm.controls['permissionDescription'].errors?.required">
          <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
          Permission Description required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="permissions-status-container" *ngIf="mode === permissionModeEnum.EDIT">
      <mat-label class="radio-label">Status</mat-label>
      <mat-radio-group formControlName="permissionStatus" ngDefaultControl>
        <mat-radio-button class="radio-buttons" *ngFor="let option of statusOption; first as isPending" [value]="option"
                          [disabled]="isPending"
        >{{option}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="button-container">
      <button *ngIf="mode === permissionModeEnum.ADD" class="cvs-btn-primary" type="submit">
        Add Permission
      </button>

      <button *ngIf="mode === permissionModeEnum.EDIT" class="cvs-btn-primary" type="submit">
        Save Changes
      </button>

      <button class="cvs-btn-ghost" (click)="exitAddEditPermission()" type="button">
        Cancel
      </button>
    </div>

  </form>
</div>
