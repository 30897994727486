import {Injectable} from '@angular/core';
import {PBMAppInfo, PBMRoleInfo} from '../../../models/AppInfo';
import {BehaviorSubject} from 'rxjs';
import {PBMPermissionInfo} from '../../../models/PBMPermissionInfo';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  currentApp$: BehaviorSubject<PBMAppInfo>;
  selectedTabIndex: number;

  constructor() {
    this.currentApp$ = new BehaviorSubject<PBMAppInfo>(null);
    this.selectedTabIndex = 0;
  }

  setCurrentApp(newApp: PBMAppInfo) {
    this.currentApp$.next(newApp);
  }

  removePermissionFromCurrentApp(permissionId: number) {
    const app: PBMAppInfo = this.currentApp$.value;
    app.permissions = app.permissions.filter(permission => permission.id !== permissionId);

    for (const role of app.roles) {
      role.permissions = role.permissions.filter(permission => permission.id !== permissionId);
    }

    this.setCurrentApp(app);
  }

  updatePermissionOnCurrentApp(updatedPermission: PBMPermissionInfo) {
    const app: PBMAppInfo = this.currentApp$.value;
    const appPermission = app.permissions.findIndex(permission => permission.id === updatedPermission.id);

    app.permissions[appPermission] = updatedPermission;

    for (const role of app.roles) {
      const rolePermission = role.permissions.findIndex(permission => permission.id === updatedPermission.id);

      if (rolePermission !== -1) {
       role.permissions[rolePermission] = updatedPermission;
      }
    }

    this.setCurrentApp(app);
  }

  updateRoleOnCurrentApp(updatedRole: PBMRoleInfo) {
    const app: PBMAppInfo = this.currentApp$.value;
    const roleIdx = app.roles.findIndex(role => role.id === updatedRole.id);

    app.roles[roleIdx] = updatedRole;

    this.setCurrentApp(app);
  }

  removeRolesFromCurrentApp(roleIds: number[]) {
    const app: PBMAppInfo = this.currentApp$.value;
    app.roles = app.roles.filter(role => !roleIds.includes(role.id));
    this.setCurrentApp(app);
  }
}
