<ag-grid-angular class="ag-theme-alpine user-app-access-grid"
                 (gridReady)="onGridReady($event)"
                 [columnDefs]="colDefs"
                 [context]="context"
                 [rowHeight]="32"
                 [autoGroupColumnDef]="autoGroupColumnDef"
                 [suppressRowClickSelection]="true"
                 [rowSelection]="'multiple'"
                 [defaultColDef]="defaultColDef"
                 [isRowSelectable]="isRowSelectable"
                 [rowClassRules]="rowClassRules"
                 [getDataPath]="agGridHelper.getRoleDataPath"
                 [components]="frameworkComponents"
                 (rowSelected)="rowSelected()"
                 [treeData]="true">
</ag-grid-angular>