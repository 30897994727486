import {Component, Input} from '@angular/core';
import {ColumnApi, GridApi} from 'ag-grid-community';
import {AgGridHelper} from '../helpers/AgGridHelper';

@Component({
  selector: 'app-paginator-rows-per-page',
  templateUrl: './paginator-rows-per-page.component.html',
  styleUrls: ['./paginator-rows-per-page.component.scss']
})
export class PaginatorRowsPerPageComponent {
  @Input() pageSizeOptions: number[];
  @Input() gridApi: GridApi;
  @Input() columnApi: ColumnApi;
  @Input() defaultPaginatorSize: any;

  focusFirstCell = AgGridHelper.focusFirstCell;


  onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(event);
    this.gridApi.setCacheBlockSize(event);
  }
}
