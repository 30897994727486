import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {PBMRoleInfo} from '../models/PBMRoleInfo';
import {AddUserFormGroup, AppAccessFormGroup, ClientAccessFromGroup, UserAccessType} from '../enums/add-user-form.enum';

export function isRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const isEmpty = control.value.length === 0;
    const isWhitespace = control.value.trim().length === 0;
    const isValid = !isEmpty && !isWhitespace;
    return isValid ? null : {required: true, message: ' required.'};
  };
}

export function validCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const isEmpty = control.value.length === 0;
    const hasValidChars = /^([a-zA-Z0-9 _-]+)$/.test(control.value);
    const isValid = isEmpty || hasValidChars;
    return isValid ? null : {pattern: true, message: ' contains invalid characters.'};
  };
}

export function asyncValidCharactersValidator(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> | null => {

    const isEmpty = control.value.length === 0;
    const hasValidChars = /^([a-zA-Z0-9 _-]+)$/.test(control.value);
    const isValid = isEmpty || hasValidChars;
    return isValid ? of(null) : of({pattern: true, message: ' contains invalid characters.'});
  };
}

export function validCharactersWithoutSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const isEmpty = control.value.length === 0;
    const hasValidChars = /^([a-zA-Z0-9_-]+)$/.test(control.value);
    const isValid = isEmpty || hasValidChars;
    return isValid ? null : {pattern: true, message: ' contains invalid characters.'};
  };
}

export function validEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const invalidEmail = Validators.email(control) || !control.value.toLowerCase().trim().endsWith('.com');
      return  invalidEmail ? {invalidEmail: true, message: ' required.'} : null;
    }
    return null;
  };
}

export function alreadyExistsValidator(roles: PBMRoleInfo[], currentRoleName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const isDuplicate = roles.map(role => role.roleName.toLowerCase()).includes(control.value.toLowerCase());
    const isValid = !isDuplicate || control.value.toLowerCase() === currentRoleName?.toLowerCase();
    return isValid ? null : {duplicate: true, message: ' already exists.'};
  };
}

export function roleExclusionValidator(roles: PBMRoleInfo[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control.value && control.value instanceof Array) {
      const excludedRoles = control.value.map(roleCode => roleCode.toLowerCase());
      const appRoleCodes = roles.map(role => role.roleCode.toLowerCase());
      const isValid = excludedRoles.every(excludedRoleCode => appRoleCodes.includes(excludedRoleCode));
      return isValid ? null : {excluded: true, message: ' are invalid.'};
    }
  };
}

export function duplicateRoleExclusionValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control.value && control.value instanceof Array) {
      const isValid = new Set(control.value).size === control.value.length;
      return isValid ? null : {duplicateExclusion: true, message: ' are already excluded.'};
    }
  };
}

export function selfRoleExclusionValidator(role: PBMRoleInfo): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control.value && control.value instanceof Array) {
      const excludedRoles = control.value.map(roleCode => roleCode?.toUpperCase());
      const isValid = excludedRoles.every(excludedRoleCode => role?.roleCode?.toUpperCase() !== excludedRoleCode);
      return isValid ? null : {selfRoleExclusion: true, message: ' can not exclude themselves.'};
    }
  };
}

export function accessTypeRequiredValidator() {
  return (formGroup: FormGroup): ValidationErrors => {
    const atLeastOneSelected = Object.keys(formGroup.controls).some(controlName => formGroup.controls[controlName].value);
    return atLeastOneSelected ? null: {required: true, message: ' required.'};
  };
}

export function appSelectedAndRoleSelectedValidator() {
    return (formGroup): ValidationErrors => {
        const appSelectedControl = formGroup.controls[AppAccessFormGroup.APP_SELECTED];
        const selectedAppRoleControl = formGroup.controls[AppAccessFormGroup.SELECTED_APP_ROLE];

        if (appSelectedControl.value && selectedAppRoleControl.value.length === 0) {
            appSelectedControl.setErrors({required: true});
            selectedAppRoleControl.setErrors({required: true});
        } else {
            appSelectedControl.setErrors(null);
            selectedAppRoleControl.setErrors(null);
        }
        return null;
    };
}

export function exactLengthValidator(exactLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      return null;
    }

    return control.value.length !== exactLength ?
      {exactlength: {requiredLength: exactLength, actualLength: control.value.length}} :
      null;
  };
}

function isEmptyInputValue(value: any): boolean {
  return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

function hasValidLength(value: any): boolean {
  return value != null && typeof value.length === 'number';
}

export function generalAccessValidator(group: FormGroup) {
  const isSelected = group.controls['selected'];
  const permissionType = group.controls['permissionType'];

  if (isSelected.value === true && permissionType.value === '') {
    permissionType.setErrors({required: true});
  } else {
    permissionType.setErrors(null);
  }
}

export function isRequiredMultiSelectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isNull = control.value === null;
    const isEmpty = control.value && control.value.length === 0;

    const isValid = !isEmpty && !isNull;

    return isValid ? null : {required: true, message: ' required.'};
  };
}

export function requiredEmployeeIdValidator() {
  return (formGroup):  ValidationErrors | null  => {
    const userType = formGroup.controls[AddUserFormGroup.USER_TYPE];
    const employeeId = formGroup.controls[AddUserFormGroup.EMPLOYEE_ID];

    const isEmpty = !employeeId.value;
    const isInternalUser = userType.value === UserAccessType.CVS_HEALTH_COLLEAGUE;

    return isInternalUser && isEmpty ? employeeId.setErrors({ required: true }) : null;
  };
}

export function validEmployeeIdPatternValidator() {
  return (formGroup):  ValidationErrors | null  => {
    const userType = formGroup.controls[AddUserFormGroup.USER_TYPE];
    const employeeId = formGroup.controls[AddUserFormGroup.EMPLOYEE_ID];

    const hasValidChars = /^[0-9]{7}$/.test(employeeId.value);
    const isNotEmpty = employeeId.value;
    const isInternalUser = userType.value === UserAccessType.CVS_HEALTH_COLLEAGUE;

    return isInternalUser && isNotEmpty && !hasValidChars ? employeeId.setErrors({ pattern: true }) : null;
  };
}

export function requiredColleagueAffiliationValidator() {
  return (formGroup):  ValidationErrors | null  => {
    const userType = formGroup.controls[AddUserFormGroup.USER_TYPE];
    const corporation = formGroup.controls[AddUserFormGroup.CORPORATION];

    const isEmpty = !corporation.value;
    const isInternalUser = userType.value === UserAccessType.CVS_HEALTH_COLLEAGUE;

    return isInternalUser && isEmpty ? corporation.setErrors({ required: true }) : null;
  };
}


export function requiredClientAccessValidator() {
  return (formGroup):  ValidationErrors | null  => {
    const userType = formGroup.controls[AddUserFormGroup.USER_TYPE];
    const clientsForClientUser = formGroup.controls[AddUserFormGroup.CLIENTS_FOR_CLIENT_USER];

    if (userType.value === UserAccessType.CLIENT_USER) {
      if (!hasClientAccess(formGroup)) {
        clientsForClientUser.setErrors({ required: true });
      } else {
        clientsForClientUser.setErrors(null);
      }
    } else {
      clientsForClientUser.setErrors(null);
    }

    return;
  };
}

export function onlyOneClientAccessValidator() {
  return (formGroup):  ValidationErrors | null  => {
    const userType = formGroup.controls[AddUserFormGroup.USER_TYPE];
    const usersClientAccess = formGroup.controls[AddUserFormGroup.USERS_CLIENT_ACCESS];

    if (userType.value === UserAccessType.CLIENT_USER) {
      if (hasMoreThanOneClientAccess(formGroup)) {
        usersClientAccess.setErrors({ moreThanOneClientAssigned: true });
      } else {
        usersClientAccess.setErrors(null);
      }
    } else {
      usersClientAccess.setErrors(null);
    }

    return;
  };
}

function hasClientAccess(formGroup): boolean {
  const clientsForClientUser = formGroup.controls[AddUserFormGroup.CLIENTS_FOR_CLIENT_USER];
  const usersClientAccess = formGroup.controls[AddUserFormGroup.USERS_CLIENT_ACCESS];
  const usersClientAccessWithLob = formGroup.controls[AddUserFormGroup.USERS_CLIENT_ACCESS_WITH_LOB];
  const usersClientAccessWithLobSelected = usersClientAccessWithLob.controls
    .filter(control => control.get(ClientAccessFromGroup.CLIENT_SELECTED).value === true);

  return clientsForClientUser.value || usersClientAccess.value?.length !== 0 || usersClientAccessWithLobSelected.length !== 0;
}

function hasMoreThanOneClientAccess(formGroup): boolean {
  const usersClientAccess = formGroup.controls[AddUserFormGroup.USERS_CLIENT_ACCESS];
  const usersClientAccessWithLob = formGroup.controls[AddUserFormGroup.USERS_CLIENT_ACCESS_WITH_LOB];
  const usersClientAccessWithLobSelected = usersClientAccessWithLob.controls
    .filter(control => control.get(ClientAccessFromGroup.CLIENT_SELECTED).value === true);

  const clientAccessLength = usersClientAccess.value ? usersClientAccess.value.length : 0;
  const clientAccessWithLobLength =  usersClientAccessWithLobSelected ? usersClientAccessWithLobSelected.length : 0;
  return (clientAccessLength  + clientAccessWithLobLength) > 1;
}

