<ag-grid-angular class="ag-theme-alpine user-general-access-grid"
                 (gridReady)="onGridReady($event)"
                 [columnDefs]="columnDefs"
                 [context]="context"
                 [rowHeight]="40"
                 [suppressRowClickSelection]="true"
                 [suppressContextMenu]="true"
                 (rowSelected)="rowSelected($event)"
                 [defaultColDef]="defaultColDef"
                 [rowSelection]="'multiple'">
</ag-grid-angular>
