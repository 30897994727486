import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpHeaderService} from '../http-header/http-header.service';
import {Observable} from 'rxjs';
import {SuperClient} from '../../models/ClientHierarchy';

@Injectable({
  providedIn: 'root'
})
export class ClientProfileService {

  private clientBaseUrl = environment.portApiBaseUrl + '/api/v1/client/';

  constructor(private http: HttpClient,
              private httpHeaderService: HttpHeaderService) {

  }

  public getClientProfile(clientId: number): Observable<SuperClient> {
   return this.http.get<SuperClient>(this.clientBaseUrl + 'hierarchy/' + clientId,
     this.httpHeaderService.buildSimpleHeader());
  }
}
