import {Injectable} from '@angular/core';
import {ClientProfileService} from '../client-profile.service';
import {ClientDataService} from '../data/client-data.service';
import {ClientProfileModalComponent} from '../../../common/client-profile-modal/client-profile-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientDTO} from '../../../models/ClientDTO';
import {ClientGroupModalComponent} from '../../../common/client-group-modal/client-group-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ClientProfileModalService {

  constructor(private clientProfileService: ClientProfileService,
              private clientDataService: ClientDataService,
              private confirmationDialog: MatDialog) { }


  openClientProfileModal(client: ClientDTO, isFromCarrier?: boolean) {
    this.clientProfileService.getClientProfile(client.id).subscribe(result => {

      this.clientDataService.setCurrentClient(result);

      this.confirmationDialog.open(ClientProfileModalComponent, {
        data: {
          clientId: client.id,
          clientCode: client.clientCode,
          fromCarrier: isFromCarrier,
        },
        panelClass: 'client-profile-modal',
        disableClose: true
      });

    });
  }

  openClientGroupAccessModal(clients: any, groupName: string, groupType: string, groupDescription: string) {
    this.confirmationDialog.open(ClientGroupModalComponent, {
      data: {
        clients,
        groupName,
        groupType,
        groupDescription
      },
      panelClass: 'client-group-modal',
      disableClose: true
    });
  }
}
