import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {HttpHeaderService} from '../http-header/http-header.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AMSManagementService {
  private AMSBaseUrl = environment.auditManagementApiUrl;

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) {
  }

  public getAMSFirms(): Observable<any> {
    return this.httpClient.get<any>(this.AMSBaseUrl + '/audit/reference/activeFirmList', this.httpHeaderService.buildSimpleHeader(null));
  }
}
