import {FilterType} from '../../enums/filter.enum';

export class ServerSideUtils {
  public static addFilterQueryParams(filterModel: any, queryParamMap: Map<string, any>) {
    for (const filter in filterModel) {

      switch (filterModel[filter].filterType) {

        case FilterType.SET:
          if (filterModel[filter].values.length > 0) {
            const queryParamKey = filter;
            const queryParamValue = filterModel[filter].values;
            queryParamMap.set(queryParamKey, queryParamValue);
          } else {
            queryParamMap.set(filter, '');
          }
          break;

        case FilterType.TEXT:
          queryParamMap.set(filter, filterModel[filter].type + ',' + filterModel[filter].filter);
          break;

        default:
          break;
      }
    }
  }

  public static addPaginationQueryParams(startRow, endRow, queryParamMap) {
    const offset = startRow;
    const limit = endRow - startRow;

    queryParamMap.set('offset', offset);
    queryParamMap.set('limit', limit);
  }
}
