<div class="tile__background-border">
  <h2 class="client-group-access-title">Client Group Search</h2>
  <p class="client-group-access-label">
    Search to add a client group to the user's profile.
  </p>
  <div class="client-group-dropdown">
  <mat-form-field class="user-client-select">
    <mat-label aria-label="Select Option">Select Option</mat-label>
    <mat-select id="userClientAccessSelection" [disableOptionCentering]="true"
                [(value)]="clientDropdownOption"
                (selectionChange)="onDropDownChange()">
      <mat-option *ngFor="let clientDropdownOption of clientDropdownOptions;"
                  [value]="clientDropdownOption">
        {{clientDropdownOption.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-client-search
    style="display: inline-block; padding-bottom: 20px; padding-left: 24px " #appClientSearch
    [buttonClass]="'cvs-btn-ghost'"
    [clientSearchType]="clientSearchType"
    (searchOutput)="searchClientGroup($event)"
    (validationError)="onClientSearchValidationError()"
  >
  </app-client-search>
    <button *ngIf="isButtonShown"
            id="clear-client-group-access-tab-results"
            aria-label="Clear Results Button"
            mat-button color="primary"
            type="button"
            (click)="resetGroupSearch()">
      Clear Results
    </button>
    <div class="client-error-text-message" *ngIf="showErrorResponse">
      No client(s) to display, search again or contact your client administrator to add the client.
    </div>

    <ag-grid-angular *ngIf="showGrid"
                     [columnDefs]="superClientIdColumnDefs"
                     [defaultColDef]="defaultColDef"
                     (gridReady)="onGridReady($event)"
                     [rowHeight]="32"
                     [context]="context"
                     [suppressMenuHide]="true"
                     [rowModelType]="rowModelType"
                     [headerHeight]="31"
                     [pagination]="true"
                     (rowSelected)="onRowSelected($event)"
                     class="ag-theme-alpine client-group-access-grid"
                     id="userClientGroupGrid">
    </ag-grid-angular>
    <app-paginator-rows-per-page
      *ngIf="showGrid"
      [defaultPaginatorSize]="defaultPaginatorSize"
      [pageSizeOptions]="[25, 50, 100]"
      [gridApi]="gridApi"
      [columnApi]="columnApi"
      id="userClientGroupPaginator">
    </app-paginator-rows-per-page>
  </div>
</div>

<div class='group-access'>
  <mat-expansion-panel #clientGroupExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2 class="client-group-access-expansion-panel-title">Client Group Access</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="client-group-container">
      <ul>
        <li *ngFor="let formControl of userClientGroups.controls">
          <div *ngIf="formControl?.value.group?.groupStatus === 'Active'">
            <mat-checkbox
              [checked]="true" class="group-access-checkbox"
              (change)="groupCheckboxChange($event)"
              [value]="formControl"
            >
            </mat-checkbox>
            <a (click)="openClientGroupAccessModal(formControl)"
               (keyup.space)="openClientGroupAccessModal(formControl)"
               [tabIndex]="0">{{formControl?.value.group?.groupName}}</a>
          </div>
      </ul>
    </div>
  </mat-expansion-panel>
</div>
