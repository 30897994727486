<div class="dialog-wrapper">
  <mat-dialog-content class="dialog-header">
    <h2 id="headlineValue">Are you sure you want to leave this page without saving?</h2>
  </mat-dialog-content>

  <mat-dialog-content class="dialog-main">
    <p id="content">If you leave this page any changes would be lost.</p>
  </mat-dialog-content>

  <div class="dialog-footer">
      <div class="dialog-footer__row">
        <div class="dialog-footer__buttons">
          <button id="cancelBtn" class="cvs-btn-white" (click)="discardChanges()">Discard Changes</button>
          <button id="continueBtn" class="cvs-btn-primary" (click)="continueEdit()">Continue Editing</button>
        </div>
      </div>
  </div>
</div>

