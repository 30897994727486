import {Component, OnDestroy, OnInit} from '@angular/core';
import {Tile} from '../models/tile.interface';
import {BehaviorSubject, Subject} from 'rxjs';
import {PBMAppInfo, PBMRoleInfo} from '../models/AppInfo';
import {AppManagementService} from '../services/app-management/app-management.service';

@Component({
  selector: 'app-app-management',
  templateUrl: './app-management.component.html',
  styleUrls: ['./app-management.component.scss']
})
export class AppManagementComponent implements OnInit, OnDestroy {
  showSpinner: Subject<boolean> = new BehaviorSubject(true);

  title = 'App Management';
  pbmApps: PBMAppInfo[] = [];
  tiles: Tile[] = [];
  subscriptions = [];

  constructor(private appManagementService: AppManagementService) { }

  ngOnInit() {
    this.showSpinner.next(true);
    const appManagementSub = this.appManagementService.getAllForAdmin().subscribe((appList) => {
      this.pbmApps = appList;
      this.tiles = this.convertPBMAppsToTiles();
      this.showSpinner.next(false);
    });
    this.subscriptions.push(appManagementSub);
  }

  convertPBMAppsToTiles(): Tile[] {
    if (this.pbmApps && this.pbmApps.length > 0) {
      return this.pbmApps.filter(pbmApp => pbmApp.appCode.toLowerCase() !== 'mypbm')
        .map((pbmApp: PBMAppInfo) => {
          const roles = pbmApp.roles.map((roleInfo: PBMRoleInfo) => {
            return roleInfo.roleCode;
          });
          return {
            title: pbmApp.appShortName,
            subtitle: pbmApp.appName,
            description: pbmApp.appDescription,
            roles,
            imageUrl: pbmApp.appIcon,
            navigationUrl: '#/app-management/manage/' + pbmApp.appCode
          };
        });
    }
    return [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
