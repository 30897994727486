import {
  ClientGroupLobsRendererComponent
} from '../ag-grid-utils/cell-renderers/client-group-lobs-renderer/client-group-lobs-renderer.component';

export const clientGroupName = {
  headerName: 'Client Group Name',
  field: 'groupName',
  headerTooltip: 'Client Group Name',
  minWidth: 322,
  checkboxSelection: true,
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.value;
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.clientProfileModalService.openClientGroupAccessModal(
        params.data.clients, params.data.groupName, params.data.groupType, params.data.groupDescription);
    });
    return a;
  },
};

export const Clients = {
  headerName: 'Clients',
  field: 'clients',
  headerTooltip: 'Clients',
  checkboxSelection: false,
  valueGetter: (params) => {
    const clientLength = params.data.clients.length;
    const label = clientLength > 1 ? ' clients' : ' client';
    return clientLength + label;
  },
  minWidth: 160,
};

export const groupType = {
  headerName: 'Group Type',
  field: 'groupType',
  headerTooltip: 'Group Type',
  minWidth: 150,
  checkboxSelection: false
};

export const ownerEmail = {
  headerName: 'Owner\'s Email',
  field: 'owner',
  headerTooltip: 'Owner\'s Email',
  minWidth: 256,
  checkboxSelection: false,
  valueGetter: (params) => {
    return params.data.owner?.basicUserInfo.email;
  }
};

export const lineOfBusiness = {
  headerName: 'Line Of Business',
  field: 'lineOfBusinesses',
  headerTooltip: 'Line Of Business',
  minWidth: 200,
  checkboxSelection: false,
  valueGetter: (params) => {
    return params.data.lineOfBusinesses.map(lob => lob.name);
  },
  filter: 'agSetColumnFilter',
  filterParams: {
    values: (params) => {
      const filterOptions = [];

      for (const lobs of params.context.this.lineOfBusinesses) {
        filterOptions.push(lobs.name);
      }

      params.success(filterOptions);
    },
    comparator: (a, b) => {
      return a.localeCompare(b);
    }
  },
  cellRenderer: ClientGroupLobsRendererComponent,
};

export const superClientIDGroupName = {
  headerName: 'Super Client Name',
  field: 'superClientName',
  minWidth: 200,
  checkboxSelection: false,
  valueGetter: (params) => {
    return params.context.this.getClientName(params);
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.context.this.getClientName(params);
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.openClientProfileModal(params);
    });
    return a;
  }
};

export const superClientNameGroupName = {
  headerName: 'Super Client Name',
  field: 'superClientName',
  minWidth: 200,
  checkboxSelection: false,
  valueGetter: (params) => {
    return  params.context.this.getClientName(params);
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.context.this.getClientName(params);
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.openClientProfileModal(params);
    });
    return a;
  }
};

export const groupCarrierName = {
  headerName: 'Carrier Name',
  field: 'carrierName',
  minWidth: 180,
  checkboxSelection: false,
  valueGetter: (params) => {
    return  params.context.this.getClientName(params);
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.context.this.getClientName(params);
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.openClientProfileModal(params);
    });
    return a;
  }
};

export const groupClientCode = {
  headerName: 'Client Code',
  field: 'clientCode',
  valueGetter: (params) => {
    const clientName = params.data.clients.filter(client => client.clientCode ===
      params.context.this.clientGroupManagementService.globalSearchValue.toUpperCase())[0].clientCode;
    return clientName;
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.data.clients.filter(client => client.clientCode ===
      params.context.this.clientGroupManagementService.globalSearchValue.toUpperCase())[0].clientCode;
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.openClientProfileModal(params);
    });
    return a;
  },
  minWidth: 160,
  checkboxSelection: false,
};





