<div class="dialog-wrapper">
  <div mat-dialog-title class="dialog-header">
    <h1 id="headlineValue">Client Profile</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <mat-dialog-content class="dialog-main">
    <app-client-profile
      [fromModal]="true"
      [clientCode]="data.clientCode"
      [fromCarrier]="data.fromCarrier"
    >
    </app-client-profile>
  </mat-dialog-content>
</div>
