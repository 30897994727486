<cvs-loader-overlay-container [isLoading]="(showSpinner | async) "
                              [opacity]=".7"
                              loadingMessage="Loading">
  <div style="width:100%;height:100%">
    <div class="back-button-container">
      <button class="cvs-btn-text-only back-button"
              aria-label="Back"
              (click)="onBack()">
        <mat-icon svgIcon="caret-left--s" class="back-button-icon"></mat-icon>
        Back
      </button>
    </div>
    <div id="clientManagementErrorBanner"></div>
    <h1 class="header-padding">{{title}}</h1>
    <div class="tile__background-border">
      <cvs-loader-overlay-container>
        <h2 class="header-sub-title">Add Client Profile</h2>
        <p class="client-management-description">{{label}}</p>
        <div class="client-add-container">
          <mat-form-field class="add-client-form-field">
            <mat-select id="add-client-selection" [disableOptionCentering]="true"
                        [(value)]="addClientOption" (selectionChange)='onDropDownChange()'>
              <mat-option *ngFor="let addClientOption of addClientOptions;"
                          [value]="addClientOption">
                {{addClientOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-client-search
            style="display: inline-block" #appClientSearch
            [buttonClass]="'cvs-btn-primary'"
            (searchOutput)="getClientHierarchy($event)"
            (ngOnChangeFinished)="startSearchFromLink()"
            [clientSearchType]="clientSearchType"
          >
          </app-client-search>
        </div>
        <ag-grid-angular *ngIf="showGrid"
                         [columnDefs]="superClientIdColumnDefs"
                         [defaultColDef]="defaultColDef"
                         (gridReady)="onGridReady($event)"
                         [rowHeight]="32"
                         [context]="context"
                         [pagination]="true"
                         [suppressMenuHide]="true"
                         class="ag-theme-alpine client-access-grid">
        </ag-grid-angular>
        <app-paginator-rows-per-page *ngIf="showGrid"
                                     [defaultPaginatorSize]="defaultPaginatorSize"
                                     [pageSizeOptions]="[50, 100, 150]"
                                     [gridApi]="gridApi"
                                     [columnApi]="columnApi">
        </app-paginator-rows-per-page>
      </cvs-loader-overlay-container>
      <button class="cvs-btn-ghost save-client-button" type="button" *ngIf="showGrid" (click)="saveClients()">
        Add Client Profile
      </button>
    </div>
  </div>
</cvs-loader-overlay-container>
