import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppManagementService} from '../../services/app-management/app-management.service';
import {PBMAppInfo} from '../../models/AppInfo';
import {AppDataService} from '../../services/app-management/data/app-data.service';
import {BannerService} from '../../services/banner-service/banner.service';

@Component({
  selector: 'app-app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.scss']
})
export class AppDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  showSpinner: Subject<boolean> = new BehaviorSubject(true);
  appCode: string;
  selectedApp: PBMAppInfo;
  subscriptions: Subscription[] = [];
  selectedTab: any;
  appOverviewHasChanged: boolean;
  isTabClicked: boolean;
  selectedTabIndex: number;

  constructor(private route: ActivatedRoute,
              private appManagementService: AppManagementService,
              private router: Router,
              private appDataService: AppDataService,
              private bannerService: BannerService
  ) {
    this.route.params.subscribe(params => {
      this.appCode = params.appCode;
    });

    this.appDataService.currentApp$.subscribe((app: PBMAppInfo) => this.selectedApp = app);
  }

  ngOnInit(): void {
    this.showSpinner.next(true);

    const appSubscription = this.appManagementService.getAppByAppCode(this.appCode).subscribe(app => {
      this.appDataService.setCurrentApp(app);
      this.showSpinner.next(false);
    });

    this.subscriptions.push(appSubscription);
    this.selectedTabIndex = this.appDataService.selectedTabIndex;
  }

  ngAfterViewInit(): void {
    const sub = this.appManagementService.appManagementNotification
      .subscribe(data => {
        if(data){
          this.bannerService.showSuccessBanner(data);
          this.appManagementService.appManagementNotification.next(null);
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.appDataService.selectedTabIndex = 0;
  }

  onTabChanged(event: any) {
    this.selectedTabIndex = event.index;
    if (this.appOverviewHasChanged && this.selectedTab === 'Overview') {
      this.isTabClicked = !this.isTabClicked;
    }
    this.selectedTab = event.tab.textLabel;
  }

  onAppOverviewChange(event: boolean) {
    this.appOverviewHasChanged = event;
  }

  canDeactivate(): boolean {
    return !this.appOverviewHasChanged;
  }

  onBack() {
    this.router.navigate(['/app-management']);
  }
}
