<div class="capabilities-container">
  <h2 class="header-padding permissions-header">Permissions</h2>

  <div class="header-container">
    <app-search #searchCapabilitiesComponent [gridApi]="gridApi"></app-search>
    <div class="capabilities-button-container">
      <app-expand-collapse-buttons #expandCollapseButtonsComponent [gridApi]="gridApi"></app-expand-collapse-buttons>
      <button class="cvs-btn-primary" style="width: 171px;" (click)="navigateToAddEditPermission()">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Permission
      </button>
    </div>
  </div>
  <ag-grid-angular
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    [components]="frameworkComponents"
    [rowHeight]="32"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [context]="context"
    [pagination]="true"
    [keepDetailRows]="true"
    [rowBuffer]="100"
    (rowGroupOpened)="updateAllRowsExpandedState()"
    [isExternalFilterPresent]="isExternalFilterPresent"
    [doesExternalFilterPass]="doesExternalFilterPass"
    [rowClassRules]="rowClassRules"
    class="ag-theme-alpine capabilities-grid">
  </ag-grid-angular>

  <app-paginator-rows-per-page
    [defaultPaginatorSize]="defaultPaginatorSize"
    [pageSizeOptions]="[10, 15, 20]"
    [gridApi]="gridApi"
    [columnApi]="columnApi">
  </app-paginator-rows-per-page>
</div>
