import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaginatorRowsPerPageComponent} from './paginator-rows-per-page.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
  declarations: [
    PaginatorRowsPerPageComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule
  ],
  exports: [
    PaginatorRowsPerPageComponent
  ]
})
export class PaginatorModule { }
