import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-client-profile-modal',
  templateUrl: './client-profile-modal.component.html',
  styleUrls: ['./client-profile-modal.component.scss']
})
export class ClientProfileModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
