<cvs-loader-overlay-container [isLoading]="(showSpinner | async) "
                              [opacity]=".7"
                              loadingMessage="Exporting">
  <div style="width:100%;height:100%">
    <div id="userReportBanner"></div>
    <h1 tabindex="0" aria-label="User Reports" class="header-padding">User Reports</h1>
    <div class="tile__background-border">
      <div>
        <h2 class="header-padding">Generate Report</h2>
        <p class="report-management-description">
          User reports may be generated based on user type, application, or client, containing user information.
          Select/enter search criteria, then select the client from the search results.
        </p>
      </div>
      <form [formGroup]="userReportFormGroup" (ngSubmit)="onExport()">
        <div class="user-types-dropdown">
          <mat-form-field>
            <mat-label aria-label="Select User Type">Select User Type</mat-label>
            <mat-select id="userTypeOption" formControlName="userTypeFormControl" [disableOptionCentering]="true"
                        multiple>
              <div class="select-all-option" (click)="toggleAllSelection(userType, userTypeOptions, 0)">
                <mat-checkbox #selectAllCheckbox [indeterminate]="isIndeterminate(userType, userTypeOptions)"
                              (click)="$event.preventDefault()">
                  Select All
                </mat-checkbox>
              </div>
              <mat-option *ngFor="let userTypeOption of userTypeOptions" [value]="userTypeOption"
                          (click)="toggleOption(userType, userTypeOptions, 0)">
                {{userTypeOption}}
              </mat-option>
            </mat-select>
            <mat-hint>Required Field</mat-hint>
            <mat-error data-cy="access-type-required-error"
                       *ngIf="userReportFormGroup.controls['userTypeFormControl'].errors?.required">
              <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
              User Type required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="application-dropdown">
          <mat-form-field>
            <mat-label aria-label="Select Application">Select Application</mat-label>
            <mat-select id="applicationOption" formControlName="applicationFormControl" [disableOptionCentering]="true"
                        multiple>
              <div class="select-all-option" (click)="toggleAllSelection(application, applicationOptions, 1)">
                <mat-checkbox #selectAllCheckbox [indeterminate]="isIndeterminate(application, applicationOptions)"
                              (click)="$event.preventDefault()">
                  Select All
                </mat-checkbox>
              </div>
              <mat-option *ngFor="let applicationOption of applicationOptions" [value]="applicationOption"
                          (click)="toggleOption(application, applicationOptions, 1)">
                {{applicationOption}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-client-selector #clientSelector
          (clientSelected)="onClientSelected($event)"
          (clientDropDownChanged)="onClientDropDownChange()">
        </app-client-selector>

        <button aria-label="Export Report Button" class="cvs-btn-primary user-export-button" type="submit">Export Report</button>
        <button aria-label="Cancel Button" class="pds-btn-ghost user-cancel-button" *ngIf="userReportFormGroup.dirty" (click)="openCancelModal()" type="button">Cancel</button>
      </form>
    </div>
  </div>
</cvs-loader-overlay-container>
