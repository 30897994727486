import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PBMUserInfo} from '../../../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  userBeingCopied$: BehaviorSubject <PBMUserInfo>;

  constructor() {
    this.userBeingCopied$ = new BehaviorSubject <PBMUserInfo>(null);
  }

  setUserBeingCopied(user: PBMUserInfo){
    this.userBeingCopied$.next(user);
  }
}
