<div class="user-app-access-container">
  <span class="number-of-applications">{{numberOfAppsText}}</span>
  <cvs-popover-button *ngIf="params.context.this.allAppsDataLoaded"
    iconName="list--s"
    class="cvs-icon-only-btn list-icon"
    hideX="false"
    customPanelClass="custom-panel-container"
    [direction]=left
    [hasBackdrop]=true>
    <div>
      <h2>App Access</h2>
      <div class="list-items">
        <ul>
          <li *ngFor="let appName of params.data.userAppNames">{{appName}}</li>
        </ul>
      </div>
    </div>
  </cvs-popover-button>
</div>
