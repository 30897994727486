import {Component, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {CurrentUserService} from '../services/current-user/current-user.service';
import {AppManagementService} from '../services/app-management/app-management.service';
import {LegalModalComponent} from '../common/legal-modal/legal-modal.component';
import {Tile} from '../models/tile.interface';
import {PBMAppInfo, PBMRoleInfo} from '../models/AppInfo';
import {MatDialog} from '@angular/material/dialog';
import {agreementModalContent} from '../common/modal-content/agreement-modal-content';
import {BehaviorSubject, Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {PBMUserInfo} from '../models/User';
import {OAuthService} from 'angular-oauth2-oidc';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  currentUser: PBMUserInfo;
  showSpinner: Subject<boolean> = new BehaviorSubject(true);

  pbmApps: PBMAppInfo[] = [];

  tiles: Tile[] = [];
  subscriptions = [];

  constructor(private currentUserService: CurrentUserService,
              private confirmationDialog: MatDialog,
              private appManagementService: AppManagementService,
              private domSanitizer: DomSanitizer,
              private window: Window,
              private oAuthService: OAuthService,
  ) {
  }

  ngOnInit(): void {
    this.showSpinner.next(true);
    const appManagementSub = this.appManagementService.getAssignedApps().subscribe((appList) => {
      this.pbmApps = appList;
      this.tiles = this.convertPBMAppsToTiles();

      const userServiceSub = this.currentUserService.currentUser$.subscribe((currentUser) => {
        this.currentUser = currentUser;
        this.showSpinner.next(false);
        if (this.currentUser && !this.currentUser.internalUser && !this.currentUser.termsAccepted) {
          this.userAgreementModal();
        } else {
          this.checkAndNavigateUserToApp();
        }
      });
      this.subscriptions.push(userServiceSub);

    });
    this.subscriptions.push(appManagementSub);
  }

  private getSafeUrl(url): string {
    return this.domSanitizer.sanitize(SecurityContext.URL, url);
  }

  convertPBMAppsToTiles(): Tile[] {
    if (this.pbmApps && this.pbmApps.length > 0) {
      return this.pbmApps.filter(pbmApp => pbmApp.appCode.toLowerCase() !== 'mypbm' && pbmApp.active)
        .map((pbmApp: PBMAppInfo) => {

          const roles = pbmApp.roles.map((roleInfo: PBMRoleInfo) => {
            return roleInfo.roleCode;
          });
          return {
            title: pbmApp.appShortName,
            subtitle: pbmApp.appName,
            description: pbmApp.appDescription,
            roles,
            imageUrl: pbmApp.appIcon,
            navigationUrl: this.getSafeUrl(pbmApp.appUrl)
          };
        });
    }
    return [];
  }

  userAgreementModal() {
    this.confirmationDialog.open(LegalModalComponent, {
      data: {
        headline: 'myPBM™ Terms and Conditions of Use',
        content: agreementModalContent,
        actionLabel: 'Agree & Continue',
        cancelLabel: 'Cancel',
        noCloseX: true,
        source: 'Login'
      },
      panelClass: 'legal-modal',
      disableClose: true
    }).afterClosed().subscribe(() => {
        this.checkAndNavigateUserToApp();
    });
  }

  checkAndNavigateUserToApp() {
    if (this.currentUser?.adminTypes?.length === 0 && this.tiles.length === 1) {
      const appTile = this.tiles[0];
      this.oAuthService.logOut();
      this.window.location.href = appTile.navigationUrl;
    }
  }

  checkAppRoles(tile: Tile) {
    const roles = this.currentUser.appRoles as object[];
    if (this.currentUser && this.currentUser.appRoles && roles.length) {
      return Object.values(this.currentUser.appRoles).some((role) => tile.roles.some(tileRole => tileRole.startsWith(role)));
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
