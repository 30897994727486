export const enum AddPermissionFormGroup {
  PERMISSION_ACCESS_TYPES = 'permissionAccessTypes',
  CAPABILITY_NAME = 'capabilityName',
  PERMISSION_NAME = 'permissionName',
  PERMISSION_TYPE = 'permissionType',
  PERMISSION_ID = 'permissionId',
  PERMISSION_DESCRIPTION = 'permissionDescription'
}

export const enum AddRoleFormGroup {
  ROLE_ACCESS_TYPES = 'roleAccessTypes',
  ROLE_NAME = 'roleName',
  ROLE_ID = 'roleId',
  ROLE_DESCRIPTION = 'roleDescription',
  EXCLUDED_ROLES = 'excludedRoles',
}

export enum AddEditModeEnum {
  EDIT = 'edit',
  ADD = 'add'
}

export const enum StatusCodes {
  PENDING = 'Pending',
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export const enum UserReportFormGroup {
  USER_ACCESS_TYPES = 'userTypeFormControl',
  APPLICATIONS = 'applicationFormControl',
  CLIENT_OPTION = 'clientOptionFormControl',
}

export const enum ClientReportFormGroup {
  CLIENT_OPTION = 'clientOption',
  CLIENT = 'clientFormControl'
}

export const enum ClientGroupFormGroup {
  CLIENT_GROUP_TYPE = 'clientGroupType',
  GROUP_USER_ACCESS_TYPE = 'clientGroupUserAccessTypes',
  OWNER_EMAIL = 'ownerEmailAddress',
  GROUP_NAME = 'clientGroupName',
  GROUP_DESCRIPTION = 'clientGroupDescription',
}
