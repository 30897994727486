import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({providedIn: 'root'})
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;

  constructor(
    private matDialog: MatDialog,
  ) {
  }

  // TODO: can deactivate triggering twice bug - https://www.reddit.com/r/Angular2/comments/anj4sr/strange_issue_with_candeactivate_guard/

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component) {
      if (component.canDeactivate()) {
        return true;
      } else {
        return this.openConfirmationDialog();
      }
    }
    return true;
  }

  openConfirmationDialog(): Observable<boolean> {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Leave page without saving?',
        body:
          '<br>'
          + 'You have unsaved changes, are you sure you want to leave this page'
          + '<br>' + 'without saving?'
          + '<br><br>'
          + 'If you leave this page, any changes will be lost.'
          + '<br><br>',
        actionLabel: 'Discard Changes',
        cancelLabel: 'Continue Editing',
        noCloseX: false
      }
    });

    return this.confirmationDialog.componentInstance.onConfirmClick;
  }
}
