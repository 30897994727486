<div style="width:100%;height:100%">
  <div id="appDashboardBanner"></div>
  <h1 class="header-padding">{{title}}</h1>
  <div class="tile__background-border">
    <cvs-loader-overlay-container>
      <div class="client-management-header-container">
        <div>
          <h2 class="sub-header-padding">Client Groups</h2>
          <p class="client-management-description">
            You may search for a client group by entering the Client Group Name, Super Client Name/ID, Client Code, or Carrier Name/ID that
            <br>
            is associated within a group. If the client group is not found, proceed by clicking the Add Group button to add a new client group.
          </p>
        </div>
        <button class="pds-btn-primary" (click)="navigateToAddEditGroup()">Add Group</button>
      </div>
      <app-search #searchGroupComponent [gridApi]="gridApi"></app-search>

      <ag-grid-angular
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        [rowHeight]="32"
        [context]="context"
        [pagination]="true"
        [suppressMenuHide]="true"
        [components]="frameworkComponents"
        class="ag-theme-alpine client-group-grid">
      </ag-grid-angular>
      <app-paginator-rows-per-page
        [defaultPaginatorSize]="defaultPaginatorSize"
        [pageSizeOptions]="[50, 100, 150]"
        [gridApi]="gridApi"
        [columnApi]="columnApi">
      </app-paginator-rows-per-page>

    </cvs-loader-overlay-container>
  </div>

  <app-client-group-detail
    *ngIf="isGroupDetailsShown"
    [selectedGroup]="selectedGroup"
    (closeClientDetails)="hideGroupDetails()">
  </app-client-group-detail>

</div>

